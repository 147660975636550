import { FC, useState } from "react";

interface InstructionFormProps {
  onSendClick: (value: InstructionFormValue) => void;
}

export interface InstructionFormValue {
  name: string;
  text: string;
  videoUrl: string;
}

const InstructionForm: FC<InstructionFormProps> = ({ onSendClick }) => {
  const [formValue, setFormValue] = useState<InstructionFormValue>({
    name: "",
    text: "",
    videoUrl: "",
  });

  const isDisabled =
    !formValue.name && (!formValue.text || !formValue.videoUrl);

  return (
    <div
      className="relative bg-lightBlue-600"
      style={{
        display: "flex",
        padding: "20px",
        gap: "10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        className="relative  mb-3"
        style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
      >
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="instruction-name"
          //style={{ opacity: 0 }}
        >
          Имя инструкции
        </label>
        <input
          type="name"
          id="instruction-name"
          onChange={({ target: { value } }) =>
            setFormValue((prevFormValue) => ({ ...prevFormValue, name: value }))
          }
          value={formValue.name}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>
      <div
        className="relative  mb-3"
        style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
      >
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="instruction-text"
        >
          Текст инструкции
        </label>
        <input
          type="text"
          id="instruction-text"
          onChange={({ target: { value } }) =>
            setFormValue((prevFormValue) => ({ ...prevFormValue, text: value }))
          }
          value={formValue.text}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>
      <div
        className="relative  mb-3"
        style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
      >
        <label
          className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
          htmlFor="instruction-videoUrl"
        >
          Ссылка на видео (Youtube)
        </label>
        <input
          type="text"
          id="instruction-videoUrl"
          onChange={({ target: { value } }) =>
            setFormValue((prevFormValue) => ({
              ...prevFormValue,
              videoUrl: value,
            }))
          }
          value={formValue.videoUrl}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
      </div>

      <button
        className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        style={{
          height: 46,
          minWidth: 110,

          margin: "0 0",
          opacity: !isDisabled ? "100%" : "50%",
        }}
        onClick={() => onSendClick(formValue)}
        disabled={isDisabled}
      >
        Cоздать
      </button>
    </div>
  );
};

export default InstructionForm;
