import React from "react";
// import "@fortawesome/fontawesome-free/css/all.min.css";
import { Dispatch } from "redux";
import Switch from "@material-ui/core/Switch";
import { connect, useDispatch } from "react-redux";
import { compose } from "recompose";
import copy from "copy-to-clipboard";

import {
  getTemplate,
  deleteTemplate,
  getXmlList,
  deleteXml,
  sharedXml,
  setEditXml,
  getWidgets,
  setInfoStatus,
  getActiveXml,
  buy,
} from "../../redux/actions/actions";
import { Loaders, TypeStatusInfo, url, urlXml } from "../../constant/constants";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Templates as TemplatesType } from "../../api/comments/api.types";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "../../shared/Modal";
import { PayModal } from "../../shared/PayModal";
import Sidebar from "../Sidebar/Sidebar";
import HeaderStats from "../Headers/HeaderStats";
import { RootState } from "../../redux/reducers/rootReducer";
import { Link, useHistory } from "react-router-dom";
import { useWindowSize } from "@react-hook/window-size";
import { useEffect } from "react";
import Alert from "@material-ui/lab/Alert";

import "./style.css";
import { useState } from "react";
import moment from "moment";

import Form from "../../tarif/form/Form";
interface CreateXmlFormProps {}

interface CreateXmlFormPrivateProps extends CreateXmlFormProps {
  fetchXml: (userId: string) => void;
  deleteXml: (params: any) => void;
  userId: string;
  isErrorTemplate: boolean;
  isLoader: boolean;
  listXml: any[];
  isSharedLoader: string;
  isSharedLoaderError: boolean;
  sharedXmlFile: (value: any) => void;
  idLoaderDelete: string;
  setEditXml: (value: any) => void;
  widgets: any;
  getWidgets: () => void;
  message: any;
  getActiveXml: () => void;
  activeXml: any[];
  buy: (userId: number, cloud: number, ads: number, xml: number) => void;
}
function CreateXmlForm({
  isSharedLoader,
  isSharedLoaderError,
  fetchXml,
  userId,
  isLoader,
  isErrorTemplate,
  listXml,
  deleteXml,
  sharedXmlFile,
  idLoaderDelete,
  setEditXml,
  widgets,
  getWidgets,
  message,
  getActiveXml,
  activeXml,
  buy,
}: CreateXmlFormPrivateProps) {
  React.useEffect(() => {
    fetchXml(userId);
    if (!widgets) {
      getWidgets();
    }
  }, []);
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [id, setId] = React.useState(-1);
  const handleClickOpen = (idValue: number) => () => {
    setId(idValue);
    setOpen(true);
  };
  const handleClickEdit = (idValue: number) => () => {
    const item = listXml.find((obj) => obj.id === idValue);
    setEditXml(item);
    history.push("/xmlCreate/edit");
    // setId(idValue);
    // setEditXml
  };

  const handleDelete = () => {
    deleteXml({ userId, xmlId: id });
    setOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const style = isLoader
    ? { display: "flex", justifyContent: "center", alignItems: "center" }
    : {};
  const ref: any = React.useRef();

  let el = document.getElementById("HeaderStats");
  const [width, height] = useWindowSize();
  useEffect(() => {
    el = ref.current;
    setHeightHeader(el ? el.offsetHeight + 30 : 198);
  }, [width]);

  const [heightHeader, setHeightHeader] = React.useState(296);
  const [avito, setAvito]: any = React.useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    setAvito(listXml.map((item) => !!item.isAvito));
  }, [listXml]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(setInfoStatus(null));
    }, 6000);
  }, [message]);

  let HeaderStatsDocument =
    document.getElementById("HeaderStats")?.clientHeight;
  const [idStyle, setIdStyle] = React.useState(-1);

  React.useEffect(() => {
    setTimeout(() => {
      setIdStyle(-1);
    }, 500);
  }, [idStyle]);
  const marks = [
    {
      value: 0,
    },
    {
      value: 50,
    },
    {
      value: 80,
    },
    {
      value: 100,
    },
  ];
  const [modalPay, setModalPay] = useState(false);
  const [dataXml, setDataXml]: any = useState();
  const [price, setPrice]: any = useState();
  useEffect(() => {
    if (dataXml) {
      const count = activeXml.reduce((acc, item) => (acc += item.count), 0);
      const data: any = dataXml?.item;
      const countNew = data.count;
      const isFree = !!widgets?.wallet?.isFree;

      const result = calcPayForTarif(
        !isFree ? widgets?.wallet?.ads : 0,
        count || 0,
        countNew,
        !isFree ? widgets?.wallet?.xml : 0,
        activeXml?.length || 0
      );
      let isChek =
        (widgets?.wallet?.ads - count - countNew >= 0 &&
          widgets?.wallet?.xml - activeXml?.length - 1 >= 0) ||
        false;
      const curentTime = moment.utc().toISOString();
      if (
        !(
          moment.utc(widgets?.wallet?.date).valueOf() -
            moment.utc(curentTime).valueOf() >
          1
        )
      ) {
        isChek = false;
      }
      setPrice({
        price: result,
        buy: !isFree ? widgets?.wallet?.ads : 0,
        newBuy: data.count,
        xml: !isFree ? widgets?.wallet?.xml : 0,
        count,
        activeXml: activeXml?.length || 0,
        dataXml,
      });
      if (!isChek) {
        setModalPay(true);
        handleClose();
      } else {
        sharedXmlFile({
          id: dataXml?.item.id,
          userId: Number(userId),
          isAvito: dataXml.isAvito,
        });
      }
    }
  }, [activeXml]);

  function calcPayForTarif(
    currentTarifAds: number,
    activedAds: number,
    addedAds: number,
    currentTarifAutoloads: number,
    activeAutoloads: number
  ) {
    if (currentTarifAds === 0) {
      return 980;
    }

    const payAutoload = currentTarifAutoloads > activeAutoloads ? 0 : 380;

    const newTarifAds = activedAds + addedAds;
    const adsForPay = newTarifAds - currentTarifAds;
    let adsForDistribution = adsForPay > 0 ? adsForPay : 0;

    let ads1001_2000 = 0;
    let ads2001_3000 = 0;
    let ads3001_5000 = 0;
    let ads5001_10000 = 0;
    let adsMore10000 = 0;

    if (adsForDistribution > 0) {
      let activeAds1 = currentTarifAds;

      if (newTarifAds < 2000) {
        ads1001_2000 = adsForDistribution;
      } else if (newTarifAds < 3000) {
        ads1001_2000 = 2000 - activeAds1 > 0 ? 2000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads1001_2000;
        ads2001_3000 = adsForDistribution;
      } else if (newTarifAds < 5000) {
        ads1001_2000 = 2000 - activeAds1 > 0 ? 2000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads1001_2000;
        activeAds1 = activeAds1 + ads1001_2000;
        ads2001_3000 = 3000 - activeAds1 > 0 ? 3000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads2001_3000;
        activeAds1 = activeAds1 + ads2001_3000;
        ads3001_5000 = adsForDistribution;
      } else if (newTarifAds < 10000) {
        ads1001_2000 = 2000 - activeAds1 > 0 ? 2000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads1001_2000;
        activeAds1 = activeAds1 + ads1001_2000;
        ads2001_3000 = 3000 - activeAds1 > 0 ? 3000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads2001_3000;
        activeAds1 = activeAds1 + ads2001_3000;
        ads3001_5000 = 5000 - activeAds1 > 0 ? 5000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads3001_5000;
        activeAds1 = activeAds1 + ads3001_5000;
        ads5001_10000 = adsForDistribution;
      } else {
        ads1001_2000 = 2000 - activeAds1 > 0 ? 2000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads1001_2000;
        activeAds1 = activeAds1 + ads1001_2000;
        ads2001_3000 = 3000 - activeAds1 > 0 ? 3000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads2001_3000;
        activeAds1 = activeAds1 + ads2001_3000;
        ads3001_5000 = 5000 - activeAds1 > 0 ? 5000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads3001_5000;
        activeAds1 = activeAds1 + ads3001_5000;
        ads5001_10000 = 10000 - activeAds1 > 0 ? 10000 - activeAds1 : 0;
        adsForDistribution = adsForDistribution - ads5001_10000;
        activeAds1 = activeAds1 + ads5001_10000;
        adsMore10000 = adsForDistribution;
      }

      return (
        ads1001_2000 * 0.5 +
        ads2001_3000 * 0.4 +
        ads3001_5000 * 0.3 +
        ads5001_10000 * 0.2 +
        adsMore10000 * 0.1 +
        payAutoload
      );
    } else {
      return payAutoload;
    }
  }

  return (
    <>
      {message ? (
        <Alert
          severity={
            message?.type === TypeStatusInfo.ERROR ? "error" : "success"
          }
          style={{
            position: "fixed",
            zIndex: 99999,
            margin: 5,
            width: "99%",
          }}
        >
          {message?.text}
        </Alert>
      ) : (
        ""
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <div
          className="relative md:ml-64 bg-blueGray-100"
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: "column",
            flexBasis: "auto",
            width: "100%",
          }}
        >
          <Form />
          {/* <HeaderStats reff={ref} noCategories />

          <div
            className="px-4 md:px-10 mx-auto w-full -m-24 menu_template"
            style={{
              marginTop: "30px",
              width: "100%",
              height:
                heightHeader && heightHeader < 544
                  ? `calc(100vh - ${heightHeader}px)`
                  : "",
              // background:' #f1f5f9',
            }}
          >
            <p style={{ marginBottom: 10 }}></p>
          
            {modalPay && (
              <PayModal
                closeModal={setModalPay}
                open={modalPay}
                {...price}
                buyFn={buy}
                widgets={widgets}
                sharedXmlFile={sharedXmlFile}
              ></PayModal>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchXml: (userId: string) => dispatch(getXmlList.started({ userId })),
    deleteXml: (params: { userId: string; xmlId: string }) =>
      dispatch(deleteXml.started(params)),
    sharedXmlFile: (params: { id: number; isAvito: boolean; userId: number }) =>
      dispatch(sharedXml.started(params)),
    setEditXml: (value: any) => dispatch(setEditXml(value)),
    getWidgets: () => {
      dispatch(getWidgets.started(undefined));
    },
    getActiveXml: () => {
      dispatch(getActiveXml.started({}));
    },
    buy: (
      userId: number,
      cloud: number,
      ads: number,
      xml: number,
      price: number,
      isNew: boolean,
      orderNumber: string
    ) => {
      dispatch(
        buy.started({
          userId,
          cloud,
          ads,
          xml,
          price: String(price),
          isNew,
          orderNumber,
        })
      );
    },
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    activeXml: state.Reducer.getActiveXml,
    listXml: state.Reducer.xmlList,
    userId: state.Reducer.user.userId,
    isLoader: state.Reducer.loaderName == Loaders.fetchTemplate,
    isSharedLoader: state.Reducer.loaderName,
    isSharedLoaderError: state.Reducer.loaderName == Loaders.errorShared,
    idLoaderDelete: state.Reducer.loaderName,
    widgets: state.Reducer.widgets,
    message: state.Reducer.message,
  };
};

export default compose<CreateXmlFormPrivateProps, CreateXmlFormProps>(
  connect(mapStateToProps, mapDispatchToProps)
)(CreateXmlForm);

// currentTarifAds: number, сейчай преобретено
// activedAds: number, сейчас включено
// addedAds: number,надо купить
// currentTarifAutoloads: number,включено
// activeAutoloads: number надо добавить
