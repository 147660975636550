import React, { useEffect, useState } from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { compose } from "recompose";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import { useHistory } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { RootState } from "../../redux/reducers/rootReducer";
import Sidebar from "../Sidebar/Sidebar";
import Card from "../XmlCard";
import { Loaders } from "../../constant/constants";
import { getTemplate, getTemplateCreate } from "../../redux/actions/actions";
import { Templates as TemplatesType } from "../../api/comments/api.types";
import { createXml, сlearTemplateXml } from "../../redux/actions/actions";
import Modal from "../../shared/Modal";
import "./style.css";
import XmlCardCalendar from "../XmlCard/XmlCARDCalendar";
import { logErrors } from "../../helpers/logErrors";
import { socketEmitter } from "../../api/socket/index";
interface CreateXmlProps {}

interface CreateXmlPrivateProps extends CreateXmlProps {
  fetchTemplate: (
    userId: string,
    isActive: boolean,
    all: boolean,
    v?: any[],
    idXml?: string,
    isPhotos?: boolean
  ) => void;
  userId: string;
  isErrorTemplate: boolean;
  isLoader: boolean;
  listTemplate: TemplatesType[];
  clearTemplate: () => void;
  listCreateTemplateXml: any[];
  createXmlFetch: (value: any) => void;
  isLoaderCreate: string;
  editTemplate: any;
  templateXMLStatus: boolean;
  fetchTemplateCreate: () => void;
}

const CreateXml: React.FC<CreateXmlPrivateProps> = ({
  fetchTemplate,
  userId,
  isLoader,
  isErrorTemplate,
  listTemplate,
  clearTemplate,
  listCreateTemplateXml,
  createXmlFetch,
  isLoaderCreate,
  editTemplate,
  templateXMLStatus,
  fetchTemplateCreate,
}) => {
  const path = window.location.pathname.split("/");
  const tempaleEditId = editTemplate?.editTemplate?.split(",") || [];
  const isEdit = path?.length > 2 && path[2] == "edit" ? true : false;
  const onlyHeight = useWindowSize();
  const [selected, setSelected] = React.useState<any[]>([]);
  const [totalCount, setTotalCount]: any = React.useState<number>(0);
  const history = useHistory();
  const list: any = React.useRef();
  const [tooltipHeight, setTooltipHeight] = React.useState(70);
  const options = Array.isArray(listTemplate)
    ? listTemplate
        .map((item: any) => {
          const obj = item.params;
          const check = obj?.length && obj[0]?.length > 2;
          const chekId = tempaleEditId?.find((j: any) => {
            return j == item.id;
          });
          return {
            label: item.name,
            value: item.id,
            img: item?.photoUrl,
            count: item?.count,
            chekId,
            idCategory: item?.idCategory,
          };
        })
    : [];

  const [openModal, setOpenModal] = React.useState(false);
  const [nameXml, setNameXml] = React.useState(editTemplate?.name || "");
  const [isLoaderTemplate, setIsLoaderTemplate] = React.useState(true);
  const [dateTime, setDateTime]: any = useState([]);
  const [open, setOpen] = useState(false);
  const [handleModal, sethandleModal] = useState();
  const [openTime, setOpenTime] = useState(false);
  const [time, setTime] = useState(
    new Array(24)?.fill("").map((i, ind) => `${ind}:00-${ind + 1}:00`)
  );
  const [editTemplateDone, setEditTemplateDone] = React.useState<any[]>([]);
  const [handlerSelectTime, sethandlerSelectTime]: any = useState(null);

  const [isCheckErrorModal, setCheckErrorModal] = useState(false);
  const [templateErrors, setTemplateErrors] = useState<any[]>([]);

  // const [select, setSelect]: any = React.useState([]);
  const [selectedInfo, setSelectedInfo] = useState<any>();
  // const [selectIndex, setSelectIndex] = useState<any>(-1);
  
  const [selectCheckboxTime, setSelectCheckboxTime] = useState<any>();

  const getTemplateErrors = () => {
    const errorStrings = logErrors(listCreateTemplateXml);
    return errorStrings;
  };

  const saveHandler = () => {
    if (!listCreateTemplateXml.length){
        setTemplateErrors([
          `Шаблон не заполнен!`,
        ]);
        setCheckErrorModal(true);
        return;
    }
    let totalUsed = 0;
    for (let item of selected) {
      let used = 0;
      const list = listCreateTemplateXml.find((i) => i.id === item.value);
      for (let date of list.value) {
        used += date.value.reduce((acc, item) => {
          const number = Number.parseInt(item);
          if (number) {
            acc += number;
          }
          return acc;
        }, 0);
      }
      totalUsed += used;
      if (item.count - used < 0) {
        setTemplateErrors([
          `В шаблоне ${item.label} использовано слишком много объявлений!`,
        ]);
        setCheckErrorModal(true);
        return;
      }
    }

    const errorStrings = getTemplateErrors();
    if (errorStrings.length) {
      setTemplateErrors(errorStrings);
      setCheckErrorModal(true);
    } else if (selected.length) {
      setTotalCount(totalUsed);
      setOpenModal(true);
    }
  };

  const deleteTemplate = React.useCallback(
    (id: string) => {
      const list = selected.filter((item) => item.value !== id);
      setSelected(list);
    },
    [selected]
  );

  const configXml = () => {
    const list = selected.map((item, index) => ({
      id: item.value,
      massParam: listCreateTemplateXml[index],
      idCategory: item?.idCategory,
    }));
    return list;
  };

  useEffect(() => {
    if (listTemplate && editTemplateDone?.length) {
      const newList = options.filter((item) => {
        return editTemplateDone.find((i) => i.id === item.value);
      });
      setSelected(newList);
    }
  }, [listTemplate, editTemplateDone]);

  useEffect(() => {
    if (isEdit && editTemplate && editTemplate?.id) {
      fetchTemplate(
        userId,
        false,
        true,
        JSON.parse(editTemplate.templates),
        editTemplate?.id,
        true
      );
      setEditTemplateDone(JSON.parse(editTemplate.templates));
    } else {
      fetchTemplateCreate();
      //fetchTemplate(userId, true, true);
    }

    return () => {
      clearTemplate();
    };
  }, []);

  useEffect(() => {
    if (isLoaderCreate == "done") {
      setOpenModal(false);
      history.push("/xmlList");
    }
    if (isLoaderCreate == Loaders.fetchCreateXml) {
      setTimeout(() => {
        setOpenModal(false);
        history.push("/xmlList");
      }, 1000);
    }
  }, [isLoaderCreate]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoaderTemplate(false);
    }, 3000);
  }, [options]);

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <div
          className="relative md:ml-64 bg-blueGray-100"
          style={{ width: " calc(100vw - 232px)" }}
        >
          <div
            style={{
              backgroundColor: "rgba(2, 132, 199, var(--tw-bg-opacity)",
              width: "100%",
              height: 120,
              padding: 30,
            }}
          >
            <Modal
              open={isCheckErrorModal}
              handleClose={() => setCheckErrorModal(false)}
              title="Ошибки в шаблонах"
              cancelTextButtom="Ок"
              submitButtom={() => setCheckErrorModal(false)}
            >
              {templateErrors.length ? (
                templateErrors.map((errorMessage, index) => (
                  <div key={index}>{errorMessage}</div>
                ))
              ) : (
                <div>Нет ошибок в выбранных шаблонах</div>
              )}
            </Modal>

            <Modal
              open={openTime}
              handleClose={() => {
                setOpenTime(false);
              }}
              title={""}
              cancelTextButtom="Отменить"
              submitTextButtom="Сохранить"
              submitButtom={handleModal}
            >
              <div style={{ padding: 0, width: 300 }}>
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Выберете интервалы времени
                </label>
                {time?.map((i, ind) => {
                  return (
                    <div style={{ padding: "5px" }} key={ind}>
                      <div>
                        <input
                          type="checkbox"
                          style={{ margin: "10px" }}
                          id={ind.toString()}
                          checked={
                            selectCheckboxTime 
                            && selectCheckboxTime.includes(`${ind}:00-${ind + 1}:00`)
                          }
                          onChange={(event: any) => {
                            handlerSelectTime &&
                              handlerSelectTime(ind, event.target.checked);
                          }}
                        />
                        {ind}:00-{ind + 1 >= 24 ? 0 : ind + 1}:00
                      </div>
                    </div>
                  );
                })}
              </div>
            </Modal>
            <Modal
              open={openModal}
              handleClose={() => {
                setOpenModal(false);
              }}
              title={""}
              cancelTextButtom="Отменить"
              submitTextButtom="Сохранить"
              submitButtom={() => {
                if (isLoaderCreate === Loaders.fetchCreateXml) return;
                const socket = socketEmitter.clientSocket;
                createXmlFetch({
                  socketId: String(socket.id),
                  value: listCreateTemplateXml,
                  listTemplate: listTemplate.filter((item) =>
                    selected.find((search) => {
                      return search.value === item.id;
                    })
                  ),
                  userId: Number(userId),
                  name: nameXml,
                  templates: configXml(),
                  count: totalCount,
                  isUpdate: !!editTemplate,
                  idXml: editTemplate?.id,
                });
              }}
            >
              <div style={{ padding: 0, width: 300 }}>
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  {isLoaderCreate === Loaders.fetchCreateXml
                    ? "Идет создание автозагрузки"
                    : "Введите имя АВТОЗАГРУЗКИ"}
                </label>
                {isLoaderCreate === Loaders.fetchCreateXml ? (
                  <CircularProgress
                    style={{
                      color: "#1DA5E9",
                    }}
                    size={40}
                  />
                ) : (
                  <input
                    id="nameTemplate"
                    name="name"
                    value={nameXml}
                    disabled={!!editTemplate}
                    onChange={(values) => setNameXml(values.target.value)}
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                )}
              </div>
            </Modal>
            <MultiSelect
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              options={options}
              value={selected}
              onChange={setSelected}
              labelledBy="Select"
              disabled={isLoader}
              ClearSelectedIcon={<span></span>}
            />
          </div>
          <div
            id="cardXmlList"
            style={{
              width: "100%",
              height: `calc(100vh - 120px)`,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {(selected.length && editTemplateDone?.length) ||
            listTemplate.length ? (
              <div
                ref={list}
                style={{
                  height: "89%",
                  maxHeight: "89%",
                  marginTop: "-74px",
                  overflow: "auto",
                  transition: " all 2s ease 0s",
                }}
              >
                {selected.map((item, index) => {
                  const editValue = editTemplateDone.find(
                    (i) => i.id === item.value
                  );
                  return (
                    <Card
                      setOpenTime={setOpenTime}
                      setDateTime={setDateTime}
                      sethandlerSelectTime={sethandlerSelectTime}
                      sethandleModal={sethandleModal}
                      open={open}
                      setOpen={setOpen}
                      dateTime={dateTime}
                      ediValues={editValue ? editValue?.massParam : null}
                      img={item?.img}
                      key={item.value + index}
                      index={index}
                      count={item.count}
                      id={item.value}
                      name={item.label}
                      delete={deleteTemplate}
                      chekId={item.chekId}
                      selectedInfo={selectedInfo}
                      setSelectedInfo={setSelectedInfo}
                      setSelectCheckboxTime={setSelectCheckboxTime}
                      listCreateTemplateXml={listCreateTemplateXml}
                      // select={select}
                      // setSelect={setSelect}
                    />
                  );
                })}
              </div>
            ) : isLoaderCreate !== "" ? (
              <>
                <CircularProgress
                  size={40}
                  style={{
                    color: "#1DA5E9",
                  }}
                />
              </>
            ) : (
              <div className="text-blueGray-600 text-lg">
                Нету свободных шаблонов
              </div>
            )}
            <div
              style={{
                transition: " all 2s ease 0s",
                width: "100%",
                height: `${tooltipHeight}px`,
                bottom: 0,
                boxShadow: "rgb(203 213 225) 0px 0px 5px 0px",
                background: "white",
                borderRadius: "9px",
                position: "absolute",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: 70,
                  height: 63,
                  background: "#0284c7",
                  right: 50,
                  top: -20,
                  zIndex: 99999,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
                onClick={() => {
                  if (tooltipHeight == 70) setTooltipHeight(300);
                  else setTooltipHeight(70);
                }}
              >
                <i
                  className="fas fa-chevron-up"
                  style={{
                    position: "absolute",
                    transform: "scale(2.5)",
                    left: "42%",
                    top: "30%",
                    color: "white",
                    zIndex: 1,
                  }}
                  onClick={() => {
                    if (tooltipHeight == 70) setTooltipHeight(300);
                    else setTooltipHeight(70);
                  }}
                ></i>
              </div>
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  overflow: "hidden",
                  display: "flex",
                  transition: "all 2s ease",
                  height: tooltipHeight == 70 ? "0" : "100%",
                  visibility: tooltipHeight == 70 ? "hidden" : "visible",
                  opacity: tooltipHeight == 70 ? 0 : 1,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    flex: "4",
                    overflowY: "auto",
                  }}
                >
                  <label className="py-2">Сохранение следующих шаблонов:</label>
                  {selected.map((template, index) => (
                    <div
                      className="shadow"
                      style={{ display: "flex", width: "100%" }}
                      key={template.value + index}
                    >
                      <label style={{ flex: "5", textAlign: "center" }}>
                        {template.label}
                      </label>
                    </div>
                  ))}
                  {!selected.length && <div>Пусто. Выберите шаблоны</div>}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    flex: "1",
                    marginTop: "50px",
                  }}
                >
                  <button
                    className="text-white font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                    style={{ background: "#0284c7" }}
                    type="button"
                    onClick={saveHandler}
                  >
                    Сохранить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTemplate: (
      userId: string,
      isActive: boolean,
      all: boolean,
      v?: any[],
      idXml?: string,
      isPhotos?: boolean
    ) =>
      dispatch(
        getTemplate.started({
          userId,
          isActive,
          all,
          sort: v,
          idXml,
          isPhotos,
        })
      ),
    fetchTemplateCreate: () => dispatch(getTemplateCreate.started({})),

    createXmlFetch: (value: any) => dispatch(createXml.started(value)),

    clearTemplate: () => dispatch(сlearTemplateXml(undefined)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    listTemplate: state.Reducer.listTemplate,
    userId: state.Reducer.user.userId,
    isLoader: state.Reducer.loaderName === Loaders.fetchTemplate,
    isLoaderCreate: state.Reducer.loaderName,
    isErrorTemplate: state.Reducer.loaderName === Loaders.fetchTemplateError,
    listCreateTemplateXml: state.Reducer.templateXML,
    editTemplate: state.Reducer.editXml,
    templateXMLStatus: state.Reducer.templateXMLStatus,
  };
};

export default compose<CreateXmlPrivateProps, CreateXmlProps>(
  connect(mapStateToProps, mapDispatchToProps)
)(CreateXml);
