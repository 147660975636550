import React from "react";
import CustomModal from "../../shared/Modal";
import SberbankIPay from "react-sberbank-ipay";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import { cloudPrice, dayTarif } from "../../constant/constants";
interface PayModalProp {
  open: boolean;
  price: number;
  xml: number;
  buy: number;
  newBuy: number;
  count: number;
  activeXml: number;
  sharedXmlFile: (params: {
    id: number;
    isAvito: boolean;
    userId: number;
  }) => void;
  id: number;
  dataXml: any;
  buyFn: (
    userId: number,
    cloud: number,
    ads: number,
    xml: number,
    price: number,
    isNew: boolean,
    orderNumber: string
  ) => void;
  closeModal: (value: boolean) => void;
  widgets: any;
}

export const PayModal: React.FC<PayModalProp> = ({
  open,
  price,
  xml,
  buy,
  newBuy,
  count,
  activeXml,
  dataXml,
  sharedXmlFile,
  buyFn,
  closeModal,
  widgets,
  ...a
}) => {
  const [openModal, setOpenModal] = useState(open);
  useEffect(() => {
    setOpenModal(open);
  }, []);
  const buyXml: number = !!widgets.wallet.isFree
    ? activeXml + 1
    : xml - activeXml - 1 >= 0
    ? 0
    : 1;
  const buyAds = buy - count - newBuy >= 0 ? 0 : Math.abs(buy - count - newBuy);

  const [cloud, setCloud] = useState(0);
  useEffect(() => {
    const result = cloudPrice * Number(cloud);
    const cloudNew = result ? result : 0;
    const isFree = !!widgets.wallet.isFree;

    const dayPay = widgets?.countDay ? widgets?.countDay : dayTarif;
    console.log(isFree, result, price, cloudNew, dayTarif, dayPay);
    if (isFree) {
      setPrice(price + cloudNew);
    } else {
      setPrice(Number((((price + cloudNew) / dayTarif) * dayPay).toFixed(2))); ///////////////
    }
  }, [cloud]);

  const [newPrice, setPrice] = useState(price);
  useEffect(() => {
    return () => {};
  }, []);

  // const [day, setDay] = useState(widgets?.wallet?.isFree?dayTarif:widgets?.wallet?.date?moment(widgets?.wallet?.date).diff(moment.utc(new Date), 'days')+1:30);
  // useEffect(()=>{
  //   if(!widgets?.wallet?.isFree)
  //   {setDay(widgets?.wallet?.date?moment(widgets?.wallet?.date).diff(moment.utc(new Date), 'days')+1:30);}
  //   else {setDay(dayTarif)}
  // },[widgets])
  return (
    <>
      <CustomModal
        open={openModal}
        maxWidth="100%"
        handleClose={() => {
          closeModal(false);
        }}
        title={""}
        submitButtom={() => {}}
      >
        <div>
          <p style={{ fontSize: 18, width: 500, marginBottom: 10 }}>
            Текущий тариф
          </p>
          <p>
            Автозагрузок {xml}
            <span style={{ marginLeft: 20 }}>Обьявлений {buy}</span>
          </p>
          <p style={{ fontSize: 18, marginTop: 30, marginBottom: 10 }}>
            {widgets?.wallet.ads == 0 && widgets?.wallet.xml == 0
              ? "Минимальный тариф "
              : "Не хватает"}
          </p>
          {widgets?.wallet.ads == 0 && widgets?.wallet.xml == 0 ? (
            <p>
              Автозагрузок {1}
              <span style={{ marginLeft: 20 }}>Обьявлений {1000}</span>
            </p>
          ) : (
            <p>
              Автозагрузок {buyXml}
              <span style={{ marginLeft: 20 }}>Обьявлений {buyAds}</span>
            </p>
          )}

          <br></br>
          <span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: 78,
              }}
            >
              <button
                className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                type="button"
                onClick={() => {
                  //"21101005"//new Date().getTime().toFixed(0)
                  // console.log('oldOrder',order)
                  SberbankIPay.ipayCheckout(
                    {
                      amount: newPrice > 1 ? newPrice : 1,
                      currency: "RUB",
                      description: `Покупка автозагрузки на сумму ${price}`,
                    },
                    function (order: any) {
                      buyFn(
                        dataXml?.userId,
                        cloud,
                        widgets?.wallet.ads == 0 && widgets?.wallet.xml == 0
                          ? 1000
                          : buyAds,
                        widgets?.wallet.ads == 0 && widgets?.wallet.xml == 0
                          ? 1
                          : buyXml,
                        newPrice > 1 ? newPrice : 1,
                        !!widgets?.wallet?.isFree,
                        order.orderNumber
                      );
                      sharedXmlFile({
                        id: dataXml?.item.id,
                        userId: dataXml?.userId,
                        isAvito: true,
                      });
                    },
                    function (order: any) {
                      console.log("error", order);
                      // showFailurefulPurchase(order);
                    }
                  );
                  setOpenModal(false);
                }}
              >
                Оплатить
              </button>
              <span>Необходимо доплатить:{newPrice} руб.</span>
            </div>
          </span>
        </div>
      </CustomModal>
      <SberbankIPay.Preloader
        apiToken="j850lpaje7jcrm8h4tm3jr0dke"
        language="ru"
        classNamePreloader="payment-preloader"
      />
    </>
  );
};
