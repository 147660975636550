import { combineReducers } from "redux";
import { Reducer, TypeState } from "./reducer";
import { reducer as formReducer } from "redux-form";
export interface RootState {
  Reducer: TypeState;
  form: ReturnType<typeof formReducer>;
}

const rootReducer = combineReducers<RootState>({
  Reducer,
  form: formReducer,
});

export default rootReducer;
