import styled from "styled-components"

interface FormStyleProps {
  bg?: string
}

export const FormStyle = styled.div<FormStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: start;
  color: black;
  text-transform: uppercase;
  background-color: ${props => props.bg};
  padding: 50px;
  border-radius: 5px;
`
