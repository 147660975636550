import React, {FC, KeyboardEvent} from 'react';
import {TextField, Slider} from "@mui/material";
import {FieldStyle} from "./Field.style";

interface PropsField {
  text: string;
  min: number;
  max: number;
  defaultValue: number;
  value: number;
  setValue: (value: any) => void;

}

const Field: FC<PropsField> = ({text, min, max, defaultValue, setValue, value}) => {


  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handlerDisableLetters = (event: KeyboardEvent<HTMLImageElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }

  }


  const handleBlur = () => {
    if (value < min) {
      setValue(min);
    } else if (value > max) {
      setValue(max);
    }
  };

  return (
    <>
      <FieldStyle>
        {text}
        <TextField
          value={value}
          variant="standard"
          size="small"
          onChange={handleInputChange}
          onBlur={handleBlur}
          defaultValue={defaultValue}
          inputProps={{inputMode: 'numeric', pattern: "[0-9]*"}}
          onKeyDown={handlerDisableLetters}
        />
      </FieldStyle>
      <Slider
        min={min}
        max={max}
        value={value}
        onChange={handleSliderChange}
        aria-label="Default"
        valueLabelDisplay="auto"

      />
    </>
  );
};

export default Field;