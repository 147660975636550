import React, {FC} from 'react';
import {ResultStyle} from "./Result.style";

interface ResultProps {
  children: JSX.Element;
  text: number

}
const Result: FC<ResultProps> = ({children, text}) => {
  return (
    <ResultStyle>
      Цена тарифа: {text} рублей
       {/* {children} */}
     </ResultStyle> 
    // <p>Цена тарифа:{text} рублей</p>  
  );
};

export default Result;