import styled from "styled-components";

export const StyledXmlTableRow = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #fff;
  & div {
    flex-basis: 200px;
    flex-shrink: 0;
    /* text-overflow: ellipsis;
    overflow: hidden; */
  }
`;

export const StyledXmlTable = styled.div`
  display: flex;
  flex-direction: column;

  & ${StyledXmlTableRow} {
    flex-basis: 100px;
    flex-shrink: 0;
  }
`;

export const StyledXmlTableCell = styled.div`
  text-overflow: ellipsis;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100px;
  border-top: 1px solid;
  border-left: 1px solid;
  padding: 10px;
`;
