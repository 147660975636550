import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { compose } from "recompose";
import { reduxForm, Field, getFormValues } from "redux-form";
import { Form, Loaders } from "../../constant/constants";
import { logging, setLoader, setUser } from "../../redux/actions/actions";
import { useHistory, useLocation } from "react-router-dom";
import { eraseCookie } from "../../helpers/cookies";
import { setAccessToken, setRefreshToken } from "../../api/auth/tokens";
interface LoginProps {}

interface LoginPrivateProps extends LoginProps {
  formValues: { email: string; password: string };
  fetchLogin: (email: string, password: string) => () => void;
  isError: boolean;
  isLogging: boolean;
  clearLoader: () => void;
  setUser: () => void;
}




const Login: React.FC<LoginPrivateProps> = ({
  formValues,
  fetchLogin,
  isError,
  isLogging,
  clearLoader,
  setUser,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [validateError, setValidateError]:any = useState(null);
  const [emailErrorFlag, setEmailErrorFlag]:any = useState(null);
  const [passwordErrorFlag, setPasswordErrorFlag]:any = useState(null);

  const EmailError = (isError || emailErrorFlag) ? { border: "1px solid red" } : {};
  const PasswordError = (isError || passwordErrorFlag) ? { border: "1px solid red" } : {};

  if (isLogging) {
    clearLoader();
    history.push(`/admin/dashboard`);
  }

  React.useEffect(() => {
    setUser();
    // eraseCookie("authorization");
    // eraseCookie("email");
    // eraseCookie("userId");
    // localStorage.removeItem("authorization");
    // localStorage.removeItem("email");
    // localStorage.removeItem("userId");
    setAccessToken("", 0);
    setRefreshToken("", 0);
  }, []);

  function clearValidation(){
    setValidateError("");
    setEmailErrorFlag(false);
    setPasswordErrorFlag(false);
  }

  function validate(){
    clearValidation();
    clearLoader();
    
    if (formValues?.email?.length === 0){
      setValidateError("Введите email");
      setEmailErrorFlag(true);
      return;
    }

    var regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regExp.test(formValues?.email)){
      setValidateError("Неправильный формат почты");
      setEmailErrorFlag(true);
      return;
    }

    if (formValues?.password?.length < 8){
      setValidateError("Пароль должен иметь 8 и более символов");
      setPasswordErrorFlag(true);
      return;
    }
    
    fetchLogin(formValues?.email, formValues?.password)();
  };

  return (
    <>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full lg:w-4/12 px-4">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
              <div className="rounded-t mb-0 px-6 py-6">
                <div className="text-center mb-3">
                  <h6 className="text-blueGray-500 text-sm font-bold">Войти</h6>
                </div>
                <hr className="mt-6 border-b-1 border-blueGray-300" />
              </div>
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <form>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Email
                    </label>
                    <Field
                      component="input"
                      name="email"
                      type="email"
                      style={EmailError}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Email"
                    />
                  </div>
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      Пароль
                    </label>
                    <Field
                      component="input"
                      type="password"
                      name="password"
                      style={PasswordError}
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Password"
                    />
                  </div>
                  {/* <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                      />
                      <span className="ml-2 text-sm font-semibold text-blueGray-600">
                        Remember me
                      </span>
                    </label>
                  </div> */}
                  <div className="text-center mt-6">
                    <button
                      onClick={validate}
                      
                      
                      className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      type="button"
                    >
                      Войти
                    </button>
                  </div>
                  {isError && (
                    <div className="mt-3 text-center" style={{ color: "red" }}>
                      { "Неверный пароль или логин!" }
                    </div>
                  )}
                  <div className="mt-3 text-center" style={{ color: "red" }}>
                    { validateError }
                  </div>

                </form>
              </div>
            </div>
            <div className="flex flex-wrap mt-6 relative">
              <div className="w-1/2">
                <Link to="/auth/refresh" className="text-blueGray-200">
                  <small>Востановить пароль?</small>
                </Link>
              </div>
              <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-blueGray-200">
                  <small>Зарегистрироваться</small>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};


const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchLogin: (email: string, password: string) => () =>{
      dispatch(logging.started({ email, password }))
    },
    clearLoader: () => dispatch(setLoader("")),
    setUser: () => dispatch(setUser(null)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    formValues: getFormValues(Form.Login)(state),
    isError: state.Reducer.loaderName === Loaders.fetchLoginFalse,
    isLogging: state.Reducer.loaderName === Loaders.fetchLoginTrue,
  };
};

// export default connect(mapStateToProps,mapDispatchToProps)(Login)
export default compose<LoginPrivateProps, LoginProps>(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: Form.Login,
    enableReinitialize: true,
    destroyOnUnmount: true,
    initialValues: {
      email: "",
      password: "",
    },
  })
)(Login);
