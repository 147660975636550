import React from "react";
import CustomModal from "../Modal";
import SberbankIPay from "react-sberbank-ipay";
import { useState } from "react";
import { useEffect } from "react";
import { cloudPrice, dayTarif } from "../../constant/constants";
import moment from "moment";
interface PayModalCloudProp {
  buyFn: (
    userId: number,
    cloud: number,
    ads: number,
    xml: number,
    price: number,
    isNew: boolean,
    orderNumber: string
  ) => void;
  closeModal: (value: boolean) => void;
  open: boolean;
  widgets: any;
  userId: number;
  min?: number;
}

export const PayModalCloud: React.FC<PayModalCloudProp> = ({
  buyFn,
  closeModal,
  open,
  widgets,
  userId,
  min,
}) => {
  useEffect(() => {}, []);

  const [cloud, setCloud] = useState(min || 0);
  const [day, setDay] = useState(
    widgets?.wallet?.isFree
      ? dayTarif
      : widgets?.wallet?.date
      ? moment(widgets?.wallet?.date).diff(moment.utc(new Date()), "days") + 1
      : 30
  );
  useEffect(() => {
    if (!widgets?.wallet?.isFree) {
      setDay(
        widgets?.wallet?.date
          ? moment(widgets?.wallet?.date).diff(moment.utc(new Date()), "days") +
              1
          : 30
      );
    } else {
      setDay(widgets?.wallet?.date ? dayTarif : 30);
    }
  }, [widgets]);
  useEffect(() => {
    setCloud(min || 0);
  }, [min]);

  // const [newPrice, setPrice] = useState(price);
  // useEffect(() => {
  //   return () => {
  //     console.log("unmount");
  //   };
  // }, []);
  const fn = (value: number) => {
    return Number(value.toFixed(2));
  };
  return (
    <>
      <CustomModal
        open={open}
        maxWidth="100%"
        handleClose={() => {
          closeModal(false);
        }}
        title={""}
        submitButtom={() => {}}
      >
        <div>
          <p
            style={{
              fontSize: 18,
              width: 400,
              marginBottom: 10,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>Текущий обьем на диске </span>
            <span>
              {(widgets?.wallet?.cloud / 1000).toFixed(2)}/
              {(widgets?.wallet?.cloudLength / 1000).toFixed(0)}ГБ
            </span>
          </p>
          <span>
            <p style={{ fontSize: 14, marginTop: 30 }}>Приобрести:</p>
            <div style={{ display: "flex", alignItems: "center" }}>
              <input
                id="nameTemplate"
                name="Title"
                onChange={(event) => {
                  const value = Number(event.target.value) || 0;
                  setCloud(value);
                }}
                value={cloud}
                type="number"
                min={min || 0}
                style={{ border: "1px solid #ccc" }}
                className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
              />
              <span style={{ marginLeft: 10 }}>ГБ</span>
            </div>
            <span style={{ fontSize: 14 }}>
              Цена:{fn(((cloud * cloudPrice) / 30) * day)}
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "column",
                height: 78,
              }}
            >
              <button
                className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                type="button"
                onClick={() => {
                  closeModal(false);
                  SberbankIPay.ipayCheckout(
                    {
                      amount: fn(((cloud * cloudPrice) / 30) * day),
                      currency: "RUB",
                      order_number: new Date().getTime().toFixed(0),
                      description: `Покупка автозагрузки на сумму ${fn(
                        ((cloud * cloudPrice) / 30) * day
                      )}`,
                    },
                    function (order: any) {
                      console.log(order);
                      if (buyFn) {
                        buyFn(
                          userId,
                          cloud,
                          0,
                          0,
                          fn(((cloud * cloudPrice) / 30) * day),
                          !!widgets?.wallet?.isFree,
                          order.orderNumber
                        );
                      }
                    },
                    function (order: any) {
                      // showFailurefulPurchase(order);
                    }
                  );
                }}
              >
                Оплатить
              </button>
            </div>
          </span>
        </div>
      </CustomModal>
      <SberbankIPay.Preloader
        apiToken="j850lpaje7jcrm8h4tm3jr0dke"
        language="ru"
        classNamePreloader="payment-preloader"
      />
    </>
  );
};
