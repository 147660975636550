import React from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import TitlePhoto from "../TitlePhoto/TitlePhoto";
import { useState } from "react";
import CustomTooltip from "../CustomTooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { paginateTableGet } from "../../redux/actions/actions";
const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    // border: 1px solid #9e9e9e;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid #9e9e9e;
      border-right: 1px solid #9e9e9e;

      :last-child {
        border-right: 0;
      }

      input {
        font-size: 1rem;
        padding: 0;
        margin: 0;
        border: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }
`;

// Create an editable cell renderer
const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return <input value={value} onChange={onChange} onBlur={onBlur} />;
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
  Cell: EditableCell,
};

// Be sure to pass our updateMyData and the skipPageReset option
function Table({
  columns,
  data,
  updateMyData,
  skipPageReset,
  tableData,
  idTemplate,
  countNewData,
  updateData,
  pageT,
  setPageT,
  saveData,
}) {
  // For this example, we're using pagination to illustrate how to stop
  // the current page from resetting when our data changes
  // Otherwise, nothing is different here.
  // console.log("data", data, columns);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: !skipPageReset,
      updateMyData,
      initialState: {
        pageSize: 100,
      },
    },
    usePagination
  );
  const [open, setVisible] = useState({});

  const changeState = (value, id) => {
    setVisible({ open: value, id });
  };
  const showModal = (id) => () => {
    if (open.value === true) {
      changeState(false);
      changeState(true, id);
    }
    changeState(true, id);
  };

  // Render the UI for your table
  const maxPage = Math.ceil(Number(countNewData) / 20);

  const dispatch = useDispatch();
  return (
    <>
      <div>
        <table
          {...getTableProps()}
          style={{ borderRadius: "1rem", display: "inline-block" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                style={{ color: "#9e9e9e" }}
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page?.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{}}>
                  {row.cells.map((cell, index) => {
                    const indRow = cell?.row || cell;
                    const photo =
                      index == 2
                        ? tableData[indRow?.index]?.row[2]?.files?.files
                        : [];

                    return (
                      <>
                        <td {...cell.getCellProps()} style={{ color: "black" }}>
                          {
                            <>
                              {!!photo?.length &&
                                open.id === indRow?.index &&
                                open.open && (
                                  <div style={{ position: "relative" }}>
                                    <TitlePhoto
                                      photoList={photo}
                                      changeState={changeState}
                                    />
                                  </div>
                                )}

                              <span
                                onMouseEnter={
                                  index == 2 && cell?.value
                                    ? showModal(indRow?.index)
                                    : () => {}
                                }
                                style={{ cursor: "pointer" }}
                                title={cell?.value}
                              >
                                {/* <CustomTooltip title={'photwewefwefw'} interactive disabled={false}> */}
                                {cell.render("Cell")}
                                {/* </CustomTooltip> */}
                              </span>
                            </>
                          }
                        </td>
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <span>
          Страница{" "}
          <strong>
            {pageT + 1} из {maxPage}
          </strong>{" "}
        </span>
        <span>
          | Перейти к:{" "}
          <button
            onClick={() => {
              updateData(pageT, idTemplate, saveData()(), pageT - 1);
              // dispatch(
              //   paginateTableGet.started({ page: pageT - 1, id: idTemplate })
              // );
              setPageT(pageT - 1);
            }}
            disabled={pageT + 1 == 1}
          >
            {"<"}
          </button>
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{
              width: "100px",
              borderRadius: "1rem",
              display: "inline-block",
            }}
            minLength={0}
            value={pageIndex + 1}
          />
          <button
            onClick={() => {
              //nextPage()
              updateData(pageT, idTemplate, saveData()(), pageT + 1);
              // dispatch(
              //   paginateTableGet.started({ page: pageT + 1, id: idTemplate })
              // );
              setPageT(pageT + 1);
            }}
            disabled={pageT + 1 == maxPage}
          >
            {">"}
          </button>
        </span>
      </div>
    </>
  );
}

function App({
  tableData,
  setFn,
  idTemplate,
  countNewData,
  loaderTable,
  updateData,
  setPage,
}) {
  const column = () => {
    let headerForData = [];
    if (tableData?.length) {
      headerForData = tableData[0].row?.reduce((acc, item) => {
        acc.push({
          Header: item?.name,
          accessor: item?.name,
        });
        return acc;
      }, []);
    }
    return headerForData;
  };
  const columns = column();

  let headerForData = [];
  if (tableData?.length) {
    const a = tableData[0]?.row || tableData[0];
    headerForData = a?.reduce((acc, item) => {
      acc.push({
        Header: item?.name,
        accessor: item?.name,
      });
      return acc;
    }, []);
  }

  const [data, setData] = React.useState(() => {
    const newData = tableData?.map((item) => {
      const t = item?.row || item;
      return t?.reduce((acc, i) => {
        acc[i?.name] = i?.value;
        return acc;
      }, {});
    });
    return newData;
  });

  useEffect(() => {
    tableData?.length &&
      setData(
        tableData?.map((item) => {
          const dat = item?.row || item;
          return dat?.reduce((acc, i) => {
            acc[i?.name] = i?.value;
            return acc;
          }, {});
        })
      );
  }, [tableData]);
  const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  useEffect(() => {
    setFn(saveData);
    //
  }, [data]);

  useEffect(() => {
    setFn(saveData);
    //
  }, []);

  React.useEffect(() => {
    setSkipPageReset(false);
  }, [data]);

  const resetData = () => setData(originalData);

  const saveData = () => {
    return () => {
      const newTableData = tableData;
      data.map((item, index) => {
        Object.keys(item).map((i) => {
          const find = newTableData[index].row.findIndex((j) => j.name == i);

          if (find > -1) {
            newTableData[index].row[find].value = item[i];
          }
        });
      });
      return tableData
        .filter((k) => k?.row?.length)
        .map((item, index) => {
          const result = item?.row.map((i) => {
            const search = data[index][i?.name];
            return { ...i, value: search };
          });
          return result;
        });
    };
  };
  const isLoader = true;
  const [pageT, setPageT] = useState(0);
  useEffect(() => {
    setPage(pageT);
  }, [pageT]);
  return (
    <Styles style={{ overflow: "initial" }}>
      {/* <button onClick={resetData}>Отменить изменения</button>
      {console.log(data)} */}
      {loaderTable || !columns?.length || !data?.length ? (
        <div
          style={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            size={70}
            style={{
              color: "#1DA5E9",
            }}
          />
        </div>
      ) : (
        <Table
          pageT={pageT}
          setPageT={setPageT}
          updateData={updateData}
          saveData={saveData}
          columns={columns}
          data={data}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          tableData={tableData}
          idTemplate={idTemplate}
          countNewData={countNewData}
        />
      )}
    </Styles>
  );
}

export default App;
