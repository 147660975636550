import React, { useEffect, useRef, useState } from "react";
import { reduxForm, Field, getFormValues } from "redux-form";
import { reset } from "redux-form";
import Alert from "@material-ui/lab/Alert";
import { Form, Loaders } from "../../constant/constants";
import Modal from "../../shared/ShoesModal/ShoesModal";
import CardStats from "../Cards/CardStats.js";
import { connect, useDispatch } from "react-redux";
import { compose } from "recompose";
import { PayModalCloud } from "../../shared/PayModalCloud";
import moment from "moment";
import {
  getCategory,
  setInfoStatus,
  setOpenCreateTemlate,
  buy,
  buyCloud,
  clearCategory,
} from "../../redux/actions/actions";
import { Link, useHistory } from "react-router-dom";

function HeaderStats({
  fetchCategory,
  category,
  subCategory,
  itemsCategory,
  formValues,
  noCategories,
  objectCategory,
  serviceCategory,
  otherCategory,
  reff,
  message,
  widgets,
  setOpenCreateTemlate,
  isOpenCreateTemlate,
  userId,
  buyCloudStatus,
  buyCloud,
  buy,
  clearCategory,
  clear,
}) {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultEdit = useRef();
  const isValid =
    (!!formValues?.otherCategory ||
      (serviceCategory?.length == 1 && !!objectCategory?.length) ||
      (objectCategory?.length == 1 && !!itemsCategory?.length) ||
      (itemsCategory.length == 1 && !!subCategory.length) ||
      otherCategory.length === 1) &&
    !Object.values(formValues).includes("0");

  useEffect(() => {
    fetchCategory({ id: null, index: 0 });
  }, []);
  useEffect(() => {
    if (formValues?.category) {
      fetchCategory({ id: formValues?.category, index: 1 });
      delete formValues.subCategory;
      delete formValues.itemsCategory;
      delete formValues.objectCategory;
      delete formValues.serviceCategory;
      delete formValues.otherCategory;
    }
  }, [formValues?.category]);

  useEffect(() => {
    if (formValues?.subCategory) {
      fetchCategory({ id: formValues?.subCategory, index: 2 });
      delete formValues.itemsCategory;
      delete formValues.objectCategory;
      delete formValues.serviceCategory;
      delete formValues.otherCategory;
    }
  }, [formValues?.subCategory]);

  useEffect(() => {
    if (formValues?.itemsCategory) {
      fetchCategory({ id: formValues?.itemsCategory, index: 3 });
      delete formValues.objectCategory;
      delete formValues.serviceCategory;
      delete formValues.otherCategory;
    }
  }, [formValues?.itemsCategory]);

  useEffect(() => {
    if (formValues?.objectCategory) {
      fetchCategory({ id: formValues?.objectCategory, index: 4 });
      delete formValues.serviceCategory;
      delete formValues.otherCategory;
    }
  }, [formValues?.objectCategory]);

  useEffect(() => {
    if (formValues?.serviceCategory) {
      fetchCategory({ id: formValues?.serviceCategory, index: 5 });
      delete formValues.otherCategory;
    }
  }, [formValues?.serviceCategory]);
  // const createRef = useRef();

  useEffect(() => {
    setOpen(!!isOpenCreateTemlate);
  }, [isOpenCreateTemlate]);
  const closeModal = (value) => {
    clearCategory();
    setOpen(false);
    setOpenCreateTemlate(null);
    clear("Header");
  };

  const categoryId =
    formValues?.otherCategory ||
    formValues?.serviceCategory ||
    formValues?.objectCategory ||
    formValues?.itemsCategory ||
    formValues?.subCategory;
  return (
    <>
      <div
        ref={reff}
        className="relative bg-lightBlue-600 "
        id="HeaderStats"
        style={
          {
            //  padding: '20px 0',
          }
        }
      >
        <div
          className="px-2 md:px-10 mx-auto w-full"
          style={{
            paddingTop: 20,
          }}
        >
          <div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle={`АКТИВНЫХ АВТОЗАГРУЗОК:`}
                  statTitle={`${widgets?.wallet?.xmlActive || 0}/${widgets?.wallet?.xml || 0}`}
                  statArrow=""
                  statDescripiron=""
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="АКТИВНЫХ ОБЪЯВЛЕНИЙ:"
                  statTitle={`${widgets?.wallet?.adsActive || 0}/${widgets?.wallet?.ads || 0}`}
                  statArrow="up"
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-lightBlue-500"
                />
              </div>
              {/* <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle="ХРАНИЛИЩЕ ФОТОГРАФИЙ:"
                  statTitle={`${
                    widgets?.wallet?.cloud
                      ? (widgets?.wallet?.cloud / 1000).toFixed(2)
                      : 0
                  } гб из ${widgets?.wallet?.cloudLength / 1000 || 0} гб`}
                  statArrow="up"
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fa fa-plus-circle fotSizev"
                  statIconColor="bg-green-500"
                  addCloud={true}
                  buyCloud={() => buyCloud(true)}
                />
              </div> */}
              <div className="w-full lg:w-6/12 xl:w-3/12 px-4">
                <CardStats
                  statSubtitle={"Цена текущего тарифа"}
                  statTitle={widgets?.wallet?.isFree ? "Пробный" : `${widgets?.wallet?.price}₽`}
                  statTitleSecond={``}
                  statArrow="up"
                  statPercent=""
                  statPercentColor="text-emerald-500"
                  statDescripiron="Since last month"
                  statIconName="fas fa-percent"
                  statIconColor="bg-lightBlue-500"
                  date={widgets?.wallet?.isFree || widgets?.wallet?.date}
                />
              </div>
            </div>
          </div>
        </div>
        {/* {!noCategories && <div style={{ height: 40, width: "100%" }}></div>} */}
        {!noCategories && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              // position: "absolute",
              // right: "1%",
              // left: "3%",
              margin: "20px 20px",
              alignItems: "flex-end",
            }}
          >
            <div
              className="relative  mb-3"
              style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
            >
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
              >
                Раздел
              </label>
              <div>
                <Field
                  component={"select"}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="category"
                  disabled={!(category?.length > 1)}
                >
                  {category?.length &&
                    category.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>
            <div
              className="relative  mb-3"
              style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
            >
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                //style={{ opacity: 0 }}
              >
                Категория
              </label>
              <div>
                <Field
                  component={"select"}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="subCategory"
                  disabled={!(subCategory?.length > 1)}
                >
                  {subCategory?.length &&
                    subCategory.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>

            <div
              className="relative  mb-3"
              style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
            >
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                // style={{ opacity: 0 }}
              >
                Подкатегория 1
              </label>
              <div>
                <Field
                  component={"select"}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="itemsCategory"
                  disabled={!(itemsCategory?.length > 1)}
                >
                  {itemsCategory?.length &&
                    itemsCategory.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>
            <div
              className="relative  mb-3"
              style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
            >
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                //style={{ opacity: 0 }}
              >
                 Подкатегория 2
              </label>
              <div>
                <Field
                  component={"select"}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="objectCategory"
                  disabled={!(objectCategory?.length > 1)}
                >
                  {objectCategory?.length &&
                    objectCategory.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>
            <div
              className="relative  mb-3"
              style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
            >
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                //style={{ opacity: 0 }}
              >
                 Подкатегория 3
              </label>
              <div>
                <Field
                  component={"select"}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="serviceCategory"
                  disabled={!(serviceCategory?.length > 1)}
                >
                  {serviceCategory?.length &&
                    serviceCategory.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>
            <div
              className="relative  mb-3"
              style={{ minWidth: 180, margin: "0 5px", width: "20%" }}
            >
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-password"
                //style={{ opacity: 0 }}
              >
                 Подкатегория 4
              </label>
              <div>
                <Field
                  component={"select"}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  name="otherCategory"
                  disabled={!(otherCategory?.length > 1)}
                >
                  {otherCategory?.length &&
                    otherCategory.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.value}
                        </option>
                      );
                    })}
                </Field>
              </div>
            </div>
            <button
              className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              style={{
                height: 46,
                minWidth: 110,

                margin: "0 0",
                // marginTop: 22,
                opacity: isValid ? "100%" : "50%",
                // marginRight: "2.6rem",
                // marginLeft: 10,
              }}
              onClick={isValid ? setOpen : undefined} //opacity: 50%;
              disabled={!isValid}
            >
              Cоздать
            </button>
          </div>
        )}
        {noCategories && (
          <div
            className="relative  mb-3"
            style={{
              margin: 0,
              width: "100%",
              paddingRight: "15px",
              paddingBottom: 10,
              textAlign: "right",
            }}
          >
            <button
              className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              style={{
                width: "224px",
                marginTop: 22,
              }}
              onClick={() => history.push("/xmlCreate")}
            >
              СОЗДАТЬ АВТОЗАГРУЗКУ
            </button>
          </div>
        )}
        {/* {noCategories && (
          <div>
          <button
            className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            style={{

              width: "224px",
              marginTop: 22,
            }}
            onClick={() => history.push("/xmlCreate")}
          >
            СОЗДАТЬ АВТОЗАГРУЗКУ
          </button>
          </div>
        )} */}
      </div>
      {open && (
        <Modal
          setOpen={closeModal}
          close={closeModal}
          title="Создание шаблона"
          idComponent={
            isOpenCreateTemlate && isOpenCreateTemlate?.idCategory
              ? isOpenCreateTemlate?.idCategory
              : categoryId
          }
        ></Modal>
      )}
      <PayModalCloud
        buyFn={buy}
        closeModall={() => buyCloud(false)}
        open={!!buyCloudStatus && !!buy}
        min={Number(buyCloudStatus)}
        widgets={widgets}
        userId={userId}
        buy={buy}
      ></PayModalCloud>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearCategory: () => dispatch(clearCategory()),
    fetchCategory: (id) => dispatch(getCategory.started(id)),
    setOpenCreateTemlate: (value) => dispatch(setOpenCreateTemlate(value)),
    buy: (userId, cloud, ads, xml, price, isNew, orderNumber) => {
      dispatch(
        buy.started({
          userId,
          cloud,
          ads,
          xml,
          price: String(price),
          isNew,
          orderNumber,
        })
      );
    },
    buyCloud: (value) => {
      dispatch(buyCloud(value));
    },
    clear: (value) => {
      dispatch(reset(value));
    },
  };
};

const mapStateToProps = (state) => {
  return {
    formValues: getFormValues(Form.Header)(state),
    isLoader: state.Reducer.loaderName === Loaders.fetchPhoto,
    category: state.Reducer.category,
    subCategory: state.Reducer.subCategory,
    itemsCategory: state.Reducer.itemsCategory,
    objectCategory: state.Reducer.objectCategory,
    serviceCategory: state.Reducer.serviceCategory,
    otherCategory: state.Reducer.otherCategory,
    message: state.Reducer.message,
    widgets: state.Reducer.widgets,
    isOpenCreateTemlate: state.Reducer.createTemlateModal,
    userId: state.Reducer.user.userId,
    buyCloudStatus: state.Reducer.buyCloud,
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: Form.Header,
    enableReinitialize: true,
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
    initialValues: {},
  })
)(HeaderStats);
