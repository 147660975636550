import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FC, useEffect, useState } from "react";
import api from "../../api";

interface StatisticsTableProps {
  templateId: string | string[];
}

const StatisticsTable: FC<StatisticsTableProps> = ({ templateId }: StatisticsTableProps) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [statistics, setStatistics] = useState<any>(null);

  useEffect(() => {
    if (!templateId) return;

    setLoading(true);
    if(!(templateId instanceof Array)) {
    api.comments
      .getStatisticsById({id: templateId, userId: 319970871})
      .then((res) => {
        setStatistics(res);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching statistics:", error);
        setLoading(false);
      });
  } else {
      const promises = templateId.map((id) => api.comments.getStatisticsById({id, userId: 319970871}));
      Promise.all(promises).then((res) => {
        console.log({res123123: res})
        setStatistics(res[0]);
        setLoading(false);
      })
    }
  }, [templateId]);
  return (
    <div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "600px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>

          {statistics ? (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Avito ID</TableCell>
                    <TableCell>URL</TableCell>
                    <TableCell>Avito статус</TableCell>
                    <TableCell>Уникальных просмотов</TableCell>
                    <TableCell>Уникальных контактов</TableCell>
                    <TableCell>Добавлено в избранное</TableCell>
                    <TableCell>Уникальных чатов</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {statistics?.avitoAd && statistics.avitoAd.map((ad: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{ad.avitoItem.items[0].ad_id}</TableCell>
                      <TableCell>{ad.avitoItem.items[0].avito_id}</TableCell>
                      <TableCell>{ad.avitoItem.items[0].url}</TableCell>
                      <TableCell>{ad.avitoItem.items[0].avito_status}</TableCell>
                      <TableCell>{ad.mappedStatistics.uniqViews}</TableCell>
                      <TableCell>{ad.mappedStatistics.uniqContacts}</TableCell>
                      <TableCell>{ad.mappedStatistics.uniqFavorites}</TableCell>
                      <TableCell>{ad.mappedStatistics.uniqChatUsers}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableBody>
                  {statistics?.avitoAd &&
                      <TableRow>
                          <TableCell>Итог:</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>{statistics.total.uniqViews}</TableCell>
                          <TableCell>{statistics.total.uniqContacts}</TableCell>
                          <TableCell>{statistics.total.uniqFavorites}</TableCell>
                          <TableCell>{statistics.total.uniqChatUsers}</TableCell>
                      </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>) : (<h3>Нет активных объявлений</h3>)}
        </div>
      )}
    </div>
  );
};

export default StatisticsTable;
