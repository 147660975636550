import React, {FC, useEffect, useState} from 'react';
import {Button} from '@mui/material';
import {FormStyle} from './Form.style';
import Field from "../field/Field";
import Result from "../result/Result";


const Form: FC = () => {
  const [downloads, setDownloads] = useState(1);
  const [ads, setAds] = useState(1000);
  const [result, setResult] = useState(1000)

  useEffect(() => {
    if (ads > downloads * 50000){
      setAds(downloads * 50000);
    }

    var finalSum = downloads * 380;
    switch(true){
      case (ads === 1000):
        finalSum += 600;
        break;
      case (ads < 2001):
        finalSum += 600 + (ads - 1000) * 0.5;
        break;
      case (ads < 3001):
        finalSum += 1100 + (ads - 2000) * 0.4;
        break;
      case (ads < 5001):
        finalSum += 1500 + (ads - 3000) * 0.3;
        break;
      case (ads < 10001):
        finalSum += 2100 + (ads - 5000) * 0.2;
        break;
      case (ads > 10000):
        finalSum += 3100 + (ads - 10000) * 0.1;
        break;
    };
    setResult(Math.floor(finalSum));
  }, [ads, downloads, result])

  return (
    <FormStyle bg={'white'} style={{ alignSelf: "center" }}>
      Настройка тарифа
      <Field
        text="Активные автозагрузки"
        max={50}
        min={1}
        defaultValue={1}
        setValue={setDownloads}
        value={downloads}
      />
      <Field
        text="Активные объявления"
        min={1000}
        max={downloads * 50000}
        defaultValue={1000}
        setValue={setAds}
        value={ads}
      />
      <Result text={result}>
        <Button disableElevation fullWidth variant="contained">Оплатить</Button>
      </Result>


    </FormStyle>
  );
};

export default Form;