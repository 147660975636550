import { FC } from "react";
import Instruction from "./Instruction";
import { InstructionEntity } from "./InstructionsPage";

interface InstructionsListProps {
  instructions: Array<InstructionEntity>;
}

const InstructionsList: FC<InstructionsListProps> = ({ instructions }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      {instructions.map((instruction) => (
        <Instruction key={instruction.id} instruction={instruction} />
      ))}
    </div>
  );
};

export default InstructionsList;
