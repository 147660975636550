import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, createStyles } from "@material-ui/core/styles";

interface Modal {
  open: boolean;
  handleClose: () => void;
  title: string;
  contentText?: string;
  cancelTextButtom?: string;
  submitTextButtom?: string;
  submitButtom:  any;
  children?: any;
  maxWidth?: string;
  name?: string;
  width?:string;
  otherDataProps?:any
  height?:string;
}
interface ModalPrivate {}

const Modal: React.FC<Modal> = ({
  open,
  handleClose,
  title,
  contentText,
  cancelTextButtom,
  submitTextButtom,
  submitButtom,
  maxWidth,
  width,
  otherDataProps,
  ...otherProps
}) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      paperWidthSm: {
        maxWidth: maxWidth,
        width,
      },
    })
  );
  const style = useStyles();
  if(otherDataProps){
    console.log('modal',otherDataProps?.id)
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={maxWidth ? style : {}}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {contentText && (
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        )}
        {otherProps.children}
      </DialogContent>
      <DialogActions>
       {cancelTextButtom && <span
          onClick={handleClose}
          color="primary"
          style={{ cursor: "pointer" }}
        >
          {cancelTextButtom}
        </span>}
        
        {submitTextButtom && 
        <span onClick={otherDataProps?submitButtom(otherDataProps) :submitButtom} color="red" style={{ cursor: "pointer" }}>
          {submitTextButtom}
        </span>}
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
