import React, { useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { useWindowSize, useWindowHeight } from "@react-hook/window-size";
import Alert from "@material-ui/lab/Alert";
import Sidebar from "../../Sidebar/Sidebar";
import Templates from "../../Page/Templates";
import HeaderStats from "../../Headers/HeaderStats";
import Modal from "../../../shared/ModalGlobal/Modal";
import { RootState } from "../../../redux/reducers/rootReducer";
import { TypeStatusInfo } from "../../../constant/constants";
import { setInfoStatus, getWidgets } from "../../../redux/actions/actions";
import "./style.css";
import InstructionsList from "../../Instructions/InstructionsList";
interface MenuProps {}

interface MenuPrivateProps extends MenuProps {
  modal: any;
  message: any;
  widgets: any;
}

const Menu: React.FC<MenuPrivateProps> = ({ modal, message, widgets }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(setInfoStatus(null));
    }, 6000);
  }, [message]);
  const [width, height] = useWindowSize();
  const ref: any = React.useRef();
  const [heightHeader, setHeightHeader] = React.useState(348);
  let el = document.getElementById("HeaderStats");

  React.useEffect(() => {
    el = ref.current;
    setHeightHeader(el ? el.offsetHeight : 348);
  }, [width]);

  useEffect(() => {
    if (!widgets) {
      dispatch(getWidgets.started(undefined));
    }
  }, []);

  return (
    <>
      {message ? (
        <Alert
          severity={
            message?.type === TypeStatusInfo.ERROR ? "error" : "success"
          }
          style={{
            position: "fixed",
            zIndex: 99999,
            margin: 5,
            width: "99%",
            display: "flex",
          }}
        >
          {message?.text}
        </Alert>
      ) : (
        ""
      )}
      <div style={{ display: "flex", flexDirection: "row" }}>
        {/* {modal && <Modal title={modal.title} setOpen={modal.setOpen} save={modal.save} Component={modal.component} tableDataFunc={modal} />} */}
        <Sidebar />
        <div
          className="relative md:ml-64 bg-blueGray-100"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            flexBasis: "auto",
            width: "100%",
          }}
        >
          <HeaderStats reff={ref} />
          <div
            className="px-4 md:px-10 mx-auto w-full -m-24 menu_template"
            style={{
              width: "100%",
              margin: 0,
              height:
                heightHeader < 544 ? `calc(100vh - ${heightHeader}px )` : "",
              // minWidth: 1000,
            }}
          >
            <Switch>
              <Route path="/admin/dashboard" exact component={Templates} />
              <Route path="/admin/settings" exact component={Templates} />
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

const mapStateToProps = (state: RootState) => {
  return {
    modal: state.Reducer.globalModal,
    message: state.Reducer.message,
    widgets: state.Reducer.widgets,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
