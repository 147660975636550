const JWT_ACCESS_TOKEN = "JWT_ACCESS_TOKEN";
const JWT_REFRESH_TOKEN = "JWT_REFRESH_TOKEN";
const JWT_ACCESS_EXPIRES = "JWT_ACCESS_EXPIRES";
const JWT_REFRESH_EXPIRES = "JWT_REFRESH_EXPIRES";

export function getAccessToken() {
  return localStorage.getItem(JWT_ACCESS_TOKEN);
}

export function getRefreshToken() {
  return localStorage.getItem(JWT_REFRESH_TOKEN);
}

export function setAccessToken(value: string, expires?: number) {
  localStorage.setItem(JWT_ACCESS_TOKEN, value);
  if (expires !== undefined) {
    localStorage.setItem(JWT_ACCESS_EXPIRES, `${Date.now() + expires}`);
  }
}

export function setRefreshToken(value: string, expires?: number) {
  localStorage.setItem(JWT_REFRESH_TOKEN, value);
  if (expires !== undefined) {
    localStorage.setItem(JWT_REFRESH_EXPIRES, `${Date.now() + expires}`);
  }
}

export function isLoggedIn() {
  const now = Date.now();
  const accessExpires = localStorage.getItem(JWT_ACCESS_EXPIRES);
  if (!accessExpires) {
    return false;
  }
  return !isRefreshTokenExpired() || now < +accessExpires;
}

export function isRefreshTokenExpired() {
  const now = Date.now();
  const refreshExpires = localStorage.getItem(JWT_REFRESH_EXPIRES);
  if (!refreshExpires) {
    return false;
  }
  return now > +refreshExpires;
}
