import { CircularProgress } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "recompose";
import { Dispatch } from "redux";
import api from "../../api";
import { RootState } from "../../redux/reducers/rootReducer";
import Sidebar from "../Sidebar/Sidebar";
import InstructionForm, { InstructionFormValue } from "./InstructionForm";
import InstructionsList from "./InstructionsList";

export interface InstructionEntity {
  id: string;
  name: string;
  text?: string;
  videoUrl?: string;
}

export interface InstructionPageProps {
  userEmail: string;
}

const InstructionsPage: FC<InstructionPageProps> = ({ userEmail }) => {
  const [instructions, setInstructions] = useState<InstructionEntity[]>([]);
  const [isInstructionLoading, setInstructionLoading] =
    useState<boolean>(false);
  const [isInstructionError, setInstructionError] = useState<Error | null>(
    null
  );
  const [adminEmails, setAdminEmails] = useState<string[]>([]);

  const createInstructionHandler = (form: InstructionFormValue) => {
    api.comments
      .createInstruction(form.name, form.text, form.videoUrl)
      .then((instruction) => {
        setInstructions((prevInstructions) => [
          ...prevInstructions,
          instruction,
        ]);
      })
      .catch(alert);
  };

  useEffect(() => {
    setInstructionLoading(true);
    api.comments
      .getInstructions()
      .then((backendInstructions) => {
        setInstructions(backendInstructions);
        setInstructionLoading(false);
      })
      .catch((error) => {
        setInstructionError(error);
      });
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Sidebar />
      <div
        className="relative md:ml-64 bg-blueGray-100"
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          flexBasis: "auto",
        }}
      >
        {adminEmails.includes(userEmail) && (
          <div style={{ width: "100%" }}>
            <InstructionForm onSendClick={createInstructionHandler} />
          </div>
        )}
        <div
          className="relative bg-white"
          style={{
            height: "100%",
            overflowY: "scroll",
            width: "80%",
            padding: "20px",
          }}
        >
          {isInstructionLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <InstructionsList instructions={instructions} />
          )}
        </div>
      </div>
    </div>
  );
};

//export default InstructionsPage;

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {};
};

const mapStateToProps = (state: RootState) => {
  return {
    userEmail: state.Reducer.user.email,
  };
};
export default compose<any, any>(connect(mapStateToProps, mapDispatchToProps))(
  InstructionsPage
);
