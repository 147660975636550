/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "./Navbars/IndexNavbar";
import Footer from "./Footers/Footer.js";

export default function Index() {
  return (
    <>
      <IndexNavbar />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-3xl">
                    АВТОМАТИЗАЦИЯ РАБОТЫ С ДОСКАМИ ОБЪЯВЛЕНИЙ
                  </h1>
                  <p className="mt-4 text-lg text-blueGray-200">
                    Наш сервис adPlacer помогает создавать большое количество
                    объявлений и размещать их в автоматическом режиме, сразу на
                    всех досках объявлений. Таких как: Avito, Юла,
                    Яндекс.Объявления. С нами Вы легко и быстро сможете
                    настроить массовый постинг!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-blueGray-200 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Единственный официальный метод
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Размещать любое количество объявления в автоматическом
                      режиме по расписанию. Сразу на всех самых популярных
                      досках объявлений на выбор или одновременно, Avito, Юла,
                      Яндекс.Объявления. Удобное и быстрое создание большего
                      количества уникальных объявлений, одного товара (с обходом
                      блокировок)
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Быстрое массовое редактирования
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      За 1 секунду Вы можете поменять любую информацию сразу во
                      всех объявлениях или выборочно у определенной модели
                      товара или услуги. Например цены, заголовки, описания.
                      Также вы можете заменить сразу все фотографии или
                      выборочно только первые фотографию у выбранного кластера
                      объявлений и многие другие возможности (максимально гибкое
                      массовое редактирование)
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Полная безопасность
                    </h6>
                    <p className="mt-2 mb-4 text-blueGray-500">
                      Вы НЕ предоставляете нам доступ к своим аккаунтам на
                      досках объявлений. Когда Вы настроите объявления в нашем
                      сервисе, у Вас появится ссылка. Эту ссылку Вы можете
                      использовать в Авито, Юле, Яндекс.Объявлениях, где хотите
                      размещать объявления. И объявления начнут размещаться
                      автоматически (по выбранному Вами расписанию)
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Работа с нами одно удовольствие
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-blueGray-600">
                  Первая версия adPlacer была разработана для собственного
                  товарного бизнеса. Далее сервис улучшался и к его помощи стали
                  прибегать агентства, занимающиеся продвижением на досках
                  объявлений. А теперь данный сервис доступен любому
                  пользователю, будь Вы предприниматель или специалист по
                  рекламе.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-blueGray-600">
                  С сервисом adPlacer подготовка объявлений займет в 10 раз
                  меньше времени. А размещение объявлений происходит
                  автоматически. По статистике у опытного пользователя в среднем
                  уходит 2 минуты на размещение одного объявления. Если
                  посчитать, то на размещение 2000 объявлений, уйдёт 4000 минут.
                  С adPlacer Вы экономите свое время или деньги, сэкономленные
                  на менеджерах! Не говоря о массовом редактировании, благодаря
                  которому моментально можно редактировать нужную информацию у
                  тысяч объявлений!
                </p>
                <Link to="/" className="font-bold text-blueGray-700 mt-8">
                  Тарифы и цены
                </Link>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      Будь в топе с нами
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      Мы тесно работаем с агентствами и товарным бизнесом, а
                      также прислушиваемся ко мнению наших уважаемых
                      пользователей. Что помогает нам постоянно улучшать наш
                      сервис adPlacer!
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-lightBlue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-lightBlue-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">Массовый постинг</h3>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Данный сервис максимально удобно использовать для создания
                    массового постинга. Вы можете создавать шаблон объявлений
                    одного товара или услуги, который размножится в тысячи
                    уникальных объявлений, обходя блокировки.
                  </p>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    Для этого в нем внедрены следующие функции:
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Рандомизация текста с динамической подстановкой
                            переменных
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Рандомизация адресов из выбранной области на карте,
                            заготовленных списков или вашего списка адресов
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Автоматическая массовая подстановка фотографий
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Удобное и гибкое формирование конечного пула
                            объявлений
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Массовое редактирование выбранного кластера
                            объявлений или точечное редактирование конкретных
                            объявлений
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Массовое внедрение новых объявлений товаров или
                            услуг в уже готовую действующую автозагрузку, без
                            потери структуры расписания
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Возможность редактирования старых объявлений,
                            созданных ранее вручную
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                            <i className="fas fa-award"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-blueGray-500">
                            Возможность добавления объявлений из старой
                            автозагрузки, если вы ранее использовали другие
                            сервисы автозагрузки
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-20 pb-48">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-8/12 px-4">
                <h2 className="text-4xl font-semibold">Основатель проекта</h2>
                <p className="text-lg leading-relaxed m-4 text-blueGray-500">
                  До того, как стать программистом, я делал много попыток начать
                  товарный бизнес, удачных и не очень. И всегда начинал с досок
                  объявлений. Это самый быстрый, дешевый и легкий способ
                  стартануть с новой нишей. Я как никто другой знаю все боли
                  ведения бизнеса на досках объявлений и в своем сервисе
                  adPlacer я учел все эти знания. При этом я не перестаю
                  улучшать данный сервис и постоянно внедряю новые возможности
                  исходя из пожеланий тех, кто им пользуется!
                </p>
              </div>
            </div>
            <div
              className="flex flex-wrap"
              style={{
                justifyContent: "center",
              }}
            >
              <div className="w-full md:w-6/12 lg:w-12/12 lg:mb-0 mb-12 px-4">
                <div className="px-6">
                  <img
                    alt="..."
                    src="./img/denis.jpg"
                    className="shadow-lg rounded-full mx-auto max-w-120-px"
                  />
                  <div className="pt-6 text-center">
                    <h5 className="text-xl font-bold">Денис Казимирский</h5>
                    <p className="mt-1 text-sm text-blueGray-400 uppercase font-semibold">
                      Основатель
                    </p>
                    <div className="mt-6">
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        style={{backgroundColor: 'white',
                        border: '1px solid gainsboro'}}
                        onClick={()=> window.open('https://youtube.com/channel/UCTgYPCWRlMziCNsESL_OgVA', '_blank')}
                      >
                        <i className="fab fa-youtube" style={{
                          color: 'red', 
                        }}></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={()=> window.open('https://t.me/joinchat/-Cgc0HJ3zI4zOGEy', '_blank')}
                      >
                        <i className="fab fa-telegram"></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={()=> window.open('https://instagram.com/deniska_zimirskiy', '_blank')}
                      >
                        <i className="fab fa-instagram"></i>
                      </button>
                      <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={()=> window.open('https://vk.com/deniska_zimirskiy', '_blank')}
                      >
                        <i className="fab fa-vk"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                        onClick={()=> window.open('https://www.facebook.com/denis.kaz.587', '_blank')}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>

                     
                      {/* <button
                        className="bg-lightBlue-400 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-twitter"></i>
                      </button>
                      <button
                        className="bg-lightBlue-600 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-facebook-f"></i>
                      </button>
                      <button
                        className="bg-pink-500 text-white w-8 h-8 rounded-full outline-none focus:outline-none mr-1 mb-1"
                        type="button"
                      >
                        <i className="fab fa-dribbble"></i>
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pb-20 relative block bg-blueGray-800" style={{ height: 500 }}>
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-800 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                  Бесплатно 7 дней 
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-400">
                  Испытай всю мощь сервиса adPlacer абсолютно бесплатно! Отсчет
                  бесплатного периода начнется с момента запуска автозагрузки.
                  Время потрачиное на подготовку автозагрузки не будет
                  учитываться.
                </p>
                <button
                  className="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-base px-8 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  style={{ padding: 10 }}
                >
                  ПОЛУЧИТЬ 7 ДНЕЙ БЕСПЛАТНО
                </button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
