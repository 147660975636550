import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRouter = ({ component, path, isAuthentication, to }) => {
  return isAuthentication ? <Route path={path} component={component} /> : <Redirect to={'/'}/>;
};

const mapStateToProps =(state)=>{
    return {
        isAuthentication:state?.Reducer?.user?.token,
    }
}

const mapDispatchToProps=(dispatch)=>{
    return {
        // updateAuthentication:()=>dispatch(checkToken)
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PrivateRouter);
