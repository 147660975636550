import { Button, CircularProgress, Input } from "@mui/material";
import { FC, useEffect, useState } from "react";
import api from "../../api";
import {
  StyledXmlTable,
  StyledXmlTableCell,
  StyledXmlTableRow,
} from "./XmlTable.styled";
import _ from "lodash";

interface XmlTableProps {
  xmlId: string;
}

const XmlTable: FC<XmlTableProps> = (props: XmlTableProps) => {
  const { xmlId } = props;

  const [page, setPage] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [searchText, setSearchText] = useState("");
  const [isLoading, setLoading] = useState(true);

  const [data, setData] = useState<any>([]);

  const searchHandler = () => {
    getData(0);
    setPage(0);
  };

  const getData = (page: number) => {

    setLoading(true);
    api.comments
      .selectXml({
        xmlId,
        page,
        searchText,
      })
      .then((res) => {
        const { data: xml, status } = res;
        if (status !== 200) {
          return;
        }
        setTotal(Math.ceil(xml.total / xml.limit));
        setData(xml.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!xmlId) return;
    getData(page);
  }, [xmlId]);
  const keySearchHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchHandler();
    }
  }

  return (
    <div>
      <div>
        <label>Поиск</label>
        <input
          id="nameTemplate"
          name="Title"
          onKeyDown={keySearchHandler}
          value={searchText}
          onChange={(event) => setSearchText(event.target.value)}
          type="text"
          style={{ border: "1px solid #ccc", width: "25%", marginLeft: "10px" }}
          className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        <Button onClick={searchHandler}> Искать</Button>
      </div>
      <div style={{ marginTop: "10px" }}>
        <span>
          Страница{" "}
          <strong>
            {page + 1} из {total}
          </strong>{" "}
        </span>
        <span>
          | Перейти к:{" "}
          <button
            onClick={() => {
              setPage(page - 1);
              getData(page - 1);
            }}
            disabled={page + 1 == 1}
          >
            {"<"}
          </button>
          <input
            type="number"
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              setPage(page);
              getData(page);
            }}
            style={{
              width: "100px",
              borderRadius: "1rem",
              display: "inline-block",
            }}
            minLength={0}
            maxLength={total}
            value={page + 1}
          />
          <button
            onClick={() => {
              setPage(page + 1);
              getData(page + 1);
            }}
            disabled={page + 1 == total}
          >
            {">"}
          </button>
        </span>
      </div>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "600px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <StyledXmlTable>
            <StyledXmlTableRow style={{ maxHeight: "50px" }}>
              {data &&
                Object.keys(data[0]).map((key) => {
                  // console.log(_.isEmpty(data[0]));
                  return <StyledXmlTableCell key={key}>{key}</StyledXmlTableCell>
                })}
            </StyledXmlTableRow>
            {
              data && data.map((item, index) => {
                return (
                    <StyledXmlTableRow key={index}>
                    {Object.keys(item).map((key) => (
                      <StyledXmlTableCell key={key}>
                        {item[key][0]._text || item[key][0]._cdata}
                      </StyledXmlTableCell>
                  ))}
                    </StyledXmlTableRow>
                )
                })
            }
          </StyledXmlTable>
        </div>
      )}
    </div>
  );
};

export default XmlTable;
