import React, { ReactElement } from "react";
import Tooltip from "@material-ui/core/Tooltip";

interface CustomTooltip {
  title: string;
  children: ReactElement<any, any>;
  interactive: boolean;
  disabled?: boolean;
}

const CustomTooltip: React.FC<CustomTooltip> = ({
  title,
  interactive,
  children,
  disabled=false,
}) => {
  return (
    <>
    {disabled?
    <Tooltip interactive={interactive} title={title} >
      {children}
    </Tooltip>:children}
    </>
  );
  
};

export default CustomTooltip;
