import React, { Fragment } from 'react';
export enum Form {
  WomensShoes = 'WomensShoes',
  Local = 'Local',
  Header = 'Header',
  Login = 'Login',
  CreateXml = 'CreateXml',
  ManageInfo = 'ManageInfo',
  CustomComponents = 'customComponents',
}

export enum Loaders {
  fetchPhoto = 'fetchPhoto',
  fetchLoginFalse = 'error',
  fetchLoginTrue = 'true',
  fetchTemplate = 'fetchTemplate',
  deleteXml = 'deleteXml',
  error = 'error',
  shared = 'shared',
  errorShared = 'shared',
  fetchTemplateError = 'fetchTemplateError',
  fetchCreateXml = 'fetchCreateXml',
  fetchCreateXmlError = 'fetchCreateXmlError',
  sendMessage = 'sendMessage',
  refresh = 'refresh',
  falseRefresh = 'falseRefresh',
  createTemplateData = 'createTemplateData',
  createTemplateGroup = 'createTemplateGroup',
  updateTemplateGroup = 'updateTemplateGroup',
  deleteTemplateGroup = 'deleteTemplateGroup',
  getStatisticsById = 'getStatisticsById',
}

export const LocalSelect = {
  1: ['m1', 'm2', 'm3'],
  2: ['p1', 'p2', 'p3'],
};

export interface Loggin {
  email: string;
  token: string;
  userId: number;
  password: string;

  status: string;
  data: {
    accessToken: {
      token: string;
      expiresIn: number;
    };
    refreshToken: {
      token: string;
      expiresIn: number;
    };
  };
}

export const cloudPrice = 5;
export const dayTarif = 30;

export const urlXml = 'http://adplacer.ru:8080';
// export const url = "https://adplacer.ru:3000";

export const url = 'https://api.adplacer.ru/api';
// export const url = `https://b34b-37-212-24-126.ngrok-free.app/api`;

//export const url = "http://10.211.49.80:3000";
//export const url = "http://localhost:8080";

export const urlSocket = 'https://api.adplacer.ru';
// export const urlSocket = 'https://b34b-37-212-24-126.ngrok-free.app';
// 
// export const urlSocket = "https://localhost:8081";
//export const urlLocal = 'http://localhost:3001'
export const urlLocal = 'http://adplacer.ru';
//export const url = "http://localhost:8080";

export const urlFe = 'http://localhost:3001';

export enum TypeStatusInfo {
  ERROR = 'error',
  DONE = 'done',
}

export const GB = 1000000;

const title = <h1></h1>;

export const Tooltips = {
  replay:
    'Вы можете сразу оплатить тариф с настройками тарифа за предыдущий период. Либо включать автозагрузки по одной, из тех, которые Вам необходимы на данный момент.',
  title: (
    <div>
      <span>
        {' '}
        Введите заголовок, который будет использован во всех объявлениях.
        <br></br>
      </span>
      <h1>- Данное поле поддерживает функцию рандомизации</h1>
      <br></br>
      <h1>
        Функция рандомизации работает следующим образом:<br></br> Когда Вы пишем
        варианты текста в фигурных скобках через вертикальный<br></br> слэш, то
        в итоговый текст входит только один из вариантов.
      </h1>
      <br></br> Пример:<br></br>
      <h1>
        {`{Петя | Саша | Паша} `}очень хотел {`{сходить | пойти}`} в{' '}
        {`{магазин | лес | гости}`}
        <br></br> сегодня!
      </h1>
      <br></br> Данный текст имеет 18 различных вариантов комбинаций после
      рандомизации<br></br> и вот несколько из них для примера:
      <br></br> Саша очень хотел сходить в гости сегодня!<br></br> Петя очень
      хотел пойти в лес сегодня!<br></br>
      Паша очень хотел сходить в магазин сегодня!<br></br>
    </div>
  ),
  description: (
    <span>
      <h1>
        Введите заголовок, который будет использован во всех объявлениях.
        <br></br>- Данное поле поддерживает функцию подстановки слов переменных
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция подстановки слов переменных работает следующим образом:<br></br>
        Если в тексте объявления Вы напишите специальное слово, то вместо него
        <br></br> встанет одно из значений, которое меняется в разных
        объявлениях.
      </h1>
      <br></br>

      <h1>
        Есть фиксированные список слов переменных:<br></br>
        Title – заголовок объявления
      </h1>
      <br></br>

      <h1>
        Пример: Если в поле «Заголовок» написано «
        {`{Компьютерный стол (сосна)|Компьютерный стол (дуб)}`}», тогда если вы
        напишите в данное поле «Title со скидкой 30%<br></br>, бесплатная
        доставка…», то итоговый текст объявления будет иметь два<br></br>{' '}
        варианта «Компьютерный стол (сосна) со скидкой 30%, бесплатная
        доставка…»<br></br>, в том случае, когда в объявлении после рандомизации
        заголовка был выбран <br></br>вариант «Компьютерный стол (сосна)». И
        второй вариант, «Компьютерный стол <br></br>(дуб) со скидкой 30%,
        бесплатная доставка…», когда после рандомизации<br></br> заголовка был
        выбран вариант «Компьютерный стол (дуб)».
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит один из вариантов
      </h1>
      <br></br>

      <h1>
        Пример:<br></br>
        {`{Петя|Саша|Паша}`} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!<br></br>
        Данный текст имеет 18 различных вариантов после рандомизации и вот
        <br></br> несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  photo: (
    <span>
      <h1>Подготовьте фотографии в папках по следующей структуре:</h1>
      <br></br>

      <h1>1) Создайте пустую папку с любым названием;</h1>
      <br></br>

      <h1>
        2) Далее в этой папке создайте такое количество папок, сколько вы хотите
        <br></br> иметь фотографий в каждом отдельном объявлении;
      </h1>
      <br></br>

      <h1>
        3) Назовите эти папки цифрами, где цифра — это позиция фотографии в
        <br></br> каждом отдельном объявлении;
      </h1>
      <br></br>

      <h1>
        4) После этого заполните эти папки фотографиями. Какое количество{' '}
        <br></br>фотографий Вы добавите в этим папки, такое количество
        объявлений и будет<br></br> создано;<br></br>* При этом если в папках
        разное количество<br></br> фотографий, то количество созданных
        объявлений будет равняться количеству фотографий в той папке,<br></br>{' '}
        где меньше всего фотографий.
      </h1>
      <br></br>

      <h1>
        5) Подготовленную папку с фотографиями добавить в ZIP архив. Для
        <br></br> добавления папки в ZIP архив:<br></br>- На Windows, нажимаете
        правой кнопкой по папке, далее из выплывающего списка наводите на слово
        «Отправить» и далее из второго выплывающего <br></br>списка выбираете
        пункт «Сжатая ZIP-папка».<br></br>- На Mac OS, нажмите правой кнопкой по
        папке, далее выберите пункт “Сжать<br></br> «Название вашей папки»”.
      </h1>
      <br></br>
    </span>
  ),

  adress: (
    <span>
      <h1>
        Вы можете выбрать один из трех вариантов установки адресов для
        объявлений:
      </h1>
      <br></br>

      <h1>
        1. В поле ввода адресов ввести адрес, который будет установлен для всех{' '}
        <br></br>объявлений. Либо список адресов в формате{' '}
        {'{Адрес 1|Адрес 2|Адрес 3}'}, тогда<br></br> адреса установятся по
        очереди для каждого объявления. Причем адреса<br></br> должны быть
        написаны в том виде, как их принимает доска объявлений, на которой вы
        размещаетесь (для этого откройте создание объявления на доске <br></br>
        объявлений и начните вписывать Ваш адрес, далее выберите нужный Вам
        адрес<br></br> из выпадающего списка адресов, его и нужно будет вписать
        в данное поле);
      </h1>
      <br></br>

      <h1>
        2. Выбрать из списка готовых адресов вариант, который подходит именно
        Вам;
      </h1>
      <br></br>

      <h1>
        3. Выделяя области на карте: - Если выделите одну область на карте,
        тогда для каждого объявления будет установлен свой случайны адрес из
        выделенной области. - Если выделите несколько областей на карте. Тогда
        по очереди из каждой области на карте будет случайным образом взят один
        адрес и установлен к одному объявлению, и так пока все объявления не
        получат свой адрес.
      </h1>
      <br></br>
    </span>
  ),
  phone: (
    <span>
      <h1>
        Введите телефон (в формате 89010030202), который будет использован во
        всех<br></br> объявлениях.<br></br>- Данное поле поддерживает функцию
        рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный{' '}
        <br></br>слэш, то в итоговый текст входит только один из вариантов.
      </h1>
      <br></br>

      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),
  nameManage: (
    <span>
      <h1>
        Введите имя менеджера, которое будет использовано во всех объявлениях.
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный{' '}
        <br></br>слэш, то в итоговый текст входит только один из вариантов.
      </h1>
      <br></br>

      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'} сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера: Саша очень хотел сходить в
        гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),
  video: (
    <span>
      <h1>
        Вставьте ссылку на видео из YouTube, которое будет использовано во всех
        <br></br> объявлениях.<br></br>- Данное поле поддерживает функцию
        рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный{' '}
        <br></br>слэш, то в итоговый текст входит только один из вариантов.
      </h1>
      <br></br>

      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'} <br></br>сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),
  price: (
    <span>
      <h1>
        Введите цену, которая будет использована во всех объявлениях.<br></br>-
        Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
      </h1>
      <br></br>

      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел{' {сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'} <br></br>сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),
  size: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {'"< 35", "36", "37", "38", "39", "40", "> 41"'}
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),
  sizeManShoes: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {'"< 40", "41", "42", "43", "44", "45", "> 46"'}
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  sizeManClothes: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {
          '"42–44 (XS)", "44–46 (S)", "46–48 (M)", "48–50 (L)", "50–52 (XL)", "52–54 (XXL)", "> 54 (XXXL)", "Без размера"'
        }
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  sizeManJeans: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {
          '"29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "> 38", "Без размера"'
        }
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  sizeWomenClothes: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {
          '"40–42 (XS)", "42–44 (S)", "44–46 (M)", "46–48 (L)", "48–50 (XL)", "> 50 (XXL)", "Без размера"'
        }
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  sizeWomenJeans: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {
          '"25", "26", "27", "28", "29", "30", "31", "32", "33", "> 34", "Без размера"'
        }
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  sizeChildrenClothes: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {
          '"50-56 cм", "62-68 см", "74-80 см", "86-92 см", "98-104 см", "110-116 см", "122-128 см", "134-140 см", "146-152 см", "158-164 см", "Без размера"'
        }
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  sizeChildrenShoes: (
    <span>
      <h1>
        Введите размеры, которые будут использованы во всех объявлениях.
        <br></br> Допустимые размеры данной категории товара, строго только
        значения из двойных ковычек (без самих ковычек): <br></br>
        {
          '"< 19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "> 36", "Без размера"'
        }
        <br></br>- Данное поле поддерживает функцию рандомизации
      </h1>
      <br></br>

      <h1>
        Функция рандомизации работает следующим образом:<br></br>
        Когда Вы пишем варианты текста в фигурных скобках через вертикальный
        <br></br> слэш, то в итоговый текст входит только один из вариантов.
        <br></br>
      </h1>
      <br></br>
      <h1>
        Пример:<br></br>
        {'{Петя|Саша|Паша}'} очень хотел {'{сходить|пойти}'} в{' '}
        {'{магазин|лес|гости}'}
        <br></br> сегодня!
      </h1>
      <br></br>

      <h1>
        Данный текст имеет 18 различных вариантов комбинаций после рандомизации
        <br></br> и вот несколько из них для примера:<br></br>
        Саша очень хотел сходить в гости сегодня!<br></br>
        Петя очень хотел пойти в лес сегодня!<br></br>
        Паша очень хотел сходить в магазин сегодня!
      </h1>
      <br></br>
    </span>
  ),

  select: `Если выберите "Да", тогда можно будет и писать и звонить. Если выберите "Нет", то можно будет только звонить по данным объявлениям.`,
};

export enum TypeField {
  Input = 'input',
  Select = 'select',
  Photo = 'photo',
  Locale = 'locale',
  Textarea = 'textarea',
  Selectbrand = 'selectBrand',
  SelectModel = 'selectModel',
  SelectGeneration = 'selectGeneration',
  SelectModification = 'selectModification',
  SelectComplectation = 'selectComplectation',
  SelectFuelType = 'selectFuelType',
  SelectTransmission = 'selectTransmission',
  SelectYear = 'selectYear',
  SelectDoors = 'selectDoors',
  SelectBodyType = 'selectBodyType',
  SelectDriveType = 'selectDriveType',
  SelectPower = 'selectPower',
}

export interface FieldInterface {
  initialValue?: string;
  value?: string[];
  name: string;
  nameField?: string;
  title: string;
  type: TypeField;
  fullSize?: boolean;
  fieldName: string;
  isGeneric: boolean;
  isStep: boolean;
  second?: boolean;
}

export const MONTH = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Декабрь',
];
