import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import {
  setLoader,
  getPhoto,
  logging,
  getTemplate,
  deleteTemplate,
  getCategory,
  fetchCreateTemplate,
  uploadFile,
  createXml,
  getXmlList,
  deleteXml,
  sharedXml,
  register,
  setInfoStatus,
  getWidgets,
  setStatus,
  getTemplateById,
  getActiveXml,
  buy,
  buyCloud,
  sendKey,
  refreshPassword,
  createTemplateData,
  createTemplateGroup,
  getTemplateCreate,
  createDataXml,
  updateTemplateData,
  paginateTable,
  paginateTableGet, updateTemplateGroup, deleteTemplateGroup, getStatisticsById,
} from "./actions/actions";
import api from "../api";
import {
  DirYa,
  Templates,
  Categories,
  Widgets,
} from "../api/comments/api.types";
import {
  Loaders,
  Loggin,
  url,
  TypeStatusInfo,
  GB,
  urlLocal,
} from "../constant/constants";
import { setCookie } from "../helpers/cookies";
import { awsS3Drive } from "./s3Drive";
import { setAccessToken, setRefreshToken } from "../api/auth/tokens";
// import { socketEmitter } from "../api/socket";

export function* getPhotoSaga({
  payload,
}: ReturnType<typeof getPhoto.started>) {
  try {
    yield put(setLoader(Loaders.fetchPhoto));
    const dir: DirYa = yield call(api.comments.getPhoto, payload);

    let namesDir = dir._embedded.items.map((item) => {
      return { type: item.type, path: item.path, name: item.name };
    });
    namesDir = namesDir
      .filter((item: any) => item.type === "dir")
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    type photo = string[];
    let files: photo[] = [];
    for (const item of namesDir) {
      const dir: DirYa = yield call(
        api.comments.getPhotoFile,
        payload,
        item.path
      );
      const count = dir._embedded.total;
      const mass: any = [];
      for (let i = 0; i < count; i += 1000) {
        const result: DirYa = yield call(
          api.comments.getPhotoFile,
          payload,
          item.path,
          i,
          1000
        );

        mass.push(...result._embedded.items);
      }
      let names: any = mass
        .filter((itemPhoto: any) => itemPhoto.type === "file")
        .map((item: any) => ({
          type: item.type,
          path: item.path,
          name: item.name,
          file: item.file,
        }));

      files.push(names);
    }
    yield put(getPhoto.done({ params: payload, result: files }));
  } catch (error) {
  } finally {
    yield put(setLoader(""));
  }
}

function* sagaCreateTemplate({
  payload,
}: ReturnType<typeof fetchCreateTemplate.started>) {
  try {
    yield put(setLoader(Loaders.fetchCreateXml));

    const categories: any[] = yield call(
      api.comments.paginateTable,
      payload.id,
      payload?.page || 0,
      payload.params
    );
    const createTemplate: string = yield call(
      api.comments.createTemplate,
      payload
    );

    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "Шаблон успешно создан",
      })
    );
    if (Number(payload.isActive) !== 1) {
      yield put(
        getTemplate.started({
          userId: payload.userId.toString(),
          isActive: false,
          all: false,
        })
      );
    }
    yield put(setStatus("finishSave"));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка создания шаблона, проверьте интернет",
      })
    );
    yield put(setLoader(Loaders.fetchCreateXmlError));
  } finally {
  }
}

function* sagaCreateXml({ payload }: ReturnType<typeof createXml.started>) {
  try {
    yield put(setLoader(Loaders.fetchCreateXml));
    const createXml: any = yield call(api.comments.createXml, payload);

    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "Автозагрузка загружается, пожалуйста подождите",
      })
    );
    yield put(setLoader("done"));
    yield put(getWidgets.started(undefined));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "Автозагрузка не создана, проверьте интернет",
      })
    );
  }
}

function* sagaCreateTemplateGroup({payload,}: ReturnType<typeof createTemplateGroup.started>) {
  try {
    yield put(setLoader(Loaders.createTemplateGroup));
    const createTemplateGroup = yield call(api.comments.createTemplateGroup, payload);
    yield put(createTemplateGroup.done({ params: payload, result: createTemplateGroup }));
    yield put(setLoader("done"));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "проверьте интернет",
      })
    );
  }
}

function* sagaUpdateTemplateGroup({payload,}: ReturnType<typeof updateTemplateGroup.started>) {
  try {
    yield put(setLoader(Loaders.updateTemplateGroup));
    const updateTemplateGroup = yield call(api.comments.updateTemplateGroup, payload)
    yield put(updateTemplateGroup.done({ params: payload, result: updateTemplateGroup }));
    yield put(setLoader("done"));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "проверьте интернет",
      })
    );
  }
}

function* sageGetStatisticsById({payload,}: ReturnType<typeof getStatisticsById.started>) {
  try {
    yield put(setLoader(Loaders.getStatisticsById));
    const statistics: any = yield call(api.comments.getStatisticsById, payload)
    yield put(getStatisticsById.done({ params: payload, result: statistics }));
    yield put(setLoader("done"));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "проверьте интернет",
      })
    );
  }
}

function* sagaDeleteTemplateGroup({payload,}: ReturnType<typeof deleteTemplateGroup.started>) {
  try {
    yield put(setLoader(Loaders.deleteTemplateGroup));
    const deleteTemplateGroup = yield call(api.comments.deleteTemplateGroup, payload)
    yield put(deleteTemplateGroup.done({ params: payload, result: deleteTemplateGroup }));
    yield put(setLoader("done"));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "проверьте интернет",
      })
    );
  }
}

function* sagaCreateTemplateData({
  payload,
}: ReturnType<typeof createTemplateData.started>) {
  try {
    yield put(setLoader(Loaders.createTemplateData));
    const createXml: string = yield call(
      api.comments.createTemplateData,
      payload
    );

    yield put(createTemplateData.done({ params: payload, result: createXml }));
    yield put(setLoader("done"));
    // yield put(getWidgets.started(undefined));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "проверьте интернет",
      })
    );
  }
}

function* sagaUpdateTemplateData({
  payload,
}: ReturnType<typeof updateTemplateData.started>) {
  try {
    yield put(setLoader(Loaders.createTemplateData));
    const createXml: string = yield call(
      api.comments.updateTemplateData,
      payload
    );

    yield put(createTemplateData.done({ params: payload, result: createXml }));
    yield put(setLoader("done"));
    // yield put(getWidgets.started(undefined));
  } catch (e) {
    // console.log(e);
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "проверьте интернет",
      })
    );
  }
}

function* sagaItemsCategory({
  payload,
}: ReturnType<typeof getCategory.started>) {
  try {
    const categories: Categories[] = yield call(
      api.comments.getCategories,
      payload.id
    );

    yield put(getCategory.done({ params: payload, result: categories }));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка получения данных сервиса, проверьте интернет",
      })
    );
  }
}

function* sagaNextPageTable({
  payload,
}: ReturnType<typeof paginateTableGet.started>) {
  try {
    yield put(paginateTableGet.done({ params: payload, result: [] }));
    const page: any = yield call(
      api.comments.paginateTableGet,
      payload.id,
      payload.page
    );

    yield put(
      paginateTableGet.done({
        params: payload,
        result: page?.data?.response?.data?.updateTemplate,
      })
    );
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка получения данных сервиса, проверьте интернет",
      })
    );
  }
}

function* sagapaginateTable({
  payload,
}: ReturnType<typeof paginateTable.started>) {
  try {
    yield put(paginateTableGet.done({ params: payload, result: [] }));
    const categories: any[] = yield call(
      api.comments.paginateTable,
      payload.id,
      payload.page,
      payload.updateTemplate
    );

    const page: any = yield call(
      api.comments.paginateTableGet,
      payload.id,
      Number(payload.newPage)
    );

    yield put(
      paginateTableGet.done({
        params: payload,
        result: page?.data?.response?.data?.updateTemplate,
      })
    );

    // yield put(paginateTable.done({ params: payload, result: categories }));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка получения данных сервиса, проверьте интернет",
      })
    );
  }
}

function* sagaBuy({ payload }: ReturnType<typeof buy.started>) {
  try {
    const buy: boolean = yield call(api.comments.buy, payload);
    yield put(getWidgets.started(undefined));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка получения данных сервиса, проверьте интернет",
      })
    );
  }
}

function* sagaGetActiveXml({
  payload,
}: ReturnType<typeof getActiveXml.started>) {
  try {
    const activeXml: Categories[] = yield call(api.comments.getActiveXml);
    yield put(getActiveXml.done({ params: payload, result: activeXml }));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка получения данных сервиса, проверьте интернет",
      })
    );
  }
}

function* sagaGetTemplate({ payload }: ReturnType<typeof getTemplate.started>) {
  try {
    yield put(getTemplate.done({ params: payload, result: [] }));
    yield put(setLoader(Loaders.fetchTemplate));
    const result = yield call(
      api.comments.getTemplate,
      payload.userId,
      payload.isActive,
      payload.all,
      payload.idXml,
      payload.page,
      payload.limit,
      payload.isPhotos
    );

    yield put(getTemplate.done({ params: payload, result: result.data }));
    yield put(setLoader(""));
    yield put(getWidgets.started(undefined));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка загрузки шаблонов проверьте сеть",
      })
    );
    yield put(setLoader(Loaders.fetchTemplateError));
  }
}

function* sagaGetTemplateCreate({
  payload,
}: ReturnType<typeof getTemplateCreate.started>) {
  try {
    // yield put(getTemplate.done({ params: payload, result: [] }));
    yield put(setLoader(Loaders.fetchTemplate));
    const template: Templates[] = yield call(api.comments.getTemplateCreate);
    yield put(getTemplateCreate.done({ params: payload, result: template }));
    yield put(setLoader(""));
    yield put(getWidgets.started(undefined));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка загрузки шаблонов проверьте сеть",
      })
    );
    yield put(setLoader(Loaders.fetchTemplateError));
  }
}
function* sagaCreateDataXml({
  payload,
}: ReturnType<typeof createDataXml.started>) {
  try {
    // yield put(getTemplate.done({ params: payload, result: [] }));
    yield put(setLoader(Loaders.fetchTemplate));
    const template: Templates[] = yield call(api.comments.getTemplateCreate);
    yield put(getTemplateCreate.done({ params: payload, result: template }));
    yield put(setLoader(""));
    yield put(getWidgets.started(undefined));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Ошибка загрузки шаблонов проверьте сеть",
      })
    );
    yield put(setLoader(Loaders.fetchTemplateError));
  }
}

function* sagaUploadPhoto({ payload }: ReturnType<typeof uploadFile.started>) {
  let valid;
  try {
    yield put(uploadFile.done({ params: payload, result: [] }));
    const url1 =
      "https://drive.google.com/drive/folders/1jHGbP12_2mczn0QswHkaMtofO0BDFqd-";
    //  const userCloud: number = yield call(
    //       api.comments.getUserCloud,
    //       payload.userId
    //     );
    //     // const fileSizeGb = Array.prototype.slice.call(payload?.file)?.reduce((acc:number, item:any)=>{
    //     //   acc+=item?.File?.size ||0;
    //     //   return acc
    //     // },0)
    //     const newFile = [...payload?.file].filter(
    //       (i) => i.type == "image/png" || i.type == "image/jpeg"
    //     );

    //     valid = newFile.reduce((acc: boolean, item: any) => {
    //       const fold = item?.webkitRelativePath.split("/");
    //       const valid = [".jpg", "jpeg", ".png"];
    //       // ''.indexOf
    //       const ext = fold[2].slice(fold[2].length - 4);
    //       const sizeValid = item?.size / 1000000 < 29;
    //       if (fold.length === 3 && valid.find((i) => i == ext) && sizeValid) {
    //         return acc;
    //       } else {
    //         acc = false;
    //         return acc;
    //       }
    //     }, true);
    //     if (!valid) {
    //       throw new Error("!!!!!!!!");
    //       return;
    //     }
    //     let fileSizeGb = 0;
    //     for (let i = 0; i < newFile?.length; i++) {
    //       fileSizeGb += newFile[i]?.size || 0;
    //     }
    //     fileSizeGb = Number((fileSizeGb / GB).toFixed(2));
    yield put(setStatus("loading"));
    const photos = yield call(
      api.comments.uploadPhoto,
      payload?.urlLink,
      payload.userId,
      payload.idCategory || -1,
      payload.id,
      payload.onUploadProgress
    );
    console.log(" const photos:", photos);
    //.file.size / GB;
    // if (fileSizeGb < Number(userCloud)) {
    //   const photos: any[] = yield call(
    //     api.comments.uploadPhoto,
    //     newFile.sort((a, b) => {
    //       if (a.webkitRelativePath < b.webkitRelativePath) return -1;
    //       if (a.webkitRelativePath > b.webkitRelativePath) return 1;

    //       return 0;
    //     }),
    //     payload.userId,
    //     payload.idCategory || -1,
    //     payload.id,
    //     payload.onUploadProgress
    //   );
    yield put(uploadFile.done({ params: payload, result: photos }));
    yield put(setStatus("finish"));
  } catch (e) {
    if (!valid) {
      yield put(
        setInfoStatus({
          type: TypeStatusInfo.ERROR,
          text: "Неверный формат медиа файлов",
        })
      );
    } else {
      console.log(e);
      yield put(
        setInfoStatus({
          type: TypeStatusInfo.ERROR,
          text: "Ошибка загрузчки файла",
        })
      );
    }
    yield put(setStatus("error"));
    yield put(setLoader(Loaders.fetchTemplateError));
  }
}

function* sagaDeleteTemplate({
  payload,
}: ReturnType<typeof deleteTemplate.started>) {
  try {
    yield put(setLoader(Loaders.fetchTemplate));
    const template: Templates[] = yield call(
      api.comments.deleteTemplate,
      payload.id
    );

    yield put(
      getTemplate.started({
        userId: payload.userId,
        isActive: false,
        all: false,
      })
    );
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "Шаблон успешно удален",
      })
    );
  } catch (e) {
    yield put(setLoader(Loaders.fetchTemplateError));
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Шаблон не удален, проверьте интернет",
      })
    );
  }
}

function* sageLogging({ payload }: ReturnType<typeof logging.started>) {
  try {
    // console.log(payload.email, payload.password);

    // if (payload.password.length < 8){
    //   console.log("ERROR");
    //   throw "error123";
    // }

    const loging: Loggin = yield call(
      api.comments.logging,
      payload.email,
      payload.password
    );

    console.log(loging);

    setAccessToken(
      loging.data.accessToken.token,
      loging.data.accessToken.expiresIn
    );
    setRefreshToken(
      loging.data.refreshToken.token,
      loging.data.refreshToken.expiresIn
    );

    // socketEmitter.connect();

    // yield setCookie(
    //   `${
    //     loging?.data?.accessToken.token || loging.token
    //   }; path=${urlLocal}; max-age=${
    //     loging?.data?.accessToken?.expiresIn || "604800"
    //   }`,
    //   "authorization"
    // );
    //localStorage.setItem("authorization", loging.data.accessToken.token);
    // localStorage.setItem("email", loging.data.email);
    // localStorage.setItem("userId", loging.userId + "");
    // yield setCookie(`${loging.email}; path=${url}; max-age=604800`, "email");
    // yield setCookie(`${loging.userId}; path=${url}; max-age=604800`, "userId");

    yield put(logging.done({ params: payload, result: loging }));
    yield put(setLoader(Loaders.fetchLoginTrue));
  } catch (e: any) {
    yield put(setLoader(Loaders.fetchLoginFalse));
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}
function* sageRegister({ payload }: ReturnType<typeof register.started>) {
  try {
    const loging: Loggin = yield call(api.comments.register, payload);
    // if (loging?.email) {
    //   yield put(
    //     logging.started({ email: loging.email, password: payload.password })
    //   );
    // }
  } catch (e: any) {
    
    yield put(setLoader(Loaders.fetchLoginFalse));
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* sagaRefresh({ payload }: ReturnType<typeof refreshPassword.started>) {
  try {
    const send: string = yield call(api.comments.refreshPassword, payload);
    yield put(setLoader(Loaders.refresh));
    // window.location.assign("https://adplacer.ru/admin/dashboard");
    window.location.assign("localhost:3001/admin/dashboard");
  } catch (e: any) { 
    yield put(setLoader(Loaders.falseRefresh));
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* sagaSendEmail({ payload }: ReturnType<typeof sendKey.started>) {
  try {
    const send: string = yield call(api.comments.sendKey, payload.email);
    yield put(setLoader(Loaders.sendMessage));
  } catch (e: any) {
    yield put(setLoader(Loaders.fetchLoginFalse));
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* sageGetTemplateById({
  payload,
}: ReturnType<typeof getTemplateById.started>) {
  try {
    
    const result: any[] = yield call(api.comments.getTemplateById, payload);
  
    
    yield put(getTemplateById.done({ result, params: payload }));
  } catch (e: any) {
    yield put({ type: "USER_FETCH_FAILED", message: e.message });
  }
}

function* sagaGetXml({ payload }: ReturnType<typeof getXmlList.started>) {
  try {
    yield put(setLoader(Loaders.fetchTemplate));
    const xml: any = yield call(
      api.comments.getxml,
      payload.userId,
      payload.page,
      payload.limit
    );
    yield put(getXmlList.done({ params: payload, result: xml.data }));
    yield put(setLoader(""));
    yield put(getWidgets.started(undefined));
  } catch (e: any) {
    yield put(setLoader(Loaders.fetchTemplateError));
  }
}

function* sagaDeleteXml({ payload }: ReturnType<typeof deleteXml.started>) {
  try {
    yield put(setLoader(Loaders.deleteXml + payload.xmlId));
    const xml: any[] = yield call(
      api.comments.deleteXml,
      payload.userId,
      payload.xmlId
    );
    yield put(getXmlList.started({ userId: payload.userId }));
    yield put(setLoader(""));
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.DONE,
        text: "Xml авто загрузка успешно удалена",
      })
    );
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Xml авто загрузка не удаленапроверьте ваше подключение или обратитесь в справочную службу",
      })
    );
    yield put(setLoader(Loaders.error));
  }
}

function* sagaSharedXml({ payload }: ReturnType<typeof sharedXml.started>) {
  try {
    yield put(setLoader(Loaders.shared + payload.id));
    const xml: any = yield call(api.comments.sharedXml, payload);
    yield put(setLoader(""));
    if (xml.text !== "error") {
      yield put(
        setInfoStatus({
          type: TypeStatusInfo.DONE,
          text: payload.isAvito
            ? "Автозагрузка опубликована"
            : "Автозагрузка снята с публикации",
        })
      );
    } else {
      yield put(
        setInfoStatus({
          type: TypeStatusInfo.ERROR,
          text: payload.isAvito
            ? "Автозагрузку не удалось опубликовать"
            : "Автозагрузку не удалось снять с публикации",
        })
      );
    }
    yield put(getXmlList.started({ userId: payload.userId + "" }));
  } catch (e) {
    yield put(
      setInfoStatus({
        type: TypeStatusInfo.ERROR,
        text: "Автозагрузка не опубликована проверьте интернет",
      })
    );
    yield put(setLoader(Loaders.errorShared + payload.id));
  }
}

function* sagaGetWidgets({ payload }: ReturnType<typeof getWidgets.started>) {
  try {
    const widgets: Widgets = yield call(api.comments.getWidget);
    yield put(getWidgets.done({ params: payload, result: widgets }));
    yield put(setLoader(""));
  } catch (e) {}
}

export function* watchCommentsSaga() {
  yield takeEvery(getPhoto.started, getPhotoSaga);
  yield takeEvery(logging.started, sageLogging);
  yield takeEvery(getTemplate.started, sagaGetTemplate);
  yield takeEvery(getTemplateCreate.started, sagaGetTemplateCreate);
  yield takeEvery(deleteTemplate.started, sagaDeleteTemplate);
  yield takeEvery(getCategory.started, sagaItemsCategory);
  yield takeEvery(fetchCreateTemplate.started, sagaCreateTemplate);
  yield takeEvery(uploadFile.started, sagaUploadPhoto);
  yield takeEvery(createXml.started, sagaCreateXml);
  yield takeEvery(getXmlList.started, sagaGetXml);
  yield takeEvery(deleteXml.started, sagaDeleteXml);
  yield takeEvery(sharedXml.started, sagaSharedXml);
  yield takeEvery(register.started, sageRegister);
  yield takeEvery(getWidgets.started, sagaGetWidgets);
  yield takeEvery(getTemplateById.started, sageGetTemplateById);
  yield takeEvery(getActiveXml.started, sagaGetActiveXml);
  yield takeEvery(buy.started, sagaBuy);
  yield takeEvery(sendKey.started, sagaSendEmail);
  yield takeEvery(refreshPassword.started, sagaRefresh);
  yield takeEvery(createTemplateData.started, sagaCreateTemplateData);
  yield takeEvery(updateTemplateData.started, sagaUpdateTemplateData);
  yield takeEvery(paginateTable.started, sagapaginateTable);
  yield takeEvery(createTemplateGroup.started, sagaCreateTemplateGroup);
  yield takeEvery(updateTemplateGroup.started, sagaUpdateTemplateGroup);
  // yield takeEvery(paginateTableGet.started, sagaNextPageTable);
}
