import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Navbar from "../Navbars/AuthNavbar";
import Login from "../../views/auth/Login";
import Register from "../../views/auth/Register";
import Refresh from "../../views/auth/Refresh";

const Auth: React.FC = () => {

  return (
    <>
      <Navbar />
      <main>
        <section className="relative w-full h-full py-40 min-h-screen" style={{display: 'flex',
    alignItems: 'center'}}>
          <div
            className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
            style={{
              backgroundImage: 'url("/img/register_bg_2.png")',
            }}
          ></div>
          <Switch>
            <Route path="/auth/login" exact component={Login} />
            <Route path="/auth/register" exact component={Register} />
            <Route path="/auth/refresh" exact component={Refresh} />
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
        </section>
      </main>
    </>
  );
}


export default Auth
