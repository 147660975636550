import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import CustomTooltip from "../../shared/CustomTooltip";
import { Tooltips } from "./../../constant/constants";
export default function CardStats({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescripiron,
  statIconName,
  statIconColor,
  statTitleSecond,
  addCloud,
  buyCloud,
  date,
}) {
  const curentTime = moment.utc().toISOString();
  const isLost =
    moment.utc(date).valueOf() - moment.utc(curentTime).valueOf() > 1
      ? false
      : true; //(moment.utc(date).valueOf()-moment.utc(curentTime).valueOf()) > 86400000 ? false : true;
  const isOneDay =
    moment.utc(date).valueOf() - moment.utc(curentTime).valueOf() > 86400000
      ? false
      : true;

  const [time, setTime] = useState({
    hour: Math.floor(
      (moment.utc(date).valueOf() - moment.utc(curentTime).valueOf()) / 3600000
    ),
    minuts: Math.floor(
      (moment.utc(date).valueOf() -
        moment.utc(curentTime).valueOf() -
        Math.floor(
          (moment.utc(date).valueOf() - moment.utc(curentTime).valueOf()) /
            3600000
        ) *
          3600000) /
        60000
    ),
    second: Math.floor(
      (moment.utc(date).valueOf() -
        moment.utc(curentTime).valueOf() -
        Math.floor(
          (moment.utc(date).valueOf() - moment.utc(curentTime).valueOf()) /
            3600000
        ) *
          3600000 -
        Math.floor(
          (moment.utc(date).valueOf() -
            moment.utc(curentTime).valueOf() -
            Math.floor(
              (moment.utc(date).valueOf() - moment.utc(curentTime).valueOf()) /
                3600000
            ) *
              3600000) /
            60000
        ) *
          60000) /
        1000
    ),
  });
  useEffect(() => {}, []);

  useEffect(() => {
    // setInterval(()=>{
    //   const curentTime = moment.utc().toISOString();
    //   const hour = Math.floor((moment.utc(date).valueOf()-moment.utc(curentTime).valueOf())/3600000);
    //   const minuts =  Math.floor(((moment.utc(date).valueOf()-moment.utc(curentTime).valueOf())-(hour*3600000))/60000)
    //   const second =  Math.floor(((moment.utc(date).valueOf()-moment.utc(curentTime).valueOf())-(hour*3600000)-minuts*60000 )/1000)
    //   setTime({hour,minuts, second})
    // }, 1000)
  }, [date]);
  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
              <h5 className="text-blueGray-400 uppercase font-bold text-xs">
                {statSubtitle}
              </h5>
              <span className="font-semibold text-xl text-blueGray-700">
                {statTitle}
                {!!statTitleSecond && statTitleSecond}
              </span>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <div
                className="relative w-auto pl-4 flex-initial"
                style={{ width: "3rem", padding: 0 }}
              >
                <div
                  className={
                    "text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full " +
                    statIconColor
                  }
                >
                  <i
                    className={
                      statSubtitle == "Цена текущего тарифа" && isLost
                        ? "fas fa-retweet"
                        : statIconName
                    }
                    onClick={() => {
                      if (!!addCloud) {
                        buyCloud();
                      }
                    }}
                  ></i>
                </div>
              </div>
              {!!date && !isLost && (
                <span
                  style={{
                    fontSize: 12,
                    color: "rgb(153, 153, 153)",
                    marginTop: 5,
                  }}
                >
                  действует по {moment(date).format("DD.MM.YYYY")}
                </span>
              )}
              {!!date && isLost && (
                <span
                  style={{
                    fontSize: 12,
                    color: "rgb(153, 153, 153)",
                    marginTop: 5,
                  }}
                >
                  {" Тариф истек "}
                  <Tooltip interactive title={Tooltips.replay}>
                    <span>
                      <i
                        className="fas fa-question-circle"
                        style={{ marginRight: 6, color: "rgb(71, 86, 105)" }}
                      />
                    </span>
                  </Tooltip>
                </span>
              )}
              {/* {!!date && 
              !isLost && isOneDay&&(
                <span
                  style={{
                    fontSize: 12,
                    color: "rgb(153, 153, 153)",
                    marginTop: 5,
                    color: "red",
                    fontWeight: 200,
                    fontSize: 14,
                    paddingLeft: "1rem",
                  }}
                >
                  закончится через <>{time.hour.toString().length==1?`0${time.hour}`:time.hour}:{time.minuts.toString().length==1?`0${time.minuts}`:time.minuts}:{time.second.toString().length==1?`0${time.second}`:time.second}</>
                </span>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

CardStats.defaultProps = {
  statSubtitle: "Traffic",
  statTitle: "",
  statArrow: "up",
  statPercent: "",
  statPercentColor: "text-emerald-500",
  statDescripiron: "Since last month",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statPercent: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
