import ServiceFactory from "./api.fetchs";
import axios from "axios";
import { getCookie } from "../../helpers/cookies";
import {
  getAccessToken,
  getRefreshToken,
  isRefreshTokenExpired,
  setAccessToken,
  setRefreshToken,
} from "../auth/tokens";
import { url } from "../../constant/constants";
import { store } from "../..";
import { setToken } from "../../redux/actions/actions";
type apiCommentsParams = {
  HTTPBaseURL: string;
};

export const Api = ({ HTTPBaseURL }: apiCommentsParams) => {
  const HTTP = axios.create({
    baseURL: HTTPBaseURL,
  });

  HTTP.interceptors.request.use((request) => {
    request.headers.authorization = `Bearer ${getAccessToken()}`;
    return request;
  });

  HTTP.interceptors.response.use(
    (response) => response,
    async (error) => {
      const refreshToken = getRefreshToken();
      if (
        error?.response?.status !== 401 ||
        !refreshToken ||
        isRefreshTokenExpired()
      ) {
        return Promise.reject(error);
      }
        
      const result = await HTTP.post(`${url}/auth/grantNewTokens`, {
        refreshToken,
      });
      
      if (!result || !result.data) {
        return Promise.reject(error);
      }
      
      setAccessToken(
        result.data.data.accessToken.token,
        result.data.data.accessToken.expiresIn
      );
      setRefreshToken(
        result.data.data.refreshToken.token,
        result.data.data.refreshToken.expiresIn
      );

      store.dispatch(setToken(result.data.data.accessToken.token));

      return HTTP.request(error.config);
    }
  );

  return {
    comments: ServiceFactory({
      HTTPBaseURL: HTTP,
    }),
  };
};
