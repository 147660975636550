import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./redux/reducers/rootReducer"; //"./redux/reducers/rootReducer";
import { createStore, applyMiddleware, compose } from "redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./tailwind.css";
import { UserAgent } from "react-useragent";
import Index from "./components/Index";
import Auth from "./components/layouts/Auth";
import Menu from "./components/layouts/Menu/Menu";
import XmlList from "./components/ListXmlForm";
import XmlCreate from "./components/CreateXml";
import { watchCommentsSaga } from "./redux/saga";
import { Rdf } from "./views/auth/Pdf";
import PrivateRouter from "./shared/PrivateRouter/PrivateRouter";
import Test from "./test";
import Form from "./components/Tarif";
import { InstructionsPage } from "./components/Instructions";
import ReportMasterPage from "./components/ReportMaster/ReportMasterPage";
const sagaMiddleware = createSagaMiddleware();
export const store = compose(applyMiddleware(sagaMiddleware))(createStore)(
  rootReducer
);

sagaMiddleware.run(watchCommentsSaga);

ReactDOM.render(
  <React.StrictMode>
    <UserAgent>
      {({ ua }) => {
        return ua.mobile ? (
          <>
            <div
              style={{
                fontSize: "32px",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              Ваше устройство не поддерживается. Воспользуйтесь компьютером
            </div>
          </>
        ) : (
          <>
            <Provider store={store}>
              <BrowserRouter>
                <Switch>
                  <Route exact path="/" component={Index} />
                  <Route path="/auth" component={Auth} />
                  <Route path="/test" component={Test} />
                  <PrivateRouter path="/admin" component={Menu} />
                  <PrivateRouter path="/xmlList" component={XmlList} />
                  <PrivateRouter
                    path="/instructions"
                    component={InstructionsPage}
                  />
                  {/* <PrivateRouter 
                    path='/reportMaster' 
                    component={ReportMasterPage}
                  /> */}
                  <PrivateRouter
                    path="/xmlCreate"
                    exact
                    component={XmlCreate}
                  />
                  <PrivateRouter path="/tarif" component={Form} />
                  <PrivateRouter
                    path="/xmlCreate/edit"
                    exact
                    component={XmlCreate}
                  />
                  <Route path="/user_agreement" component={Rdf} />
                  <Redirect from="*" to="/" />;
                </Switch>
              </BrowserRouter>
            </Provider>
          </>
        );
      }}
    </UserAgent>
  </React.StrictMode>,
  document.getElementById("root")
);
