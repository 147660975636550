import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { compose } from "recompose";
import { getWidgets } from "../../redux/actions/actions";

interface Navbar {
  isAuth?: boolean;
  getWidgets?: () => void;
}

const Navbar: React.FC<Navbar> = ({ isAuth, getWidgets }) => {
  useEffect(()=>{
    getWidgets && getWidgets()
  },[])
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div
            className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
            style={{ width: "auto" }}
          >
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              adPlacer
            </Link>
          </div>
          <ul
            className="flex flex-col lg:flex-row list-none lg:ml-auto"
            style={{ flexDirection: "row" }}
          >
            {/* <NavLink to="/templates" >
              <button
                className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                type="button"
              >
                <i></i> templates
                </button>
            </NavLink> */}
            {isAuth && (
              <NavLink to="/admin">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                  <i></i> Редактор
                </button>
              </NavLink>
            )}
            <li className="flex items-center">
              <NavLink to="/auth">
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                  
                >
                  <i></i>
                  {isAuth ? "Выйти" : "Войти"}
                </button>
              </NavLink>
              {/* <NavLink to="/register" >
                <button
                  className="bg-lightBlue-500 text-white active:bg-lightBlue-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
                  <i></i> SIGN UP
                </button>
              </NavLink> */}
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

// export default Navbar

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    getWidgets: () => dispatch(getWidgets.started(undefined)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    isAuth: !!state?.Reducer?.user?.token,
  };
};

export default compose<Navbar, Navbar>(
  connect(mapStateToProps, mapDispatchToProps)
)(Navbar);
