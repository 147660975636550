import React from 'react'
import CircularProgress from "@material-ui/core/CircularProgress";
interface LoaderComponentProps {

}

const LoaderComponents: React.FC<LoaderComponentProps> = () => {
    return (
        <div
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
            style={{ margin: 0, boxShadow: "none" }}
        >
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0" style={{
                height: '100vh', display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <CircularProgress
                    style={{
                        margin: "10px",
                        marginTop: "29px",
                        color: "#1DA5E9",
                    }}
                    size={60}
                />
            </div>
        </div>
    )

}

export default LoaderComponents