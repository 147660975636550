import React, { useRef } from "react";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { compose } from "recompose";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  getTemplate,
  deleteTemplate,
  setOpenCreateTemlate,
  setIdEdit,
  setInfoStatus,
} from "../../redux/actions/actions";
import { Loaders, TypeStatusInfo } from "../../constant/constants";
import {TemplateGroups, Templates as TemplatesType} from "../../api/comments/api.types";
import { RootState } from "../../redux/reducers/rootReducer";
import Modal from "../../shared/Modal";
import TableNew from "../../shared/Table/TableNew";
import { useState } from "react";
import { useEffect } from "react";
import CustomTooltip from "../../shared/CustomTooltip";
import "./style.css";
import api from "../../api";
import { socketEmitter } from "../../api/socket";
import TableModalTemplate from "../TableModalTemplate";
import Select from "react-select";
import StatisticsTable from "../Statistics/Statistics";
interface TemplatesProps { }

interface TemplatesPrivateProps extends TemplatesProps {
  fetchTemplate: (userId: string, page?: string, limit?: string) => void;
  deleteTemplate: (params: any) => void;
  userId: string;
  isErrorTemplate: boolean;
  isLoader: boolean;
  loaderName: string;
  listTemplate: TemplatesType[];
  setOpenCreateTemlate: (props: any) => void;
  setIdEdit: (id: number) => void;
  setInfoStatus: (data: { type: TypeStatusInfo; text: string } | null) => void;
}

function getTemplates(userId: string, page?: number, limit?: number) {
  return api.comments.getTemplate(
    userId,
    false,
    false,
    undefined,
    page?.toString(),
    limit?.toString()
  );
}

// TODO: remake template actions not using redux
function Templates({
  fetchTemplate,
  userId,
  isLoader,
  isErrorTemplate,
  listTemplate,
  deleteTemplate,
  setOpenCreateTemlate,
  setIdEdit,
  loaderName,
  setInfoStatus,
}: TemplatesPrivateProps) {
  const [templates, setTemplates] = useState<TemplatesType[]>([]);
  const [templateGroups, setTemplateGroups] = useState<TemplateGroups[]>([]);
  const [id, setId] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = useState(false);

  const style = loader
    ? { display: "flex", justifyContent: "center", alignItems: "center" }
    : {};
  let HeaderStats = document.getElementById("HeaderStats")?.clientHeight;

  const limit = 20;
  const listRef = useRef<HTMLDivElement>(null);
  const loadedPages = useRef(1);
  const totalPages = useRef(null);

  const getNextPage = () => {
    if (
      totalPages.current !== null &&
      totalPages.current < loadedPages.current
    ) {
      return;
    }
    return getTemplates(userId, loadedPages.current, limit).then((result) => {
      setTemplates((prev) => [...prev, ...result.data.nonGrouped]);
      totalPages.current = result.pages;
      loadedPages.current += 1;
    });
  };

  const reloadPages = () => {
    getTemplates(userId, 1, limit).then((result) => {
      setTemplates([...result.data.nonGrouped]);
      totalPages.current = result.pages;
      loadedPages.current = 2;
      setTemplateGroups([...result.data.grouped]);
    });
  };

  const scrollHandler = () => {
    if (!listRef.current) return;

    if (
      listRef.current.scrollHeight ===
      listRef.current.offsetHeight + listRef.current.scrollTop
    ) {
      getNextPage();
    }
  };

  const handleClickOpen = (idValue: number) => () => {
    setId(idValue);
    setOpen(true);
  };

  const handleDelete = () => {
    deleteTemplate({ id, userId });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseModalGroup = () => {
    setOpenModalCreateGroup(false);
  };

  useEffect(() => {
    if (loaderName == "templateLoadComplete") {
      reloadPages();
    }
  }, [loaderName]);

  const loadCompleteHandler = () => {
    reloadPages();
    setInfoStatus({
      type: TypeStatusInfo.DONE,
      text: "Шаблон успешно создан",
    });
  };

  useEffect(() => {
    if (!socketEmitter.clientSocket) {
      socketEmitter.connect();
    }
    socketEmitter.clientSocket.on("templateLoadComplete", loadCompleteHandler);
    return () => {
      socketEmitter.clientSocket.off(
        "templateLoadComplete",
        loadCompleteHandler
      );
    };
  }, [loadCompleteHandler, socketEmitter.clientSocket, socketEmitter]);


  
  React.useEffect(() => {
    //fetchTemplate(userId, loadedPages.toString(), limit.toString());
    reloadPages();
  }, [listTemplate]);

  useEffect(() => {
    if (!isLoader) {
      setTimeout(() => {
        setLoader(isLoader);
      }, 1000);
    } else setLoader(true);
  }, [isLoader]);

  const [pageT, setPageT] = useState(0);
  const [isTableOpen, setTableOpen] = useState(false);
  const [statistics, setStatistics] = useState<any>({});
  const [isStatisticsTableOpen, setStatisticsTableOpen] = useState(false);
  const [fn, setFn]: any = useState();
  const [tableTemplateId, setTableTemplateId] = useState<number | string>("");
  const [tableCount, setTableCount] = useState(0);
  const [tableLoader, setTableLoader] = useState<boolean>(false);
  const [tablePageContent, setTablePageContent] = useState<any[]>([]);
  const [showTemplates, setShowTemplates] = useState(true);
  const [selectedUngroupedTemplates, setSelectedUngroupedTemplates] = useState<number[]>([]); // Состояние для отслеживания выбранных шаблонов
  const [selectedGroupedTemplates, setSelectedGroupedTemplates] = useState<number[]>([]); // Состояние для отслеживания выбранных шаблонов
  const [openModalCreateGroup, setOpenModalCreateGroup] = useState(false);
  const [templateGroupName, setTemplateGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [activeGroup, setActiveGroup] = useState<TemplateGroups | null>(null);

  // Функция для переключения активной группы
  const toggleGroup = (group: TemplateGroups) => {
    setActiveGroup(activeGroup === group ? null : group);
  };

  const showTemplateList = () => {
    setShowTemplates(true);
  };

  // Функция для отображения групп шаблонов
  const showGroupList = () => {
    setShowTemplates(false);
  };

  const handleGroupedTemplateSelection = (templateId: number) => {
    if (selectedGroupedTemplates.includes(templateId)) {
      setSelectedGroupedTemplates(selectedGroupedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedGroupedTemplates([...selectedGroupedTemplates, templateId]);
    }
  };

  const handleUngroupedTemplateSelection = (templateId: number) => {
    if (selectedUngroupedTemplates.includes(templateId)) {
      setSelectedUngroupedTemplates(selectedUngroupedTemplates.filter((id) => id !== templateId));
    } else {
      setSelectedUngroupedTemplates([...selectedUngroupedTemplates, templateId]);
    }
  };

  const handleAddToGroup = () => {
    // Здесь можно выполнить действия с выбранными шаблонами
    setOpenModalCreateGroup(true);
  };

  const handleGetStatistics = (templateId: number) => {
    api.comments.getStatisticsById({id: templateId, userId: 319970871}).then((res) => {
      console.log({res})
      setStatistics(res);
    });
  };

  const handleUpdateGroup = () => {
    const targetGroup = templateGroups.find((group) => group.id === selectedGroup);
    const updatedTemplates = targetGroup?.templates.map((template) => template.id).concat(selectedUngroupedTemplates);
    api.comments.updateTemplateGroup({templateIds: updatedTemplates, id: selectedGroup}).then((res) => {
      setOpenModalCreateGroup(false);
      setTableOpen(false);
      setTableLoader(true);
      reloadPages();
      setSelectedUngroupedTemplates([])
    })
  }

  const handleRemoveFromGroup = () => {
    const selectedGroup = templateGroups.find((group) => group.id === activeGroup?.id);
    // Remove selected templates from the selected group
    const updatedTemplates = selectedGroup?.templates.filter((template) => !selectedGroupedTemplates.includes(template.id)).map((template) => template.id);
    api.comments.updateTemplateGroup({templateIds: updatedTemplates, id: selectedGroup?.id}).then((res) => {
      setOpenModalCreateGroup(false);
      setTableOpen(false);
      setTableLoader(true);
      reloadPages();
      setSelectedGroupedTemplates([])
    })
  }

  const handleCreateGroup = () => {
    api.comments.createTemplateGroup({templateIds: selectedUngroupedTemplates, name: templateGroupName}).then((res) => {
      setOpenModalCreateGroup(false);
      setTableOpen(false);
      setTableLoader(true);
      reloadPages();
      setSelectedGroupedTemplates([])
    })
  }

  const handleDeleteGroup = (groupId) => {
    api.comments.deleteTemplateGroup(groupId).then((res) => {
      setOpenModalCreateGroup(false);
      setTableOpen(false);
      setTableLoader(true);
      reloadPages();
    })
  }

  const handleGroupSelect = (group: any) => {
    setSelectedGroup(group.id);
  }

  const tablePaginationHandler = async (page, id, updateTemplate, newPage) => {
    setTableLoader(true);
    console.log(updateTemplate, 'updatedTemplate');
    if (page != null && updateTemplate && id) {

      await api.comments.paginateTable(id, page, updateTemplate);
    }

    const response = await api.comments.paginateTableGet(id, newPage);
    console.log(response.data, "TEMPLATE");
    if (response?.data?.updateTemplate) {
      setTablePageContent(response.data.updateTemplate);
    }
    setTableLoader(false);
  };

  const updateTableHandler = async () => {
    console.log(tablePageContent, tableTemplateId);

    if (tableTemplateId) {
      setTableLoader(true);
      fn();
      await api.comments.paginateTable(
        tableTemplateId,
        pageT,
        tablePageContent
      );
      const res = await api.comments.updateTemplateByMok({
        templateId: tableTemplateId,
      });
      setTableOpen(false);
      setTableLoader(true);
      reloadPages();
    }
  };

  return (
    <>
      <div style={{marginBottom: 10, marginTop: 20}}>
        <span onClick={showTemplateList} style={{cursor: "pointer", textDecoration: showTemplates? "underline" : "none"}}>
          Шаблоны
        </span>
        {" | "}
        <span onClick={showGroupList} style={{cursor: "pointer", textDecoration: showTemplates? "none" : "underline"}}>
          Группы шаблонов
        </span>
      </div>
      {showTemplates ? (
        <div>
          <Modal
            open={isTableOpen}
            maxWidth="100%"
            width="80%"
            handleClose={() => {
              setTableOpen(false);
              setTableTemplateId("");
            }}
            title={"Просмотр шаблона"}
            cancelTextButtom="Отменить"
            submitTextButtom="Обновить"
            submitButtom={updateTableHandler}
          >
            {(tablePageContent && tablePageContent.length) || tableLoader ? (
              <TableNew
                setPage={setPageT}
                tableData={tablePageContent}
                updateData={tablePaginationHandler}
                idTemplate={tableTemplateId}
                loaderTable={tableLoader}
                countNewData={tableCount}
                setFn={setFn}
              />
            ) : (
              <div>
                Не удалось построить таблицу, попробуйте пересоздать или обновить
                шаблон.
              </div>
            )}
          </Modal>
          <Modal
            title={"Просмотр статистики"}
            open={isStatisticsTableOpen}
            maxWidth="100%"
            width="80%"
            handleClose={() => setStatisticsTableOpen(false)}
            cancelTextButtom="Закрыть"
            submitButtom={() => {}}
          >
            <StatisticsTable templateId={statistics}/>
          </Modal>
          <Modal
            open={open}
            handleClose={handleClose}
            title={"Вы точно хотите удалить шаблон обявлений?"}
            contentText={`Удаленные шаблоны отключат xml в которых они используются и исчезнут
        без возможности восстановления`}
            cancelTextButtom="Отменить"
            submitTextButtom="Удалить"
            submitButtom={handleDelete}
          />
          <div
            className="menu_template"
            ref={listRef}
            onScroll={scrollHandler}
            style={{
              // width: "100%",
              overflow: "auto",
              height:
                HeaderStats && HeaderStats < 544
                  ? `calc(100vh - ${HeaderStats}px - 54px) `
                  : "",
              // height: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...style,
            }}
          >
            {loader ? (
              <CircularProgress
                size={60}
                style={{
                  margin: "10px",
                  marginTop: "29px",
                  color: "#1DA5E9",
                }}
              />
            ) : (
              templates.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between ",
                    backgroundColor: "white",
                    borderRadius: "0.25rem",
                    padding: 15,
                    marginBottom: 10,
                    boxShadow: "rgb(136 136 136 / 20%) 3px 3px 10px 0px",
                    width: "100%",
                  }}
                >
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <input
                      style={{marginRight: "10px"}}
                      type="checkbox"
                      checked={selectedUngroupedTemplates.includes(item.id)}
                      onChange={() => handleUngroupedTemplateSelection(item.id)}
                    />
                    <div style={{}}>{item.name}</div>
                  </div>
                  <div>
                    Статус: &#160;
                    {item.isActive === 1 && !item.isLoading && (
                      <Tooltip interactive title={"Автозагрузка: " + item.nameXml}>
                    <span
                      style={{
                        width: 132,
                        display: " inline-block",
                        color: "#14b8a6",
                      }}
                    >
                      {" используется"}
                    </span>
                      </Tooltip>
                    )}
                    {item.isActive === 0 && !item.isLoading && (
                      <span
                        style={{
                          width: 132,
                          display: " inline-block",
                          color: " #db5353",
                        }}
                      >
                    {" не используется"}
                  </span>
                    )}
                    {item.isActive === 3 && !item.isLoading && (
                      <span
                        style={{
                          width: 132,
                          display: " inline-block",
                          color: "#999999",
                        }}
                      >
                    {" не заполнен"}
                  </span>
                    )}
                    {item.isLoading && !item.isError && (
                      <span
                        style={{
                          width: 132,
                          display: " inline-block",
                          color: "#23aeee",
                        }}
                      >
                    {" загружается "}
                        <CircularProgress size="10px"/>
                  </span>
                    )}
                    {item.isError && (
                      <span
                        style={{
                          width: 132,
                          display: " inline-block",
                          color: "#ff0000",
                        }}
                      >
                    {" ошибка загрузки "}
                  </span>
                    )}
                    <i
                      onMouseDown={() => {
                      }}
                      className="fas fa-chart-bar"
                      style={{
                        marginRight: "10px",
                        color: "rgb(71, 86, 105)",
                        cursor: "pointer",
                      }}
                      onClick={ () => {
                        console.log(item)
                        setStatistics(item.id);
                        setStatisticsTableOpen(true);
                      }}
                    ></i>
                    <i
                      className="fas fa-table"
                      style={{
                        marginRight: "5px",
                        color: "rgb(71, 86, 105)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setTableOpen(true);
                        setTableTemplateId(item.id);
                        setTableCount(item.count);
                        tablePaginationHandler(null, item.id, null, 0);
                      }}
                    ></i>
                    <i
                      onMouseDown={() => {
                      }}
                      className="fas fa-edit"
                      style={{
                        marginRight: "5px",
                        color: "rgb(71, 86, 105)",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        console.log('ITEM id ', item.id);

                        setIdEdit(item.id);
                        setOpenCreateTemlate({
                          name: item.name,
                          value: "edit",
                          idCategory: item.idCategory,
                          editValue: null, //JSON.parse(item.originParams),
                          isActive: item.isActive,
                          params: null, //item?.params,
                          isDescription: item.isDescription,
                        });
                      }}
                    ></i>
                    <CustomTooltip
                      interactive
                      title={"Вы не можете удалить используемый шаблон"}
                      disabled={item.isActive !== 1 ? false : true}
                    >
                      <i
                        className="fas fa-trash-alt"
                        onClick={
                          item.isActive !== 1 ? handleClickOpen(item.id) : () => {
                          }
                        }
                        style={{color: "rgb(71, 86, 105)", cursor: "pointer"}}
                      ></i>
                    </CustomTooltip>
                  </div>
                </div>
              ))
            )}
            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '30px'}}>
              <button style={{width: "200px", opacity: selectedUngroupedTemplates.length > 0 ? '100%' : '50%'}} disabled={selectedUngroupedTemplates.length === 0} className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" onClick={handleAddToGroup}>Добавить в группу</button>
            </div>
          </div>
          <Modal
            open={openModalCreateGroup}
            handleClose={handleCloseModalGroup}
            title={"Подтвердите добавление шаблонов в группу"}
            cancelTextButtom="Отменить"
            submitTextButtom="Добавить"
            submitButtom={() => {
              console.log('Добавление')
              handleUpdateGroup();
            }}
          >
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Выберите название группы шаблонов
            </label>
            <Select
              isSearchable={false}
              id="selectTemplateGroup"
              name="Title"
              placeholder={"Выберите группу"}
              options={templateGroups.map((group) =>({name: group.name, label: group.name, id: group.id}))}
              onChange={handleGroupSelect}
              className="border-0 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </Modal>
        </div>
      ) : (
        <div>
          <Modal
            open={isTableOpen}
            maxWidth="100%"
            width="80%"
            handleClose={() => {
              setTableOpen(false);
              setTableTemplateId("");
            }}
            title={"Просмотр шаблона"}
            cancelTextButtom="Отменить"
            submitTextButtom="Обновить"
            submitButtom={updateTableHandler}
          >
            {(tablePageContent && tablePageContent.length) || tableLoader ? (
              <TableNew
                setPage={setPageT}
                tableData={tablePageContent}
                updateData={tablePaginationHandler}
                idTemplate={tableTemplateId}
                loaderTable={tableLoader}
                countNewData={tableCount}
                setFn={setFn}
              />
            ) : (
              <div>
                Не удалось построить таблицу, попробуйте пересоздать или обновить
                шаблон.
              </div>
            )}
          </Modal>
          <Modal
            open={open}
            handleClose={handleClose}
            title={"Вы точно хотите удалить шаблон обявлений?"}
            contentText={`Удаленные шаблоны отключат xml в которых они используются и исчезнут
        без возможности восстановления`}
            cancelTextButtom="Отменить"
            submitTextButtom="Удалить"
            submitButtom={handleDelete}
          />
          <div
            className="menu_template"
            ref={listRef}
            onScroll={scrollHandler}
            style={{
              // width: "100%",
              overflow: "auto",
              height:
                HeaderStats && HeaderStats < 544
                  ? `calc(100vh - ${HeaderStats}px - 54px) `
                  : "",
              // height: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ...style,
            }}
          >
            {loader ? (
              <CircularProgress
                size={60}
                style={{
                  margin: "10px",
                  marginTop: "29px",
                  color: "#1DA5E9",
                }}
              />
            ) : (
              templateGroups.map((group, index) => (
                  activeGroup? (
                    activeGroup.name === group.name && (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between ",
                          backgroundColor: "white",
                          borderRadius: "0.25rem",
                          padding: 15,
                          marginBottom: 10,
                          boxShadow: "rgb(136 136 136 / 20%) 3px 3px 10px 0px",
                          width: "100%",
                        }}
                      >
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                          <i className='fas fa-arrow-circle-left' style={{
                            marginRight: "5px",
                            color: "rgb(71, 86, 105)",
                            cursor: "pointer",
                          }}
                             onClick={() => {
                               setActiveGroup(null);
                             }}></i>
                          <span className='text-2xl font-semibold'>{activeGroup.name}</span>
                        </div>
                        {group.templates.map((template, index) => (
                          <div key={index} style={{ /* Стили для контейнера шаблона в группе */}}>
                            {/* Отображаем шаблон внутри группы */}
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between ",
                                backgroundColor: "white",
                                borderRadius: "0.25rem",
                                padding: 15,
                                marginBottom: 10,
                                boxShadow: "rgb(136 136 136 / 20%) 3px 3px 10px 0px",
                                width: "100%",
                              }}
                            >
                              <div style={{display: 'flex', alignItems: 'center'}}>
                                <input
                                  style={{marginRight: "10px"}}
                                  type="checkbox"
                                  checked={selectedGroupedTemplates.includes(template.id)}
                                  onChange={() => handleGroupedTemplateSelection(template.id)}
                                />
                                <div style={{}}>{template.name}</div>
                              </div>
                              <div>
                                Статус: &#160;
                                {template.isActive === 1 && !template.isLoading && (
                                  <Tooltip interactive title={"Автозагрузка: " + template.nameXml}>
                    <span
                      style={{
                        width: 132,
                        display: " inline-block",
                        color: "#14b8a6",
                      }}
                    >
                      {" используется"}
                    </span>
                                  </Tooltip>
                                )}
                                {template.isActive === 0 && !template.isLoading && (
                                  <span
                                    style={{
                                      width: 132,
                                      display: " inline-block",
                                      color: " #db5353",
                                    }}
                                  >
                    {" не используется"}
                  </span>
                                )}
                                {template.isActive === 3 && !template.isLoading && (
                                  <span
                                    style={{
                                      width: 132,
                                      display: " inline-block",
                                      color: "#999999",
                                    }}
                                  >
                    {" не заполнен"}
                  </span>
                                )}
                                {template.isLoading && !template.isError && (
                                  <span
                                    style={{
                                      width: 132,
                                      display: " inline-block",
                                      color: "#23aeee",
                                    }}
                                  >
                    {" загружается "}
                                    <CircularProgress size="10px"/>
                  </span>
                                )}
                                {template.isError && (
                                  <span
                                    style={{
                                      width: 132,
                                      display: " inline-block",
                                      color: "#ff0000",
                                    }}
                                  >
                    {" ошибка загрузки "}
                  </span>
                                )}
                                <i
                                  className="fas fa-table"
                                  style={{
                                    marginRight: "5px",
                                    color: "rgb(71, 86, 105)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    setTableOpen(true);
                                    setTableTemplateId(template.id);
                                    setTableCount(template.count);
                                    tablePaginationHandler(null, template.id, null, 0);
                                  }}
                                ></i>
                                <i
                                  onMouseDown={() => {
                                  }}
                                  className="fas fa-edit"
                                  style={{
                                    marginRight: "5px",
                                    color: "rgb(71, 86, 105)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    console.log('ITEM id ', template.id);

                                    setIdEdit(template.id);
                                    setOpenCreateTemlate({
                                      name: template.name,
                                      value: "edit",
                                      idCategory: template.idCategory,
                                      editValue: null, //JSON.parse(item.originParams),
                                      isActive: template.isActive,
                                      params: null, //item?.params,
                                      isDescription: template.isDescription,
                                    });
                                  }}
                                ></i>
                                <CustomTooltip
                                  interactive
                                  title={"Вы не можете удалить используемый шаблон"}
                                  disabled={template.isActive !== 1 ? false : true}
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    onClick={
                                      template.isActive !== 1 ? handleClickOpen(template.id) : () => {
                                      }
                                    }
                                    style={{color: "rgb(71, 86, 105)", cursor: "pointer"}}
                                  ></i>
                                </CustomTooltip>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '30px'}}>
                          <button style={{width: "200px", opacity: selectedGroupedTemplates.length > 0 ? '100%' : '50%'}} disabled={selectedGroupedTemplates.length === 0}
                                  className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                  onClick={handleAddToGroup}>Удалить из группы
                          </button>
                        </div>
                        <Modal
                          open={openModalCreateGroup}
                          handleClose={handleCloseModalGroup}
                          title={"Подтвердите удаление шаблонов из группы"}
                          cancelTextButtom="Отменить"
                          submitTextButtom="Удалить"
                          submitButtom={() => {
                            console.log('Удаление')
                            handleRemoveFromGroup();
                          }}
                        >
                          <label
                            className="block uppercase text-blueGray-600 text-x font-bold mb-2"
                            htmlFor="grid-password"
                          >
                            {activeGroup?.name}
                          </label>

                        </Modal>
                      </div>
                    )
                    ) :
                    (<div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between ",
                      backgroundColor: "white",
                      borderRadius: "0.25rem",
                      padding: 15,
                      marginBottom: 10,
                      boxShadow: "rgb(136 136 136 / 20%) 3px 3px 10px 0px",
                      width: "100%",
                    }}>
                      <div style={{}}>{group.name}</div>
                      <Modal
                        title={"Просмотр статистики"}
                        open={isStatisticsTableOpen}
                        maxWidth="100%"
                        width="80%"
                        handleClose={() => setStatisticsTableOpen(false)}
                        cancelTextButtom="Закрыть"
                        submitButtom={() => {}}
                      >
                        <StatisticsTable templateId={statistics}/>
                      </Modal>
                      <div>

                        <i style={{textDecoration: 'underline', cursor: 'pointer', marginRight: "35px"}}
                           onClick={() => toggleGroup(group)}
                        >
                          Просмотреть группу
                        </i>
                        <i
                          onMouseDown={() => {
                          }}
                          className="fas fa-chart-bar"
                          style={{
                            marginRight: "10px",
                            color: "rgb(71, 86, 105)",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            console.log(group.templates)
                            setStatistics(group.templates.map((template) => template.id));
                            setStatisticsTableOpen(true);
                          }}
                        ></i>
                        <CustomTooltip
                          interactive
                          title={"Вы не можете удалить используемый шаблон"}
                          disabled={false}
                        >
                          <i
                            className="fas fa-trash-alt"
                            onClick={
                              () => handleDeleteGroup(group.id)
                            }
                            style={{color: "rgb(71, 86, 105)", cursor: "pointer"}}
                          ></i>
                        </CustomTooltip>
                      </div>
                    </div>)
                )
              )
            )}
            {!activeGroup && (<>
              <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '30px'}}>
                <button style={{width: "200px"}}
                        className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        onClick={handleAddToGroup}>Создать группу
                </button>
              </div>
              <Modal
                open={openModalCreateGroup}
            handleClose={handleCloseModalGroup}
            title={"Подтвердите создание группы шаблонов"}
            cancelTextButtom="Отменить"
            submitTextButtom="Сформировать"
            submitButtom={() => {
              console.log('создание')
              handleCreateGroup();
            }}
          >
            <label
              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              Введите название группы шаблонов
            </label>
            <input
              id="nameTemplateGroup"
              name="Title"
              value={templateGroupName}
              onChange={(event) => setTemplateGroupName(event.target.value)}
              type="text"
              style={{border: "1px solid #ccc"}}
              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            />
          </Modal></> )}
</div>
</div>)}
</>
)
  ;
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchTemplate: (userId: string, page?: string, limit?: string) =>
      dispatch(
        getTemplate.started({
          userId,
          isActive: false,
          all: false,
          page,
          limit,
        })
      ),
    deleteTemplate: (params: { id: number; userId: string }) =>
      dispatch(deleteTemplate.started(params)),
    setOpenCreateTemlate: (props: { value: string; idCategory: number }) =>
      dispatch(setOpenCreateTemlate(props)),
    setIdEdit: (id: number) => dispatch(setIdEdit(id)),
    setInfoStatus: (data: { type: TypeStatusInfo; text: string } | null) => {
      dispatch(setInfoStatus(data));
    },
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    listTemplate: state.Reducer.listTemplate,
    userId: state.Reducer.user.userId,
    isLoader: state.Reducer.loaderName === Loaders.fetchTemplate,
    loaderName: state.Reducer.loaderName,
    isErrorTemplate: state.Reducer.loaderName === Loaders.fetchTemplateError,
  };
};

export default compose<TemplatesPrivateProps, TemplatesProps>(
  connect(mapStateToProps, mapDispatchToProps)
)(Templates);
