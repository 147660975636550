import React from "react";
// import pdf from '/public/pdf.pdf'
export const Rdf = () => {

  return (
    <main>
      <section className="relative w-full h-full py-40 min-h-screen">
        <div
          className="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
          style={{
            backgroundImage: 'url("/img/register_bg_2.png")',
          }}
        >
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
              <embed
                src="pdf.pdf"
                width="100%"
                height="90%"
              />
          </div>
        </div>
        </div>
      </section>
    </main>
  );
};
