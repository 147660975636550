///////////// ПО ПОРЯДКУ /////////////////
export function sequentialDistrib(cells, start, end, countAds) {
    start = Number(start)
    end = Number(end)
    countAds = Number(countAds)
    const numSelectedCells = end - start + 1
    const firstCell = start - 1
    for(let i = 0; i < cells.length; i++) {
        cells[i] = ''
    }

    if (countAds === 1) {
        cells[firstCell] = '1'

        return cells

    } else if (countAds === numSelectedCells) {
        cells = cellsEqualAds(cells, start, end)

        return cells

    } else if (numSelectedCells > countAds) {
        let count = 0
        for (let i = firstCell; count < countAds; i++) {
            cells[i] = '1'
            count++
        }

        return cells

    } else if (numSelectedCells < countAds) {
        return cellsLess(cells, numSelectedCells, start, end, countAds)
    }
}
//////////////////////////////////////////


///////////// РАВНОМЕРНО /////////////////
export function evenlyDistrib(cells, start, end, countAds) {
    start = Number(start)
    end = Number(end)
    countAds = Number(countAds)
    const numSelectedCells = end - start + 1
    const firstCell = start - 1
    for(let i = 0; i < cells.length; i++) {
        cells[i] = ''
    }

    if (countAds === 1) {
        cells[firstCell] = '1'

        return cells

    } else if (countAds === numSelectedCells) {
        cells = cellsEqualAds(cells, start, end)

        return cells

    } else if (numSelectedCells > countAds) {
        return cellsMore(cells, start, end, countAds)

    } else if (numSelectedCells < countAds) {
        return cellsLess(cells, numSelectedCells, start, end, countAds)
    }
}
//////////////////////////////////////////


function cellsEqualAds(cells, start, end) {
    for(let i = start - 1; i <= end - 1; i++) {
        cells[i] = '1'
    }

    return cells
}

function cellsMore(cells, start, end, countAds) {
    const numSelectedCells = end - start + 1
    const firstCell = start - 1
    const lastCell = end - 1
    for(let i = 0; i < cells.length; i++) {
        cells[i] = ''
    }
    let step = numSelectedCells / countAds;
    if (step >= 2) {
        if (Number.isInteger(step)) {
            for(let numCurrentCell = firstCell; countAds > 0; countAds--) {
                cells[numCurrentCell] = '1'
                numCurrentCell = numCurrentCell + step
            }
    
            return cells
    
        } else {
            step = Math.trunc(step)
            for(let numCurrentCell = firstCell; countAds > 0; countAds--) {
                if (countAds === 1 && numCurrentCell > lastCell) {
                    cells[lastCell] = '1'
                } else {
                    cells[numCurrentCell] = '1'
                    numCurrentCell = numCurrentCell + step
                }
            }
    
            return cells
        }
    } else {
        for(let i = 0; i < cells.length; i++) {
            if (i >= firstCell && i <= lastCell) {
                cells[i] = '1'
            } else {
                cells[i] = ''
            }
        }
        let countSpace = numSelectedCells - countAds
        step = Math.trunc(numSelectedCells / countSpace)
        for(let numCurrentCell = firstCell + step - 1; countSpace > 0; countSpace--) {
            cells[numCurrentCell] = ''
            numCurrentCell = numCurrentCell + step
        }
        return cells
    }
    
}

function cellsLess(cells, numSelectedCells, start, end, countAds) {
    const firstCell = start - 1
    const lastCell = end - 1
    let numAdsForEachCell = countAds / numSelectedCells
    if(Number.isInteger(numAdsForEachCell)) {
        numAdsForEachCell = String(numAdsForEachCell)
        for(let i = firstCell; i <= lastCell; i++) {
            cells[i] = numAdsForEachCell
        }

        return cells
    } else {
        let partNumAdsForEachCell = Math.trunc(numAdsForEachCell)
        let restOfAds = countAds - partNumAdsForEachCell * numSelectedCells
        partNumAdsForEachCell = String(partNumAdsForEachCell)
        for(let i = firstCell; i <= lastCell; i++) {
            cells[i] = partNumAdsForEachCell
        }
        let restOfCells = cellsMore(new Array(30), start, end, restOfAds)
        restOfCells.forEach((cell, numCell) => {
            if (cell === '1') {
                cells[numCell] = String(Number(cells[numCell]) + 1)
            }
        })

        return cells
    }
}


// console.log(sequentialDistrib(new Array(30), '1', '4', '10'))
// console.log(evenlyDistrib(new Array(30), '6', '15', '300'))