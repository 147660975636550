import React from "react";
// AccessoriesGloves
// / import {} from '../dynamucComponents/Acce'//../dynamucComponents/Foods/Foods'
// import("../dynamucComponents/ForChildrenCarSeats/ForChildrenCarSeats"))
export const Comp = () => {
  return <div>errror</div>;
};


// import {} from '../dynamucComponents/ManOther/ManOther'
export const components = (id) => {

  return React.lazy(
    () =>
      new Promise((resolve) => {
        setTimeout(
          () => resolve(import("../dynamucComponents/customComponents")),
          1000
        );
      }))

  // switch (Number(id)) {
  //   case 201:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/VideoAccessories/VideoAccessories")),
  //             1000
  //           );
  //         })
  //     );
  //     case 25:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManOther/ManOther")),
  //             1000
  //           );
  //         })
  //     );
  //   case 85:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Tools/Tools")),
  //             1000
  //           );
  //         })
  //     );
  //   case 84:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Doors/Doors")),
  //             1000
  //           );
  //         })
  //     );
  //   case 122:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Plants/Plants")),
  //             1000
  //           );
  //         })
  //     );

  //   case 9:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () =>
  //               resolve(import("../dynamucComponents/WomensShoes/WomensShoes")),
  //             1000
  //           );
  //         })
  //     );
  //   case 16:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () =>
  //               resolve(import("../dynamucComponents/WomanOther/WomanOther")),
  //             1000
  //           );
  //         })
  //     );
  //   case 71:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () =>
  //               resolve(
  //                 import("../dynamucComponents/HobbiesMountainBikes/HobbiesMountainBikes")
  //               ),
  //             1000
  //           );
  //         })
  //     );
  //   case 21:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManShoes/ManShoes")),
  //             1000
  //           );
  //         })
  //     );
  //   case 86:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Fireplace/Fireplace")),
  //             1000
  //           );
  //         })
  //     );
  //   case 87:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Windows/Windows")),
  //             1000
  //           );
  //         })
  //     );
  //   case 88:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Ceilings/Ceilings")),
  //             1000
  //           );
  //         })
  //     );
  //   case 89:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GardeningEquip/GardeningEquip")),
  //             1000
  //           );
  //         })
  //     );
  //   case 90:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/PlumbingSauna/PlumbingSauna")),
  //             1000
  //           );
  //         })
  //     );
  //   case 125:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Insulation/Insulation")),
  //             1000
  //           );
  //         })
  //     );
  //   case 126:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Fasteners/Fasteners")),
  //             1000
  //           );
  //         })
  //     );
  //   case 127:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/RoofGutter/RoofGutter")),
  //             1000
  //           );
  //         })
  //     );
  //   case 128:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/VarnishesPaints/VarnishesPaints")),
  //             1000
  //           );
  //         })
  //     );
  //   case 129:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/SheetMaterials/SheetMaterials")),
  //             1000
  //           );
  //         })
  //     );
  //   case 130:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/RolledMetal/RolledMetal")),
  //             1000
  //           );
  //         })
  //     );
  //   case 131:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GeneralConstructionMaterials/GeneralConstructionMaterials")),
  //             1000
  //           );
  //         })
  //     );
  //   case 132:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Finishing/Finishing")),
  //             1000
  //           );
  //         })
  //     );
  //   case 133:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Lumber/Lumber")),
  //             1000
  //           );
  //         })
  //     );
  //   case 134:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BuildingMixtures/BuildingMixtures")),
  //             1000
  //           );
  //         })
  //     );
  //   case 135:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BuildingWalls/BuildingWalls")),
  //             1000
  //           );
  //         })
  //     );
  //   case 136:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Electrician/Electrician")),
  //             1000
  //           );
  //         })
  //     );
  //   case 137:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BuildingMaterialsOthers/BuildingMaterialsOthers")),
  //             1000
  //           );
  //         })
  //     );
  //   case 278:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/House/House")),
  //             1000
  //           );
  //         })
  //     );
  //   case 279:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Bath/Bath")),
  //             1000
  //           );
  //         })
  //     );
  //   case 280:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Alcove/Alcove")),
  //             1000
  //           );
  //         })
  //     );
  //   case 281:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ChangeHouse/ChangeHouse")),
  //             1000
  //           );
  //         })
  //     );
  //   case 282:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Veranda/Veranda")),
  //             1000
  //           );
  //         })
  //     );
  //   case 283:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BuildForAnimals/BuildForAnimals")),
  //             1000
  //           );
  //         })
  //     );
  //   case 284:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Well/Well")),
  //             1000
  //           );
  //         })
  //     );
  //   case 285:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Canopy/Canopy")),
  //             1000
  //           );
  //         })
  //     );
  //   case 286:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Greenhouse/Greenhouse")),
  //             1000
  //           );
  //         })
  //     );
  //   case 287:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Toilet/Toilet")),
  //             1000
  //           );
  //         })
  //     );
  //   case 288:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Hozblok/Hozblok")),
  //             1000
  //           );
  //         })
  //     );

  //   case 289:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BuildOther/BuildOther")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 92:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/CompTablesChairs/CompTablesChairs")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 93:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BedsSofasArmchairs/BedsSofasArmchairs")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 94:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/KitchenSets/KitchenSets")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 95:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Lighting/Lighting")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 96:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/StandsPedestals/StandsPedestals")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 97:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/InteriorItemsArt/InteriorItemsArt")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 98:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/TablesChairs/TablesChairs")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 99:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/TextilesCarpets/TextilesCarpets")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 100:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WardrobesChestsDrawers/WardrobesChestsDrawers")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 101:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/FurnitureInteriorOther/FurnitureInteriorOther")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 102:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/VacuumCleaners/VacuumCleaners")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 103:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WashingMachines/WashingMachines")),
  //             1000
  //           );
  //         })
  //     );
            
  //   case 104:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Irons/Irons")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 105:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/SewingMachines/SewingMachines")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 106:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ShaversTrimmers/ShaversTrimmers")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 107:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HairClippers/HairClippers")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 108:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HairDryersStylingDevice/HairDryersStylingDevice")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 109:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Epilators/Epilators")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 110:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Hoods/Hoods")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 111:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/SmallKitchenAppliances/SmallKitchenAppliances")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 112:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Microwaves/Microwaves")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 113:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Slabs/Slabs")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 114:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Dishwashers/Dishwashers")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 115:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/RefrigeratorsFreezers/RefrigeratorsFreezers")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 116:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Fans/Fans")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 117:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AirConditioners/AirConditioners")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 118:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Heaters/Heaters")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 119:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AirPurifiers/AirPurifiers")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 120:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Thermometers/Thermometers")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 121:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AppliancesOther/AppliancesOther")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 123:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Dishes/Dishes")),
  //             1000
  //           );
  //         })
  //     );
                  
  //   case 124:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/KitchenGoods/KitchenGoods")),
  //             1000
  //           );
  //         })
  //     );



  //   case 290:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/Foods/Foods")),
  //             1000
  //           );
  //         })
  //     );

  //   case 4:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenPants/WomenPants")),
  //             1000
  //           );
  //         })
  //     );

  //   case 6:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenJeans/WomenJeans")),
  //             1000
  //           );
  //         })
  //     );

  //   case 7:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenSwimwear/WomenSwimwear")),
  //             1000
  //           );
  //         })
  //     );
      
  //     case 8:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenUnderwear/WomenUnderwear")),
  //             1000
  //           );
  //         })
  //     );

  //     case 10:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/WomenBlazersSuits/WomenBlazersSuits")),
  //               1000
  //             );
  //           })
  //       );

  //     case 11:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/WomenDressesSkirts/WomenDressesSkirts")),
  //               1000
  //             );
  //           })
  //       );
        
  //     case 12:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/WomenShirtsBlouses/WomenShirtsBlouses")),
  //               1000
  //             );
  //           })
  //       );
                
  //     case 13:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/WomenWeddingDresses/WomenWeddingDresses")),
  //               1000
  //             );
  //           })
  //       );
                
  //     case 14:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/WomenTopsTShirts/WomenTopsTShirts")),
  //               1000
  //             );
  //           })
  //       );
                        
  //     case 15:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/WomenJersey/WomenJersey")),
  //               1000
  //             );
  //           })
  //       );
                                
  //     case 18:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/ManPants/ManPants")),
  //               1000
  //             );
  //           })
  //       );
                                        
  //     case 20:
  //       return React.lazy(
  //         () =>
  //           new Promise((resolve) => {
  //             setTimeout(
  //               () => resolve(import("../dynamucComponents/ManJeans/ManJeans")),
  //               1000
  //             );
  //           })
  //       );
                                  
  //   case 22:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManBlazersSuits/ManBlazersSuits")),
  //             1000
  //           );
  //         })
  //     );
                                        
  //   case 23:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManShirts/ManShirts")),
  //             1000
  //           );
  //         })
  //     );
                                              
  //   case 24:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManKnitwearTshirts/ManKnitwearTshirts")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 309:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenFurCoats/WomenFurCoats")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 310:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenCoat/WomenCoat")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 311:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenWinterJackets/WomenWinterJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 312:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenDemiJackets/WomenDemiJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 313:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenRaincoats/WomenRaincoats")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 314:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenParks/WomenParks")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 315:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenVests/WomenVests")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 316:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenJeansJackets/WomenJeansJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                    
  //   case 317:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenLightweightJackets/WomenLightweightJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 318:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenSheepskinCoats/WomenSheepskinCoats")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 319:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenLeatherJackets/WomenLeatherJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 320:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenKosuhi/WomenKosuhi")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 321:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenBombers/WomenBombers")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 322:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenPoncho/WomenPoncho")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 323:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenAnoraki/WomenAnoraki")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 324:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WomenOuterwearOther/WomenOuterwearOther")),
  //             1000
  //           );
  //         })
  //     );
                                                          
  //   case 325:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManWinterJackets/ManWinterJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 326:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManLightweightJackets/ManLightweightJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 327:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManDemiJackets/ManDemiJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 328:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManCoat/ManCoat")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 329:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManSheepskinCoats/ManSheepskinCoats")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 330:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManVests/ManVests")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 331:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManParks/ManParks")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 332:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManJeansJackets/ManJeansJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 333:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManLeatherJackets/ManLeatherJackets")),
  //             1000
  //           );
  //         })
  //     );
                                                                
  //   case 334:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManBombers/ManBombers")),
  //             1000
  //           );
  //         })
  //     );
                                                                      
  //   case 335:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManRaincoats/ManRaincoats")),
  //             1000
  //           );
  //         })
  //     );
                                                                      
  //   case 336:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManKosuhi/ManKosuhi")),
  //             1000
  //           );
  //         })
  //     );
                                                                      
  //   case 337:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManAnoraki/ManAnoraki")),
  //             1000
  //           );
  //         })
  //     );
                                                                      
  //   case 338:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ManOuterwearOther/ManOuterwearOther")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 291:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesBags/AccessoriesBags")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 292:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesBackpacks/AccessoriesBackpacks")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 293:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesHats/AccessoriesHats")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 294:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesSuitcases/AccessoriesSuitcases")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 295:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesShawlsScarves/AccessoriesShawlsScarves")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 296:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesWallets/AccessoriesWallets")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 297:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesGlasses/AccessoriesGlasses")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 298:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesBelts/AccessoriesBelts")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 299:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesGloves/AccessoriesGloves")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 300:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesDecorations/AccessoriesDecorations")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 301:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesBriefcases/AccessoriesBriefcases")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 302:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesSocks/AccessoriesSocks")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 303:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesUmbrellas/AccessoriesUmbrellas")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 304:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesCoversDoc/AccessoriesCoversDoc")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 305:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesHair/AccessoriesHair")),
  //             1000
  //           );
  //         })
  //     );
                                                                            
  //   case 306:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesCosmetics/AccessoriesCosmetics")),
  //             1000
  //           );
  //         })
  //     );
                                                                                  
  //   case 307:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesTies/AccessoriesTies")),
  //             1000
  //           );
  //         })
  //     );
                                                                                  
  //   case 308:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/AccessoriesOther/AccessoriesOther")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 29:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlPants/GirlPants")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 30:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlOuterwear/GirlOuterwear")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 31:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlJumpsuits/GirlJumpsuits")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 32:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlShoes/GirlShoes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 33:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlPajamas/GirlPajamas")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 34:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlDressesSkirts/GirlDressesSkirts")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 35:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlJersey/GirlJersey")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 36:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlHatsMittensScarves/GirlHatsMittensScarves")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 37:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/GirlOther/GirlOther")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 39:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyPants/BoyPants")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 40:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyOuterwear/BoyOuterwear")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 41:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyJumpsuits/BoyJumpsuits")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 42:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyShoes/BoyShoes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 43:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyPajamas/BoyPajamas")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 44:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyJersey/BoyJersey")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 45:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyHatsMittensScarves/BoyHatsMittensScarves")),
  //             1000
  //           );
  //         })
  //     );
                                                                                        
  //   case 46:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/BoyOther/BoyOther")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   // case 48:
  //   //   return React.lazy(
  //   //     () =>
  //   //       new Promise((resolve) => {
  //   //         setTimeout(
  //   //           () => resolve(import("../dynamucComponents/ForChildrenCarSeats/ForChildrenCarSeats")),
  //   //           1000
  //   //         );
  //   //       })
  //   //   );
                                                                                              
  //   case 49:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenBicyclesScooters/ForChildrenBicyclesScooters")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 50:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenFurniture/ForChildrenFurniture")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 51:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenStrollers/ForChildrenStrollers")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 52:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenToys/ForChildrenToys")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 53:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenBedding/ForChildrenBedding")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 54:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenFeedingGoods/ForChildrenFeedingGoods")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 55:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenBathingGoods/ForChildrenBathingGoods")),
  //             1000
  //           );
  //         })
  //     );
                                                                                              
  //   case 56:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenGoodsForSchool/ForChildrenGoodsForSchool")),
  //             1000
  //           );
  //         })
  //     );
                                                                                                    
  //   case 58:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/ForChildrenGoodsForSchool/ForChildrenGoodsForSchool")),
  //             1000
  //           );
  //         })
  //     );
                                                                                                    
  //   case 59:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WatchesJewelryWatches/WatchesJewelryWatches")),
  //             1000
  //           );
  //         })
  //     );
                                                                                
  //   case 60:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/WatchesJewelryJewelry/WatchesJewelryJewelry")),
  //             1000
  //           );
  //         })
  //     );
                                                                                      
  //   case 62:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyCosmetics/HealthBeautyCosmetics")),
  //             1000
  //           );
  //         })
  //     );

                                                                                      
  //   case 63:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyPerfumery/HealthBeautyPerfumery")),
  //             1000
  //           );
  //         })
  //     );

                                                                                      
  //   case 64:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyDevices/HealthBeautyDevices")),
  //             1000
  //           );
  //         })
  //     );

                                                                                      
  //   case 65:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyHygiene/HealthBeautyHygiene")),
  //             1000
  //           );
  //         })
  //     );

                                                                                      
  //   case 66:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyHair/HealthBeautyHair")),
  //             1000
  //           );
  //         })
  //     );

                                                                                      
  //   case 67:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyMedical/HealthBeautyMedical")),
  //             1000
  //           );
  //         })
  //     );

                                                                                      
  //   case 68:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HealthBeautyBiologically/HobbiesWinterSports")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 345:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesCardsCoupons/HobbiesCardsCoupons")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 346:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesConcerts/HobbiesConcerts")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 347:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesTrips/HobbiesTrips")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 348:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesSport/HobbiesSport")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 349:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesTheater/HobbiesTheater")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 350:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesCircus/HobbiesCircus")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 351:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesShow/HobbiesShow")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 352:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesMagazines/HobbiesMagazines")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 353:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBooks/HobbiesBooks")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 354:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesEducational/HobbiesEducational")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 355:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBanknotes/HobbiesBanknotes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 356:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesTickets/HobbiesTickets")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 357:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesCelebrityThings/HobbiesCelebrityThings")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 358:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesMilitary/HobbiesMilitary")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 359:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesGramophone/HobbiesGramophone")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 360:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesDocuments/HobbiesDocuments")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 361:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesTokens/HobbiesTokens")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 362:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesGames/HobbiesGames")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 363:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesCalendars/HobbiesCalendars")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 364:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesPaintings/HobbiesPaintings")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 365:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesKinderSurprise/HobbiesKinderSurprise")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 366:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesEnvelopes/HobbiesEnvelopes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 367:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesWeaponLayouts/HobbiesWeaponLayouts")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 368:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesStamps/HobbiesStamps")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 369:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesModels/HobbiesModels")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 370:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesCoins/HobbiesCoins")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 371:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesPostcards/HobbiesPostcards")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 372:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesAshtrays/HobbiesAshtrays")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 373:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesPlasticСards/HobbiesPlasticСards")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 374:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesSportsCards/HobbiesSportsCards")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 375:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesPhotos/HobbiesPhotos")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 376:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesLabels/HobbiesLabels")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 377:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesCollectingOther/HobbiesCollectingOther")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 378:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesAccordions/HobbiesAccordions")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 379:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesGuitars/HobbiesGuitars")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 380:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesWindInstruments/HobbiesWindInstruments")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 381:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesPiano/HobbiesPiano")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 382:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesViolin/HobbiesViolin")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 383:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesDrums/HobbiesDrums")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 384:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesForStudio/HobbiesForStudio")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 385:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesAccessories/HobbiesAccessories")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 386:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBilliards/HobbiesBilliards")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 387:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesDiving/HobbiesDiving")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 388:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesMartialArts/HobbiesMartialArts")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 389:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesWinterSports/HobbiesWinterSports")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 390:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBallGames/HobbiesBallGames")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 391:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBoardGames/HobbiesBoardGames")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 392:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesPaintball/HobbiesPaintball")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 393:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesRollers/HobbiesRollers")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 394:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesTennis/HobbiesTennis")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 395:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesTourism/HobbiesTourism")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 396:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesFitness/HobbiesFitness")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   case 397:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesSportsNutrition/HobbiesSportsNutrition")),
  //             1000
  //           );
  //         })
  //     );
                                                                                            
  //   // case 398:
  //   //   return React.lazy(
  //   //     () =>
  //   //       new Promise((resolve) => {
  //   //         setTimeout(
  //   //           () => resolve(import("../dynamucComponents/HobbiesSportsOther/HobbiesSportsOther")),
  //   //           1000
  //   //         );
  //   //       })
  //   //   );
                                                                                                  
  //   case 399:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesHuntingFishing/HobbiesHuntingFishing")),
  //             1000
  //           );
  //         })
  //     );
                                                                                                        
  //   case 72:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesRoadBikes/HobbiesRoadBikes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                                        
  //   case 73:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBMXBikes/HobbiesBMXBikes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                                        
  //   case 74:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesBabyBikes/HobbiesBabyBikes")),
  //             1000
  //           );
  //         })
  //     );
                                                                                                              
  //   case 75:
  //     return React.lazy(
  //       () =>
  //         new Promise((resolve) => {
  //           setTimeout(
  //             () => resolve(import("../dynamucComponents/HobbiesSparePartsBikes/HobbiesSparePartsBikes")),
  //             1000
  //           );
  //         })
  //     );
            
     

      
  //   default:
  //     return Comp;
  // }
};
