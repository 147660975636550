import { TextField } from "@material-ui/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";

const mok = [
  {
    data: "01.01.2023",
    index: 0,
    mass: [
      {
        date: "0/22",
        value: 1,
      },
    ],
  },
  {
    data: "01.12.2022",
    index: 1,
    mass: [
      {
        date: "1/22",
        value: 2,
      },
    ],
  },
];

const changeDate = () => {};

const Test = (props) => {
  const ref = useRef(moment());
  const arr = new Array(31).fill("q");
  const [value, setValues]: any = useState({});
  let date = moment();
  const [curentMonthDays, setCurentMonthDays]: any = useState([]);
  const [prevMonthDays, setPrevMonthDays]: any = useState([]);

  const changeDate = () => {
    if (mok?.length) {
      const dat = `01.${moment(date).format("MM.YYYY")}`;
      const val = mok.find((i) => i.data == dat);
      let arr = new Array(date.daysInMonth()).fill("").map((item, index) => ({
        date: index + "/" + date.format("YY"),
        value: "",
      }));
      if (val) {
        const newVal = arr.map((item, ind) => {
          const dat = ind + 1 + "/" + moment(date).format("YY");
          const valueFind = val.mass.find((u) => u.date == dat);
          if (valueFind) {
            change(ind)({ target: { value: valueFind.value } });
            return {
              date: dat,
              value: valueFind.value,
            };
          }
          return { date: dat, value: "" };
        });
        setCurentMonthDays(newVal);

        console.log(newVal, value);
      }
    }
  };
  console.log("value", value);

  useEffect(() => {
    if (!mok?.length && !props?.data) {
      let arr = new Array(date.daysInMonth()).fill("").map((item, index) => ({
        date: index + "/" + date.format("YY"),
        value: "",
      }));
      setCurentMonthDays(curentMonthDays);
    }
    if (mok?.length) {
      const dat = `01.${moment(date).format("MM.YYYY")}`;
      const val = mok.find((i) => i.data == dat);
      let arr = new Array(date.daysInMonth()).fill("").map((item, index) => ({
        date: index + "/" + date.format("YY"),
        value: "",
      }));
      if (val) {
        const newVal = arr.map((item, ind) => {
          const dat = ind + "/" + moment(date).format("YY");
          const valueFind = val.mass.find((u) => u.date == dat);
          if (valueFind) {
            

            return {
              date: dat,
              value: valueFind.value,
            };
          }
          return { date: dat, value: "" };
        });
        setCurentMonthDays(newVal);
        const dat = `01.${ref.current.format("MM")}.${ref.current.format("YYYY")}`;
        setValues({...value, [dat]: newVal})
      }
    }
  }, []);

  // useEffect(()=>{changeDate()},[date])

  const [curent, setCurent] = useState({ visible: true });

  const prevMonthFn = () => {
    setCurent({ visible: false });
    setTimeout(() => {
      if (ref.current) {
        ref.current = moment(moment(ref.current).subtract(1, "month"));
      }
      let arr = new Array(moment(ref.current).daysInMonth())
        .fill("")
        .map((item, index) => ({
          date: index + "/" + date.format("YY"),
          value: null,
        }));

      // changeDate();
      // setCurentMonthDays(arr);
      setCurent({ visible: true });
    }, 1000);
  };

  const nextMonthFn = () => {
    setCurent({ visible: false });
    setTimeout(() => {
      if (ref.current) {
        ref.current = moment(moment(ref.current).add(1, "month"));
      }
      let arr = new Array(moment(ref.current).daysInMonth())
        .fill("")
        .map((item, index) => ({
          date: index + "/" + date.format("YY"),
          value: "",
        }));
        // changeDate()
      // changeDate();
      // setCurentMonthDays(arr);
      setCurent({ visible: true });
    }, 1000);
  };

  const change = (ind: number) => (event: { target: { value: any } }) => {
    const dat = `01.${ref.current.format("MM")}.${ref.current.format("YYYY")}`;

    let val = value[dat];
    if (!val?.length) {
      val = curentMonthDays;
    }

    val[ind] = Number(event.target.value);
    const newVal = [...curentMonthDays];
    if (+event.target.value > 0) {
      newVal[ind] = {
        date: ind + "/" + date.format("YY"),
        value: Number(event.target.value),
      };
    } else {
      newVal[ind] = {
        date: ind + "/" + date.format("YY"),
        value: Number.parseInt(event.target.value) || "",
      };
    }
    setCurentMonthDays(newVal);
    // if(dat && newVal)
    setValues({ ...value, [dat]: newVal });
  };

  const formatData = () => {
    const data = [];
    // eslint-disable-next-line array-callback-return
    const i = Object.keys(value).reduce((acc: [any], item, ind) => {
      acc.push({
        data: item,
        index: ind,
        mass: value[item].filter((i: { value: any[] }) => {
          return i.value;
        }),
      });

      return acc;
    }, []);

    console.log(i);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "610px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "24px",
          fontWeight: 900,
          maxWidth: 610,
        }}
      >
        <p style={{ margin: 20, marginTop: 0 }} onClick={prevMonthFn}>
          {"<"}
        </p>
        <div
          style={{
            minWidth: 610,
            display: "flex",
            maxHeight: 244,
            maxWidth: 610,
            overflow: "hidden",
          }}
        >
          <div
            style={{
              minWidth: 610,
              height: 244,
              display: "flex",
              flexWrap: "wrap",
              opacity: curent?.visible ? 1 : 0,
              background: "red",
              animationDuration: "3s",
              animationName: "slidein",
              transition: "visibility 0s, opacity 1s linear",
            }}
          >
            {curentMonthDays.map((item: any, ind: number) => (
              <div className="yacheika" key={ind}>
                <p className="yacheika-text">{ind + 1}</p>
                <div className="yacheika-input">
                  <TextField
                    key={ind}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                    className="input-field"
                    variant="outlined"
                    onChange={change(ind)}
                    value={item.value}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <p style={{ margin: 20, marginTop: 0 }} onClick={nextMonthFn}>
          {">"}
        </p>
      </div>
      <div>
        {ref.current.format("MM")} {ref.current.format("YYYY")}
      </div>
      <button onClick={formatData}>grgrgrgrg</button>
    </div>
  );
};
export default Test;
