import React, { Suspense, useCallback } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { RootState } from "../../redux/reducers/rootReducer";
import {
  closeModal,
  openModal,
  getTemplateById,
  setStatus,
  createTemplateData,
  setPhotoList,
  setLocation,
} from "../../redux/actions/actions";
import { components } from "./components";
import Loader from "../dynamucComponents/LoaderComponent";
import { useEffect } from "react";
import { compose, lifecycle } from "recompose";
import lodash from "lodash";
interface ModalProps {
  setOpen: (value: boolean) => void;
  title: string;
  idComponent: number;
  idEdit: number;
}

interface ModalPrivateProps extends ModalProps {
  isOpenCreateTemlate: any;
  getTemplateById: (id: number, idCategory: number) => void;
  setStatus: () => void;
  createTemplateData: () => void;
  setLocation: () => void;
  setPhotoList: () => void;
}

const WomensShoesModal: React.FC<ModalPrivateProps> = ({
  setOpen,
  title,
  idComponent,
  isOpenCreateTemlate,
  getTemplateById,
  idEdit,
  setStatus,
  createTemplateData,
  setLocation,
  setPhotoList,
}) => {
  // console.log("idComponent", idComponent);
  const fnOpen = useCallback(
    (value: boolean) => {
      setOpen(value);
    },
    [setOpen]
  );
  const url = components(idComponent);
  const DynamicComponent = url;

  useEffect(() => {
    // console.log(idEdit, idComponent);
    getTemplateById(idEdit, idComponent);
    return () => {
      setStatus();
      createTemplateData();
      setLocation();
      setPhotoList();
      setOpen(false);
    };
  }, []);
  return (
    <>
      <div
        className="inset-0 z-50 outline-none focus:outline-none"
        style={{
          top: 10,
          left: 10,
          position: "absolute",
          right: 25,
          overflow: "hidden",
        }}
      >
        <div
          className="relative w-auto my-6 mx-auto max-w-6xl"
          style={{ width: "100%", marginTop: "0.5rem" }}
        >
          <div
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
            style={{ border: "1px solid rgb(216 220 225)" }}
          >
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-3xl font-semibold">{title}</h3>
              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                <span
                  className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  ×
                </span>
              </button>
            </div>

            <div className="relative p-6 flex-auto">
              {!isOpenCreateTemlate?.field?.length ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Suspense fallback={<Loader />}>
                    <DynamicComponent
                      close={fnOpen}
                      idComponent={idComponent}
                      isEdit={!!idEdit}
                    />
                  </Suspense>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeTable: () => dispatch(closeModal(null)),
    getTemplateById: (id: number, idCategory: number) => {
      // console.log(idCategory);

      dispatch(getTemplateById.started({ id, idCategory }));
    },
    setStatus: () => dispatch(setStatus(null)),
    createTemplateData: () =>
      dispatch(createTemplateData.done({ params: null, result: null })),

    setPhotoList: () => dispatch(setPhotoList(null)),
    setLocation: () => dispatch(setLocation(null)),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    isOpenCreateTemlate: state.Reducer.createTemlateModal,
    idEdit: state.Reducer.idEdit,
  };
};
export default compose<ModalPrivateProps, ModalProps>(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle<ModalPrivateProps, ModalProps>({
    shouldComponentUpdate(prev) {
      // console.

      if (
        !lodash.isEqual(
          prev.isOpenCreateTemlate,
          this.props.isOpenCreateTemlate
        )
      ) {
        return true;
      }
      if (prev.idComponent === this.props.idComponent) {
        return false;
      }
      return true;
    },
  })
)(WomensShoesModal);
