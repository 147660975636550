import { toInteger } from "lodash";

type TemplateError = {
  id: string;
  index: number;
  month: string;
  day?: number;
  saveDate?: string;
};

export function logErrors(cards) {
  const errors: string[] = [];
  // for (let i = 0; i < select.length; i++) {
  //   if (select[i] && cards[]){

  //   }
  // }
  for (let i = 0; i < cards.length; i++) {
    let cardValueIndex = 0;
    
    for (const cardValue of cards[i].value) {
      const values = cardValue.value;
      const saveDateVisitedProps: any = [];

      for (let j = 0; j < values.length; j++) {
        if (values[j] == "") {
          continue;
        }

        const propName = `${j + 1}:${cardValue.date.monthCurrent}:${cardValue.date.yearCurrent}`;
        if (!cardValue.saveDateTime[propName]) {
          errors.push(`Ошибка в шаблоне №${i + 1} месяц ${ cards[i].value[cardValueIndex].date.monthCurrent } день ${j + 1}. Даты не выбраны`);
        } else {
          if (cardValue.saveDateTime[propName].length !== toInteger(values[j])){
            errors.push(`Ошибка в шаблоне №${i + 1} месяц ${ cards[i].value[cardValueIndex].date.monthCurrent } день ${j + 1}. 
                          Количество дат(${cardValue.saveDateTime[propName].length}) и автозагрузок(${values[j]}) не совпадает`);
          }
          
          saveDateVisitedProps.push(propName);
        }
      }
      for (let saveDate in cardValue.saveDateTime) {
        if (!saveDateVisitedProps.includes(saveDate)) {
          errors.push(`Ошибка в шаблоне №${i + 1}, месяц ${ cardValue.date.monthCurrent } не заполнена ячейка для даты ${saveDate}`);
        }
      }
      cardValueIndex++;
    }
  }
  return errors;
}
