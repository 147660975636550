import { FC } from "react";
import Youtube from "react-youtube";
import { InstructionEntity } from "./InstructionsPage";

interface InstructionProps {
  instruction: InstructionEntity;
}

const Instruction: FC<InstructionProps> = ({
  instruction: { name, text, videoUrl },
}) => {
  let videoId = "";
  if (videoUrl) {
    const videoIdQueryLeft = videoUrl.lastIndexOf("v=") + 2;
    const videoIdQueryRight = videoUrl.indexOf("&", videoIdQueryLeft);
    videoId = videoUrl.substring(
      videoIdQueryLeft,
      videoIdQueryRight === -1 ? undefined : videoIdQueryRight
    );
  }
  return (
    <div>
      <h3 className="text-xl uppercase py-3 font-bold">{name}</h3>
      <p className=" py-4">{text}</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        {videoId && <Youtube videoId={videoId} />}
      </div>
    </div>
  );
};

export default Instruction;
