import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { compose } from "recompose";
import { RootState } from "../../redux/reducers/rootReducer";
import {
  setTemplateXml,
  deleteTemplateList,
} from "../../redux/actions/actions";
import "./XmlCard.css";
import { randomizeText } from "../../helpers/text";
import CustomTooltip from "../../shared/CustomTooltip";
import {
  useWindowSize,
  useWindowWidth,
  useWindowHeight,
} from "@react-hook/window-size";
import { sequentialDistrib, evenlyDistrib } from "./fn";
import Modal from "../../shared/Modal";
import { logErrors } from "../../helpers/logErrors";
import { getMonthName } from "../../helpers/getMonthName";
import { toInteger } from "lodash";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";

const arr = Array(30).fill("");

interface XmlCardProps {
  img: string;
  index: number;
  count: number;
  id: string;
  name: string;
  ediValues?: any;
  month?: number;
  year?: number;
  delete: (value: string) => void;
  chekId?: string;
  setDateTime: (value) => void;
  dateTime: any;
  open: boolean;
  setOpen: (value) => void;
  setOpenTime: (value) => void;
  sethandlerSelectTime: any;
  sethandleModal: (value) => void;
  selectedInfo: any;
  setSelectedInfo: (value) => void;
  
  listCreateTemplateXml: any[];
  // select: any;
  // setSelect: (value) => void;
  setSelectCheckboxTime: (value) => void;
}

interface XmlCardPrivateProps extends XmlCardProps {
  setTemplate: (value: any, data: any, id: string) => void;
  deleteTemplate: (index: number) => void;
  list: (date: any, id: string) => any;
  listAll: any;
}

const XmlCard: React.FC<XmlCardPrivateProps> = (props) => {
  const {
    setOpen,
    open,
    dateTime,
    setDateTime,
    index,
    img,
    setTemplate,
    count,
    deleteTemplate,
    list,
    ediValues = [],
    name,
    chekId,
    month,
    year,
    id,
    listAll,
    setOpenTime,
    sethandlerSelectTime,
    sethandleModal,
    selectedInfo,
    setSelectedInfo,
    setSelectCheckboxTime,
    listCreateTemplateXml,
  } = props;
  const [personName, setPersonName] = React.useState([]);
  const handleChange = (event: any) => {
    setPersonName(event.target.value);
  };
  const [saveDateTime, setSaveDateTime]: any = useState({});
  const [width, height] = useWindowSize();
  const [autogenerate, setAutogenerate] = useState({ start: 1, end: 30 });
  
  const [values, setValues] = React.useState<string[]>(new Array(31).fill(""));
  const [usedAdsCount, setUsedAdsCount] = React.useState<number>(0);
  const [currentMonthAdsCount, setCurrentMonthAdsCount] = React.useState(0);
  const [select, setSelect]: any = React.useState([]);
  const [hoveredSelect, setHoveredSelect]: any = React.useState([]);
  const [selectLastIndex, setSelectLastIndex] = React.useState<number>();
  const [inputCount, setInputCount] = React.useState(count - usedAdsCount);
  const [isTemplateWasChanged, setTemplateWasChanged] = React.useState(false);
  const [checkbox, setCheckbox] = React.useState({ name: "", value: false });
  const [CardXmlListDocument, setCardXmlListDocument] = useState(100);
  const [date, setDate] = useState({
    monthCurrent: new Date().getMonth() + 1,
    yearCurrent: new Date().getFullYear(),
  });
  // const [selectTime, setSelectTime]: any = useState([]);
  let a = document.getElementById("sidebar")?.clientWidth;
  let selectIndex = -1;
  let selectTime : string[] = [];

  const nextMonth = () => {
    let newMonth = (date.monthCurrent - 1 + 1) % 12;
    let newYear = Math.floor((date.monthCurrent - 1 + 1) / 12) + date.yearCurrent;
    const newDate = new Date(newYear, newMonth);
    setSelectLastIndex(undefined);
    setHoveredSelectCells([]);
    setDate({
      monthCurrent: newDate.getMonth() + 1,
      yearCurrent: newDate.getFullYear(),
    });
    
    let oldValue = listCreateTemplateXml[index]
      .value
      .find((info) => info.date.monthCurrent === newDate.getMonth() + 1 && info.date.yearCurrent === newDate.getFullYear());
    if (oldValue){
      setValues(oldValue.value);
      setSaveDateTime(oldValue.saveDateTime);
      
      let newSelect:any = [];
      oldValue.value.forEach((value, index) => { 
        if (oldValue.value[index] !== '')
          newSelect[index] = true;
      });
      setSelect([ ...newSelect]);
    }
    else {
      setSelect([]);
      setSaveDateTime({});
      let day = new Date(newYear, (date.monthCurrent + 1) % 12);
      day.setDate(0);
      setValues(new Array(day.getDate()).fill(""));
    }
  };

  const prevMonth = () => {
    // const prevCount = values.reduce((acc, item) => {
    //   const number = Number.parseInt(item);
    //   if (number) {
    //     acc += number;
    //   }
    //   return acc;
    // }, 0);
    // setUsedAdsCount(usedAdsCount - prevCount);

    let newMonth = (date.monthCurrent - 1 - 1 + 12) % 12;
    let newYear = date.yearCurrent - (newMonth === 11 ? 1 : 0);
    const newDate = new Date(newYear, newMonth);


    // setSelect([]);
    // setSaveDateTime({});
    // setSelectLastIndex(undefined);
    setHoveredSelectCells([]);
    setDate({
      monthCurrent: newDate.getMonth() + 1,
      yearCurrent: newDate.getFullYear(),
    });

    let oldValue = listCreateTemplateXml[index]
      .value
      .find((info) => info.date.monthCurrent === newDate.getMonth() + 1 && info.date.yearCurrent === newDate.getFullYear());
    if (oldValue){
      setValues(oldValue.value);
      setSaveDateTime(oldValue.saveDateTime);

      let newSelect:any = [];
      oldValue.value.forEach((value, index) => { 
        if (oldValue.value[index] !== '')
          newSelect[index] = true;
      });
      setSelect([ ...newSelect]);
    }
    else {
      setSelect([]);
      setSaveDateTime({});
      let day = new Date(newYear, (date.monthCurrent + 2) % 12);
      day.setDate(0);
      setValues(new Array(day.getDate()).fill(""));
    }
    
    // let day = new Date(newYear, date.monthCurrent);
    // day.setDate(0);
    // setValues(new Array(day.getDate()).fill(""));
  };

  

  const handleIntervalRandomize = (end: string) => {
    if (count) {
      const maxCount = inputCount;
      const endIndex = Number(end);
      const newValue = [...new Array(30).fill("")];
      const countEnd = endIndex - autogenerate.start + 1;
      for (let i = autogenerate.start - 1; i < endIndex; i++) {
        newValue[i] = (maxCount / countEnd).toFixed(0);
      }
      setValues(newValue);
    }
  };

  const handleAutoGeneration = (
    alghorithmCallback: (
      values: string[],
      start: number,
      end: number,
      count: number
    ) => any
  ) => {
    const availableValues = select
      .filter((value, index) => value && !values[index])
      .map(() => '')
      
    const result = alghorithmCallback(
      availableValues,
      1,
      availableValues.length,
      inputCount
    );
    let j = 0;
    let usedCount = 0;
    const newValues = [...values];

    select.forEach((element, index) => {
      if (element && !newValues[index]){
        newValues[index] = result[j];
        usedCount += +result[j];
        j++;
      }
    })

    const newSelect = [ ...select];
    select.forEach((value, index) => { 
      if (value && newValues[index] === '')
        newSelect[index] = undefined;
    });

    setSelect([ ...newSelect]);
    setValues(newValues);
    setInputCount(inputCount - usedCount);
    setUsedAdsCount(usedAdsCount + usedCount);
  };

  const handlerClear = () => {
    let oldTemplate = listCreateTemplateXml[index].value;
    for (let i = 0; i < oldTemplate.length; i++) {
      let newValue = oldTemplate[i].value.fill('');
      setTemplate({ index, result: newValue, saveDateTime:{} }, oldTemplate[i].date, id?.toString());
    }

    // const prevCount = values.reduce((acc, item) => {
    //   const number = Number.parseInt(item);
    //   if (number) {
    //     acc += number;
    //   }
    //   return acc;
    // }, 0);

    setUsedAdsCount(0);
    setValues(values.map((val) => ""));
    setSaveDateTime({});
    setSelect([]);
    setSelectLastIndex(undefined);
    setHoveredSelectCells([]);
    setInputCount(count);
  };

  const [hoveredSelectCells, setHoveredSelectCells] = useState<number[]>([]);

  const hoverCellHandler = (ind: number) => {
    if (selectLastIndex === undefined) return;

    const arr: number[] = [];
    for (let i = selectLastIndex; i <= ind; i++) {
      arr.push(i);
    }
    setHoveredSelectCells(arr);
  };

  const selectClickGroup = (ind) => () => {
    if (select[ind]) {
      if (saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`])
        delete saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`]
      let newValues = [ ...values];
      setUsedAdsCount(usedAdsCount - toInteger(newValues[ind]));
      setInputCount(inputCount + toInteger(newValues[ind]));
      newValues[ind] = '';
      select[ind] = false;
      setSelect([...select]);
      setValues(newValues);
      setSelectLastIndex(undefined);
      setHoveredSelectCells([]);
      return;
    }

    if (selectLastIndex !== undefined) {
      let newDateTime = {};
      const newSelect = [...select];
      const newHoveredSelect = [] as any[];
      for (let i = selectLastIndex; i <= ind; i++) {
        newSelect[i] = true;
        newDateTime[`${i + 1}:${date.monthCurrent}:${date.yearCurrent}`] = [];

        newHoveredSelect[i] = true;
      }
      setSelectLastIndex(undefined);
      setHoveredSelectCells([]);
      setHoveredSelect(newHoveredSelect);
      setSelect(newSelect);      
    } else {
      setHoveredSelectCells([ind]);
      setSelectLastIndex(ind);
    }
  };


  const handlerGroupDateTime = () => () => {
    const newSelect = [ ...hoveredSelect];
    hoveredSelect.forEach((value, index) => {
      if (value && values[index] === '')
        newSelect[index] = undefined;
    });

    const newValues = Array.from({ length: Math.max(select.length, newSelect.length) }, (_, i) =>
        select[i] === true || newSelect[i] === true ? true : undefined
    );

    setSelect([ ...newValues]);

    let newSelectTime = [];
    selectTime = newSelectTime;

    setSelectCheckboxTime([ ...newSelectTime]);

    sethandlerSelectTime(handlerSelectTime);
    sethandleModal(handleGroupModal);
    setSelectedInfo({
      month: date.monthCurrent,
      year: date.yearCurrent,
      selected: newSelect,
      saveDateTime: { ...saveDateTime},
    });

    setOpenTime(true);
  }

  const handleGroupModal = () => () => {
    setSelectedInfo((selectedInfo) => {
        const selectedSave = selectedInfo.selected.map((v, index) =>{
          if (v && values[index]){
            let availableValues = Array(selectTime.length).fill('')

            let newSelectTime: string[] = [];
            for (let i = 0; i < 24; i++){
              if (selectTime.includes(`${i}:00-${i + 1}:00`)){
                newSelectTime.push(`${i}:00-${i + 1}:00`);
              }
            }
            
            const result = evenlyDistrib(
              availableValues,
              1,
              availableValues.length,
              +values[index]
            );

            let finalSelectTime = Array(+values[index]).fill('');
            let k = 0;
            let j = 0;

            result.forEach((value, index) => {
              if (value != ''){
                for (let i = 0; i < +value; i++){
                  finalSelectTime[j] = newSelectTime[k]
                  j++;
                }
              }
              k++;
            })
            return {
              [`${index + 1}:${selectedInfo.month}:${selectedInfo.year}`]: finalSelectTime
            };
          }
          else
            return {};
          }

          // return v && values[index]
          //   ? {
          //       [`${index + 1}:${selectedInfo.month}:${selectedInfo.year}`]: [ ...selectTime.slice(0, Math.min(selectTime.length, toInteger(values[index])))]
          //     }
          //   : {}      
          // }
        );
        for (let s of selectedSave) {
          for (let key in s) {
            if (s[key].length) {
              selectedInfo.saveDateTime[key] = s[key];
            } else {
              delete selectedInfo.saveDateTime[key];
            }
          }
        }
        setSaveDateTime({ ...selectedInfo.saveDateTime });
        return {
          ...selectedInfo,
          saveDateTime: {
            ...selectedInfo.saveDateTime
          },
        };
    });
    
    setOpenTime(false);
    selectTime = [];
  };

  const handlerDateTime = async (ind) => {
    selectTime = [];
    if (select[ind] && values[ind]) {
      selectIndex = ind
      let newSelectTime:string[] = [];
      if (
        `${ind + 1}:${date.monthCurrent}:${date.yearCurrent}` in saveDateTime
      ) {
        for(let i = 0; i < saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`].length; i++){
          const str = saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`][i];
          if (!newSelectTime.includes(str)){
            newSelectTime.push(str);
          }
        }
      }
      selectTime = newSelectTime;
      setSelectCheckboxTime([ ...newSelectTime]);
      
      sethandlerSelectTime(handlerSelectTime);
      sethandleModal(handleModal);
      setSelectedInfo({
        month: date.monthCurrent,
        year: date.yearCurrent,
        selected: select,
        saveDateTime: { ...saveDateTime},
      });
      setOpenTime(true);
    }
  };

  const handlerSelectTime = () => (ind, isChecked: boolean) => {
    let newSelectTime = [ ...selectTime]

    if (isChecked) {
      newSelectTime.push(`${ind}:00-${ind + 1}:00`);
    } else {
      newSelectTime.splice(newSelectTime.indexOf(`${ind}:00-${ind + 1}:00`), 1);
    }
    setSelectCheckboxTime([ ...newSelectTime]);
    selectTime = [ ...newSelectTime];

    // setSelectedInfo((selectedInfo) => {
    //   const selectedSave = selectedInfo.selected.map((v, index) =>{
    //     return v && index === selectIndex
    //       ? {
    //           [`${index + 1}:${selectedInfo.month}:${selectedInfo.year}`]: [
    //             ...data.map((d) => `${d}:00-${d + 1}:00`),
    //           ],
    //         }
    //       : {}
      
    //     }
    //   );
    //   for (let s of selectedSave) {
    //     for (let key in s) {
    //       if (s[key].length) {
    //         selectedInfo.saveDateTime[key] = s[key];
    //       } else {
    //         delete selectedInfo.saveDateTime[key];
    //       }
    //     }
    //   }
    //   return {
    //     ...selectedInfo,
    //     saveDateTime: {
    //       ...selectedInfo.saveDateTime
    //     },
    //   };
    // });
  };

  const getCellColor = (ind: number) => {
    if (
      !!saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`]?.length && 
      saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`]?.length === toInteger(values[ind])
    ) {
      return "green";
    } else {
      
      if ( select[ind] && 
        toInteger(values[ind]) > 0 &&
        saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`]?.length > 0 &&
        (saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`]?.length !== toInteger(values[ind]))) {
        return "yellow"
      }

      if (select[ind]) {
        return "#0071c661";
      }

      if (hoveredSelectCells.includes(ind)) {
        return "#cbcbcb";
      }

      return "#e4e4e7";
    }
  };

  const handleModal = () => () => {
    setSelectedInfo((selectedInfo) => {
        const selectedSave = selectedInfo.selected.map((v, index) =>{
          if (v && index === selectIndex) {
            let availableValues = Array(selectTime.length).fill('')

            let newSelectTime: string[] = [];
            for (let i = 0; i < 24; i++){
              if (selectTime.includes(`${i}:00-${i + 1}:00`)){
                newSelectTime.push(`${i}:00-${i + 1}:00`);
              }
            }

            const result = evenlyDistrib(
              availableValues,
              1,
              availableValues.length,
              +values[index]
            );

            let finalSelectTime = Array(+values[index]).fill('');
            let k = 0;
            let j = 0;
            result.forEach((val, index) => {
              if (val !== ''){
                for (let i = 0; i < +val; i++){
                  finalSelectTime[j] = newSelectTime[k]
                  j++;
                }
              }
              k++;
            })
            return {
              [`${index + 1}:${selectedInfo.month}:${selectedInfo.year}`]: finalSelectTime
            };
          }
          else
            return {};
          }
        );
        for (let s of selectedSave) {
          for (let key in s) {
            if (s[key].length) {
              selectedInfo.saveDateTime[key] = s[key];
            } else {
              delete selectedInfo.saveDateTime[key];
            }
          }
        }
        setSaveDateTime({ ...selectedInfo.saveDateTime });
        return {
          ...selectedInfo,
          saveDateTime: {
            ...selectedInfo.saveDateTime
          },
        };
      });
    
    setOpenTime(false);
    selectTime = [];
    // setSelectTime([]);
  };

  useEffect(() => {
    setCardXmlListDocument(
      document.getElementById("cardXmlList")?.clientWidth || 100
    );
  }, []);

  // TODO: optimize
  useEffect(() => {
    const monthCurrent = month || new Date().getMonth() + 1;
    const yearCurrent = year || new Date().getFullYear();

    if (ediValues && ediValues.value && ediValues.value.length) {
      //TODO: check different months
      let usedCount = 0;
      for (let value of ediValues.value) {
        usedCount += value.value.reduce((acc, item) => {
          const number = Number.parseInt(item);
          if (number) {
            acc += number;
          }
          return acc;
        }, 0);
      }

      for (let value of ediValues.value.reverse()) {
        if (usedCount > count) {
          setTemplateWasChanged(true);
          for (let prop in value.saveDateTime) {
            const dateParts = prop.split(":");
            if (
              new Date(`${dateParts[1]}/${dateParts[0]}/${dateParts[2]}`) >
              new Date()
            ) {
              delete value.saveDateTime[prop];
            }
          }

          const currentDate = new Date();
          const valueDate = new Date(
            `${value.date.monthCurrent}/1/${value.date.yearCurrent}`
          );

          if (
            currentDate.getMonth() + 1 === value.date.monthCurrent &&
            currentDate.getFullYear() === value.date.yearCurrent
          ) {
            let day = new Date();
            day.setMonth(value.date.monthCurrent);
            day.setFullYear(value.date.yearCurrent);
            day.setDate(0);

            usedCount -= value.value
              .slice(currentDate.getDate())
              .reduce((acc, item) => {
                const number = Number.parseInt(item);
                if (number) {
                  acc += number;
                }
                return acc;
              }, 0);

            value.value = [
              ...value.value.slice(0, currentDate.getDate()),
              ...new Array(day.getDate() - currentDate.getDate()).fill(""),
            ];
          } else if (valueDate > currentDate) {
            usedCount -= value.value.reduce((acc, item) => {
              const number = Number.parseInt(item);
              if (number) {
                acc += number;
              }
              return acc;
            }, 0);

            valueDate.setDate(0);
            value.value = new Array(valueDate.getDate()).fill("");
          }
        }

        if (
          value.date.monthCurrent === monthCurrent &&
          value.date.yearCurrent === yearCurrent
        ) {
          let newSelect:any = [];
          value.value.forEach((val, index) => { 
            if (val !== '')
              newSelect[index] = true;
          });
          setSelect([ ...newSelect]);
          setValues(value.value);
          setSaveDateTime(value.saveDateTime);
        }
        setTemplate(
          { index, result: value.value, saveDateTime: value.saveDateTime },
          value.date,
          id
        );
      }
      setUsedAdsCount(usedCount);
      setInputCount(count - usedCount);
    } else {
      let day = new Date();
      day.setMonth(monthCurrent);
      day.setFullYear(yearCurrent);
      day.setDate(0);
      setValues(new Array(day.getDate()).fill(""));
    }
  }, []);

  useEffect(() => {
    setCardXmlListDocument(
      document.getElementById("cardXmlList")?.clientWidth || 100
    );
  }, [width]);

  useEffect(() => {
    setTemplate({ index, result: values, saveDateTime }, date, id?.toString());

    const count = values.reduce((acc, item) => {
      const number = Number.parseInt(item);
      if (number) {
        acc += number;
      }
      return acc;
    }, 0);
    setCurrentMonthAdsCount(count);
  }, [values, saveDateTime]);

  // useEffect(() => {
  //   const data = list(date, id?.toString());
  //   if (data?.value?.length > index) {
  //     setValues(data?.value);
  //     setSaveDateTime(data?.saveDateTime);
  //   }
  // }, [date.monthCurrent, date.yearCurrent]);

  const imgUrl = img.replace(/.*(?:id=|\/d\/)([^&\/]+).*/, 'https://lh3.googleusercontent.com/d/$1=w1000?authuser=0');

  return (
    <div
      className="overflow"
      style={{
        transition: " all 2s",
        margin: "10px 0",
        padding: 0,
        width: `calc(100vw - ${a}px - 30px)`,
      }}
    >
      <main className="main" style={{}}>
        <div
          className="body"
          style={{
            position: "relative",
            minWidth: "900px",
            border:
              usedAdsCount > count || isTemplateWasChanged
                ? "5px solid red"
                : "1px solid rgb(216, 220, 225)",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CustomTooltip title={name} interactive disabled={false}>
              <div className="picture-text" style={{ fontSize: 20 }}>
                {name}
              </div>
            </CustomTooltip>
            <img className="picture-img" src={imgUrl} referrerPolicy="no-referrer" />
            <div className="checkbox-container" style={{ marginLeft: "10px" }}>
              {/* <label className="checkbox-label" style={{ fontSize: 16 }}>
                Распределить в интервале
              </label> */}
              {/* <div className="select-container">
                <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="c"
                  onChange={(value) => {
                    const newStart = Number.parseInt(value.target.value) || 0;
                    setAutogenerate({
                      start: newStart,
                      end:
                        autogenerate.end > newStart
                          ? autogenerate.end
                          : newStart + 1,
                    });
                  }}
                >
                  {new Array(values.length - 1).fill(0).map((item, index) => {
                    const value = index + 1;
                    return (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
                <select
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={(value) => {
                    setAutogenerate({
                      start: autogenerate.start,
                      end: Number.parseInt(value.target.value) || 30,
                    });
                  }}
                  value={autogenerate.end}
                >
                  {new Array(values.length - autogenerate.start - 1)
                    .fill(0)
                    .map((item, index) => {
                      const value = autogenerate.start + index + 1;
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  <option
                    key={values.length}
                    value={values.length}
                    defaultValue={values.length}
                  >
                    {values.length}
                  </option>
                </select>
              </div> */}

              <div>
                {/* <div>Использовано в этом месяце {currentMonthAdsCount}</div> */}
                <div
                  style={{ fontSize: "16px", width: "100%", textAlign: "left" }}
                >
                  Использовано: <br />
                  <strong>{usedAdsCount}</strong> из <strong>{count}</strong>
                </div>
                <input
                  placeholder="100"
                  value={inputCount}
                  style={{ width: "100%", border: " 1px solid #e4e4e7" }}
                  onChange={(event) => {
                    let value = Number.parseInt(event?.target.value);

                    value =
                      value > count - usedAdsCount
                        ? count - usedAdsCount
                        : value;
                    value = value < 0 ? 0 : value;
                    if (!Number.isNaN(value)) {
                      setInputCount(value);
                    } else {
                      setInputCount(0);
                    }
                  }}
                />
                <div className="buttom-container">
                  <button
                    className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                    type="button"
                    onClick={() => handleAutoGeneration(evenlyDistrib)}
                  >
                    Распределить
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              className="colvo-ob"
              style={{
                maxWidth: "427px",
                display: "flex",
                gap: "10px",
              }}
            >
              {/* <span>
                Доступно <strong>{count - usedAdsCount}</strong> объявлений
              </span> */}
              {/* {isTemplateWasChanged && (
                <div>
                  Настройки были исправлены, согласно изменениям в шаблоне!
                </div>
              )} */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  gap: "10px",
                  width: "60%",
                }}
              >
                <button
                  className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                  style={{
                    flex: "1",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="button"
                  onClick={prevMonth}
                >
                  <i
                    className="fa fa-arrow-left "
                    style={{ color: "#ffffff" }}
                  />
                </button>
                <span
                  style={{
                    flex: "1",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    fontSize: "16px",
                  }}
                >
                  {getMonthName(date?.monthCurrent - 1)} {date?.yearCurrent}
                </span>
                <button
                  className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                  style={{
                    flex: "1",
                    height: "35px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="button"
                  onClick={nextMonth}
                >
                  <i
                    className="fa fa-arrow-right "
                    style={{ color: "#ffffff", height: "20px" }}
                  />
                </button>
              </div>
              <button
                className="bg-teal-500 text-white active:bg-teal-600 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom"
                type="button"
                style={{
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handlerClear}
              >
                Очистить
              </button>
            </div>
            <div className="day-list">
              <div>Пн</div>
              <div>Вт</div>
              <div>Ср</div>
              <div>Чт</div>
              <div>Пт</div>
              <div>Сб</div>
              <div>Вс</div>
            </div>
            <div className="container-yacheek">
              {new Array(
                (toInteger(new Date(date.yearCurrent, date.monthCurrent - 1, 1).getDay()) + 6) % 7
              )
                .fill("")
                .map(() => (
                  <small
                    className="yacheika"
                    style={{ background: "transparent" }}
                  />
                ))}
              {values.map((_, ind) => (
                <div
                  onMouseEnter={() => hoverCellHandler(ind)}
                  className="yacheika"
                  key={id + ind}
                  style={{
                    background: getCellColor(ind),
                  }}
                >
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <i
                      className={ select[ind] ? "gg-remove-r" : "gg-add-r" }
                      style={{ transform: "scale(var(--ggs,0.5))", marginTop:"auto", marginBottom:"auto"  }}
                      onClick={selectClickGroup(ind)}
                    ></i>
                    
                    <i
                        className="gg-time"
                        style={{ transform: "scale(var(--ggs,0.6))", marginTop:"auto", marginBottom:"auto", marginLeft:"0px"  }}
                        onClick={async () => handlerDateTime(ind)}
                      ></i>
                    {/* <i
                      className="gg-arrow-bottom-right-o"
                      onClick={selectClickGroup(ind)}
                      style={{ transform: "scale(var(--ggs,0.5))" }}
                    ></i> */}
                    <p 
                      className="yacheika-text"
                      style={{ marginTop:"auto", marginBottom:"auto" }}
                    >
                      {ind + 1}
                    </p>
                  </div>

                  <div
                    className="yacheika-input"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <TextField
                      className="input-field"
                      variant="outlined"
                      onChange={(event) => {
                        if (!select[ind])
                          return;
                        if (saveDateTime[`${ind + 1}:${date.monthCurrent}:${date.yearCurrent}`])
                          return;
                        const newValue = [...values];
                        const inputValue = +event.target.value || 0;
                        let reg = /^\d+$/;
                        if (!reg.test(event.target.value) && event.target.value !== '')
                          return;
                        let currentValue = parseInt(newValue[ind]);
                        if (!currentValue)
                          currentValue = 0;

                        if (count - (usedAdsCount - currentValue) - inputValue >= 0) {
                          newValue[ind] = event.target.value;
                          
                          if (parseInt(newValue[ind]) === 0)
                            newValue[ind] = '';

                          if (inputCount > count - (usedAdsCount - currentValue) - inputValue) {
                            setInputCount(count - (usedAdsCount - currentValue) - inputValue);
                          }
                          setUsedAdsCount(
                            usedAdsCount +
                              (+newValue[ind] || 0) -
                              (+values[ind] || 0)
                          );
                          setValues(newValue);
                        }
                      }}
                      value={values[ind]}
                    />
                  </div>
                </div>
              ))}
              <button
                className="bg-teal-500 text-white active:bg-teal-600 disabled:bg-red-200 font-bold uppercase text-base px-8 py-3 rounded-full shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 buttom "
                type="button"
                style={{
                  height: "35px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
                onClick={handlerGroupDateTime()}
                // onClick={handlerDateTime(select.findIndex((el) => el))}
                // disabled={true}
              >
                Установить время
              </button>
            </div>
          </div>

          <i
            className="fas fa-times"
            style={{ top: 5, right: 10, position: "absolute" }}
            onClick={() => {
              deleteTemplate(index);
              props.delete(props.id);
            }}
          ></i>
        </div>
      </main>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    deleteTemplate: (index: number) => dispatch(deleteTemplateList(index)),
    setTemplate: (
      value: { index: number; result: any; saveDateTime: any },
      date: string,
      id: string
    ) => dispatch(setTemplateXml({ date, value, id })),
  };
};

const mapStateToProps = (state: RootState) => {
  return {
    list: (date, id) => {
      const index = state.Reducer.templateXML?.findIndex(
        (item) => item?.id == id
      );

      if (index == -1) {
        return [];
      }

      const find =
        state.Reducer.templateXML[index]?.value?.find(
          (i) =>
            i?.date.monthCurrent == date.monthCurrent &&
            i?.date.yearCurrent == date.yearCurrent
        ) || [];

      return find;
    },
    listAll: state.Reducer.templateXML,
  };
};

export default compose<XmlCardPrivateProps, XmlCardProps>(
  connect(mapStateToProps, mapDispatchToProps)
)(XmlCard);
